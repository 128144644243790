<ng-container *ngIf="renderMode == 'column'">
  <div class="row" *ngIf="formArray">
    <div class="col-md-4 col-sm-12" *ngFor="let formControl of formArray.controls">
      <app-attendance-field [hideLabel]="hideLabel"  [isEditable]="isEditable" [renderMode]="'column'" [isHost]="isHost" [formControl]="formControl" (save)="onSave(formControl)"></app-attendance-field>
    </div>
  </div>
</ng-container>
<ng-container *ngIf="renderMode == 'list' && formArray">
    <li *ngFor="let formControl of formArray.controls" style="list-style: none;">
      <app-attendance-field [hideLabel]="hideLabel" [isEditable]="isEditable" [renderMode]="'list'" [isHost]="isHost" [formControl]="formControl" (save)="onSave($event)"></app-attendance-field>
    </li>
</ng-container>



