<div class="card pl-1 pr-1 import-site">
  <obc-loader [show]="inProgress"></obc-loader>
  <div class="card-body">
    <div class="row">
      <div class="col-12 col-lg-8">
        <button class="btn btn-default" [routerLink]="['/sites']">
          <i class="fas fa-arrow-left"></i> Return to List
        </button>
      </div>
      <div class="col-md-12 mt-1">
          <div class="alert alert-default mr-2 d-flex align-items-center flex-wrap justify-content-between">Import sites data in CSV file format (comma seperated)
            <a class="btn btn-sm btn-info mt-2 mt-md-0" href="../../../../assets/files/import-sites-sample.csv" target="_blank">
              Download
              Sample File
            </a>
          </div>

      </div>
      <div class="col-md-12 mt-1">
        <obc-file-upload [showClearButton]="true" (clear)="onClearForm()"
                         [formControl]="fileFormControl"
                         [accept]="FileUploadAcceptType.CSV">
        </obc-file-upload>
      </div>
      <div class="col-md-12 mt-1" *ngIf="importData?.message">
        <div class="alert mb-1 text-center" *ngIf="!(!importData.valid && isImportModelValid)"
          [ngClass]="{'alert-success' : importData.valid, 'alert-danger' : !importData.valid}" role="alert">
          {{importData.message}}
        </div>
      </div>
      <div class="col-md-12" *ngIf="importData && sites && sites.length">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th colspan="2">
                <div class="d-flex justify-content-between">
                  <span>Import Data</span>
                  <div>
                    <button class="btn btn-success" (click)="onImport()" [disabled]="!isImportModelValid">
                      Import
                    </button>
                  </div>
                </div>
              </th>
            </tr>
          </thead>
          <tbody style=" font-weight: bold;">
            <ng-container *ngFor="let site of sites; let index = index">
              <ng-container *ngIf="site.validationStatus != importRecordStates.Corrupted">
                <tr [ngClass]="{'bk-warning': !isValidSite(site)}">
                  <td>
                    <div>
                      <span [tooltip]="site.isUpdateRequest ? 'Update':'Add'">
                        <i class=""
                          [ngClass]="{'fa fa-edit': site.isUpdateRequest && site.validationStatus != importRecordStates.Invalid, 'fas fa-plus': !site.isUpdateRequest && site.validationStatus != importRecordStates.Invalid}"></i>
                        #{{index+1}}
                      </span>
                    </div>
                    <div>
                      <span>
                        <!-- <i [ngClass]="{'fa-check': site.isValid,'fa-times': !site.isValid }" class="fa"></i> -->
                      </span>
                      <span class="answer">
                        {{site.name}}
                      </span>
                      <sub class="answer" *ngIf="site.siteReference">[{{site.siteReference}}]</sub>
                    </div>
                    <div class="line-h10 small-font">
                      <span class="description truncate small-font">{{site.description}}</span>
                    </div>
                  </td>
                  <td>
                    <div>
                      <div class="small-font">
                        <span
                          [ngClass]="{'checked': fieldIsValid(site,'address'), 'unchecked': !fieldIsValid(site,'address')}">
                          Address:
                        </span>
                        <span class="answer">
                          {{site.address}}
                          <button class="btn btn-sm btn-primary p-1" *ngIf="showGetGoegrathicData(site)"
                            (click)="getLocation(site)">Lookup Address</button>
                        </span>
                      </div>
                    </div>
                    <div>
                      <div class="small-font">
                        <span
                          [ngClass]="{'checked': fieldIsValid(site,'latitude'), 'unchecked': !fieldIsValid(site,'latitude')}">
                          Latitude:
                        </span>
                        <span class="answer">{{site.latitude}}</span>
                      </div>
                      <div class="small-font">
                        <span
                          [ngClass]="{'checked': fieldIsValid(site,'longitude'), 'unchecked': !fieldIsValid(site,'longitude')}">
                          Longitude:
                        </span>
                        <span class="answer">{{site.longitude}}</span>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr [ngClass]="{'bk-warning': !isValidSite(site)}">
                  <td>
                    <div>
                      <span class="small-font"
                        [ngClass]="{'checked': fieldIsValid(site,'isPublicSite'), 'unchecked': !fieldIsValid(site,'isPublicSite')}">
                        Public
                        Site: <span class="answer">{{site.isPublicSite ? "Yes":"No"}}</span>
                      </span>
                    </div>
                    <div>
                      <span class="small-font"
                        [ngClass]="{'checked': fieldIsValid(site,'siteRadius'), 'unchecked': !fieldIsValid(site,'siteRadius')}">
                        Site
                        Radius: <span class="answer">
                          {{site.siteRadius}}<span *ngIf="site.siteRadius && site.siteRadius > 0"> m</span>
                        </span>
                      </span>
                    </div>
                    <div>
                      <span class="small-font"
                        [ngClass]="{'checked': fieldIsValid(site,'timezone'), 'unchecked': !fieldIsValid(site,'timezone')}">
                        Time
                        Zone: <span class="answer">{{site.timezone ? site.timezone : "[BASE ON COMPANY TIMEZONE]"}}</span>
                      </span>
                    </div>
                    <div>
                      <span class="small-font"
                        [ngClass]="{'checked': fieldIsValid(site,'siteSupervisorEmailAddress'), 'unchecked': !fieldIsValid(site,'siteSupervisorEmailAddress')}">
                        Site
                        Supervisor Email Address: <span class="answer">{{site.siteSupervisorEmailAddress |
                          nicyNull}}</span>
                      </span>
                    </div>
                    <div>
                      <span class="small-font"
                        [ngClass]="{'checked': fieldIsValid(site,'maxPeopleOnSiteMode'), 'unchecked': !fieldIsValid(site,'maxPeopleOnSiteMode')}">
                        Max
                        People On Site Mode: <span
                          class="answer">{{maxPeopleOnSiteModes[site.maxPeopleOnSiteMode]}}</span>
                      </span>
                    </div>
                    <div *ngIf="site.maxPeopleOnSiteMode != maxPeopleOnSiteModes.Disable">
                      <span class="small-font"
                        [ngClass]="{'checked': fieldIsValid(site,'maxPeopleOnSite'), 'unchecked': !fieldIsValid(site,'maxPeopleOnSite')}">
                        Max
                        People On Site: <span class="answer">
                          {{site.maxPeopleOnSite}}
                          (Vistors +
                          Accompaniers)
                        </span>
                      </span>
                    </div>
                    <div>
                      <span class="small-font"
                        [ngClass]="{'checked': fieldIsValid(site,'forceCheckOutTime'), 'unchecked': !fieldIsValid(site,'forceCheckOutTime')}">
                        End
                        Time Of Day: <span class="answer">{{site.forceCheckOutTime}}</span>
                      </span>
                    </div>
                    <div>
                      <span class="small-font"
                        [ngClass]="{'checked': fieldIsValid(site,'forceCheckOutAfterMinute'), 'unchecked': !fieldIsValid(site,'forceCheckOutAfterMinute')}">
                        Force Check Out After Time Period: <span class="answer"
                          *ngIf="site.forceCheckOutAfterMinute || site.forceCheckOutAfterMinute == 0">{{site.forceCheckOutAfterMinute}}
                          minutes</span>
                        <span class="answer"
                          *ngIf="site.forceCheckOutAfterMinute == undefined || site.forceCheckOutTime == null">Disable</span>
                      </span>
                    </div>
                    <div>
                      <span class="small-font"
                        [ngClass]="{'checked': fieldIsValid(site,'enableNotifyNearUser'), 'unchecked': !fieldIsValid(site,'enableNotifyNearUser')}">
                        Notify
                        Near Users: <span class="answer">{{site.enableNotifyNearUser ? "Yes":"No"}}</span>
                      </span>
                    </div>
                    <div>
                      <span class="small-font"
                        [ngClass]="{'checked': fieldIsValid(site,'checkinLocationMode'), 'unchecked': !fieldIsValid(site,'checkinLocationMode')}">
                        Location Required Mode On Check In/Out? <span class="answer">{{site.checkinLocationMode |
                          checkinLocationMode}}</span>
                      </span>
                    </div>
                    <div>
                      <span class="small-font"
                        [ngClass]="{'checked': fieldIsValid(site,'autoCheckInType'), 'unchecked': !fieldIsValid(site,'autoCheckInType')}">
                        Automatic
                        CheckIn: <span class="answer">
                          {{site.autoCheckInType | automaticCheckinMode}}
                        </span>
                      </span>
                    </div>
                    <div>
                      <span class="small-font"
                        [ngClass]="{'checked': fieldIsValid(site,'autoCheckOutType'), 'unchecked': !fieldIsValid(site,'autoCheckOutType')}">
                        Automatic
                        CheckOut: <span class="answer">
                          {{autoCheckOutTypes[site.autoCheckOutType]}}
                        </span>
                      </span>
                    </div>
                    <div>
                      <span class="small-font"
                        [ngClass]="{'checked': fieldIsValid(site,'optInQuestionMode'), 'unchecked': !fieldIsValid(site,'optInQuestionMode')}">
                        Opt-in Question Mode: <span class="answer">
                          {{site.optInQuestionMode | optInQuestionMode}}
                        </span>
                      </span>
                    </div>
                    <div>
                      <span class="small-font"
                        [ngClass]="{'checked': fieldIsValid(site,'optInQuestionMessage'), 'unchecked': !fieldIsValid(site,'optInQuestionMessage')}">
                        Opt-in Question Message: <span class="answer">
                          {{site.optInQuestionMessage}}
                        </span>
                      </span>
                    </div>
                  </td>
                  <td>
                    <div>
                      <span class="small-font"
                        [ngClass]="{'checked': fieldIsValid(site,'geofencingThresholdInMinutes'), 'unchecked': !fieldIsValid(site,'geofencingThresholdInMinutes')}">
                        Automatic
                        Check Out Threshold: <span class="answer">
                          {{site.geofencingThresholdInMinutes}} minutes
                        </span>
                      </span>
                    </div>
                    <div>
                      <span class="small-font"
                        [ngClass]="{'checked': fieldIsValid(site,'QrCodeCheckinMode'), 'unchecked': !fieldIsValid(site,'QrCodeCheckinMode')}">
                        Enable {{constants.ANONYMOUS_ATTENDANCE_TITLE}} Checkin: <span class="answer">
                          {{site.qrCodeCheckinMode | qRCodeCheckinMode}}
                        </span>
                      </span>
                    </div>
                    <div *ngIf="site.qrCodeCheckinMode != qrCodeCheckinMode.Disable">
                      <span class="small-font"
                        [ngClass]="{'checked': fieldIsValid(site,'forceCheckOutAnonymousAfterInMinute'), 'unchecked': !fieldIsValid(site,'forceCheckOutAnonymousAfterInMinute')}">
                        Auto Check Out {{constants.ANONYMOUS_ATTENDANCE_TITLE}} After: <span class="answer">
                          {{site.forceCheckOutAnonymousAfterInMinute}} minutes
                        </span>
                      </span>
                    </div>

                    <div>
                      <span class="small-font"
                        [ngClass]="{'checked': fieldIsValid(site,'accompanierMode'), 'unchecked': !fieldIsValid(site,'accompanierMode')}">
                        Accompanying
                        Persons Info: <span class="answer">
                          {{site?.accompanierMode | accompanierMode}}
                        </span>
                      </span>
                    </div>
                    <div>
                      <span class="small-font"
                        [ngClass]="{'checked': fieldIsValid(site,'multipleCheckinType'), 'unchecked': !fieldIsValid(site,'multipleCheckinType')}">
                        Multiple
                        Checkin: <span class="answer">
                          {{multipleCheckinTypes[site.multipleCheckinType]}}
                        </span>
                      </span>
                    </div>
                    <div>
                      <span class="small-font"
                        [ngClass]="fieldIsValid(site,'enableDelayedAnswerForCheckIn') ? 'checked' : 'unchecked'">
                        Enable Delayed Answer For CheckIn: <span class="answer">
                          {{site?.enableDelayedAnswerForCheckIn | yesNo}}
                        </span>
                      </span>
                    </div>
                    <div>
                      <span class="small-font"
                        [ngClass]="fieldIsValid(site,'enableDelayedAnswerForCheckOut') ? 'checked' : 'unchecked'">
                        Enable Delayed Answer For CheckOut: <span class="answer">
                          {{site?.enableDelayedAnswerForCheckOut | yesNo}}
                        </span>
                      </span>
                    </div>
                    <div>
                      <span class="small-font"
                        [ngClass]="fieldIsValid(site,'delayedAnswerForCheckInThresholdInHour') ? 'checked' : 'unchecked'">
                        Delayed Answer For CheckIn Threshold In Hour: <span class="answer">
                          {{site?.delayedAnswerForCheckInThresholdInHour | nicyNull}}
                        </span>
                      </span>
                    </div>
                    <div>
                      <span class="small-font"
                        [ngClass]="fieldIsValid(site,'delayedAnswerForCheckOutThresholdInHour') ? 'checked' : 'unchecked'">
                        Delayed Answer For CheckOut Threshold In Hour: <span class="answer">
                          {{site?.delayedAnswerForCheckOutThresholdInHour | nicyNull}}
                        </span>
                      </span>
                    </div>
                    <div>
                      <span class="small-font"
                        [ngClass]="fieldIsValid(site,'delayedAnswerNotificationFrequencyInMinute') ? 'checked' : 'unchecked'">
                        Delayed Answer Notification Frequency In Minute: <span class="answer">
                          {{site?.delayedAnswerNotificationFrequencyInMinute | nicyNull}}
                        </span>
                      </span>
                    </div>
                    <div>
                      <span class="small-font"
                        [ngClass]="fieldIsValid(site,'delayedAnswerNotificationAfterCheckOutNotifyCount') ? 'checked' : 'unchecked'">
                        Delayed Answer Notification After CheckOut Notify Count: <span class="answer">
                          {{site?.delayedAnswerNotificationAfterCheckOutNotifyCount | nicyNull}}
                        </span>
                      </span>
                    </div>
                    <div>
                      <span class="small-font"
                        [ngClass]="fieldIsValid(site,'AutoCheckInDelayInMinute') ? 'checked' : 'unchecked'">
                        Automatic Check In Delay (Minute): <span class="answer">
                          {{site?.autoCheckInDelayInMinute | nicyNull}}
                        </span>
                      </span>
                    </div>
                    <div>
                      <span class="small-font"
                        [ngClass]="fieldIsValid(site,'checkInApprovalMode') ? 'checked' : 'unchecked'">
                        Check-In Approval Mode: <span class="answer">
                          {{getCheckinApprovalModeFriendlyMode(site?.checkInApprovalMode) | nicyNull}}
                        </span>
                      </span>
                    </div>
                    <div>
                      <span class="small-font"
                        [ngClass]="fieldIsValid(site,'checkInLocationOverrideInKioskMode') ? 'checked' : 'unchecked'">
                        Override Check-In Location To Optional Mode In Kiosk: <span class="answer">
                          {{getcheckInLocationOverrideInKioskMode(site?.checkInLocationOverrideInKioskMode) | nicyNull}}
                        </span>
                      </span>
                    </div>

                    <!-- <div>
                      <span class="small-font"
                        [ngClass]="fieldIsValid(site,'enablePreCheckIn') ? 'checked' : 'unchecked'">
                        Enable Pre Check-In: <span class="answer">
                          {{site?.enablePreCheckIn | nicyNull}}
                        </span>
                      </span>
                    </div>
                    <div>
                      <span class="small-font"
                        [ngClass]="fieldIsValid(site,'enablePreCheckInForFrequentUsers') ? 'checked' : 'unchecked'">
                        Enable Pre Check-In For Frequent Users: <span class="answer">
                          {{site?.enablePreCheckInForFrequentUsers | nicyNull}}
                        </span>
                      </span>
                    </div>
                    <div>
                      <span class="small-font"
                        [ngClass]="fieldIsValid(site,'enablePreCheckInByCode') ? 'checked' : 'unchecked'">
                        Enable Pre Check-In By Code: <span class="answer">
                          {{site?.enablePreCheckInByCode | nicyNull}}
                        </span>
                      </span>
                    </div> -->
                  </td>
                </tr>
              </ng-container>
              <tr [ngClass]="{'bk-warning': !isValidSite(site)}"
                *ngIf="site.validationStatus != importRecordStates.Valid">
                <td colspan="2">
                  <div *ngIf="site.validationStatus == importRecordStates.Corrupted">
                    #{{index+1}}
                  </div>
                  <ul>
                    <li style="list-style: none;" class="error" *ngFor="let error of site.errorMessages">
                      {{error.errorMessage}}
                    </li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td class="p-1" style="background-color: #cf7200;" colspan="2">
                </td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
