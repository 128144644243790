import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, Validators } from '@angular/forms';
import { values } from 'lodash';
import { deepCloneJson } from 'src/app/helpers/general-functions';
import { AttendanceFieldModel, AttendanceFieldWithValue, AttendanceFieldViewModel, FieldPermission, FieldValueType } from 'src/app/models/attendance-field-model';

@Component({
  selector: 'app-attendance-field',
  templateUrl: './attendance-field.component.html',
  styleUrls: ['./attendance-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: AttendanceFieldComponent,
      multi: true,
    }
  ]
})
export class AttendanceFieldComponent implements ControlValueAccessor {
  mode: ('edit' | 'view') = "edit";
  @Input() renderMode: ('column' | 'list') = 'list';
  @Input() hideLabel: boolean = false;
  _isHost: boolean = false;
  @Input() set isHost(value: boolean) {
    this._isHost = value;
    if (this.formControl == null) return;
    this.updateFormControlStatus();
  }
  _isEditable: boolean;
  @Input() set isEditable(value: boolean) {
    this._isEditable = value;
    this.mode = this._isEditable ? "edit" : "view";
    if (this.formControl == null) return;
    this.updateFormControlStatus();
  };

  _field: AttendanceFieldWithValue;
  _lastSavedValue: AttendanceFieldWithValue;
  formControl: FormControl;
  onchange: Function;
  ontouch: Function;
  FieldValueType = FieldValueType;
  FieldPermission = FieldPermission;
  @Output() save = new EventEmitter();
  constructor() { }
  updateFormControlStatus() {
    if (this.permission == FieldPermission.Disabled)
      throw "Disabled form!";
    else if (this.permission == FieldPermission.ViewOnly)
      this.formControl.disable();
    else {
      if (this.mode == 'view')
        this.formControl.disable();
      else
        this.formControl.enable();
    }
  }


  writeValue(obj: AttendanceFieldWithValue): void {
    this._field = obj;
    this._lastSavedValue = deepCloneJson(this._field);
    if (this.formControl == null)
      this.formControl = new FormControl(obj.value, []);
    else
      this.formControl.setValue(obj.value);
    this.updateFormControlStatus();
    if (performance)
      this.formControl.valueChanges.subscribe(res => {
        this._field.value = res;
        this.onchange(this._field);
      })
  }

  registerOnChange(fn: any): void {
    this.onchange = fn;
  }
  registerOnTouched(fn: any): void {
    this.ontouch = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled)
      this.formControl.disable();
    else
      this.formControl.enable();
  }

  validate({ value }: FormControl) {
    return this.formControl.errors;
  }

  registerOnValidatorChange?(fn: () => void): void {
  }

  onSave() {
    this.save.emit({
      fieldKey: this._field.fieldKey,
      value: this._field.value,
    } as AttendanceFieldViewModel);
    this._lastSavedValue = deepCloneJson(this._field);
  }

  onDiscardChanges() {
    this.writeValue(this._lastSavedValue);
  }

  get permission(): FieldPermission {
    if (this._field == null) return FieldPermission.Disabled;
    if (this._isHost)
      return this._field.hostPermission;
    else
      return this._field.userPermission;
  }
}
