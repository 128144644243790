import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { UserAutoCompleteFilterByFields } from 'src/app/enums/user-auto-complete-target-fields';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { UserBasicInfoViewModel } from 'src/app/models/user-basic-info-viewmodel';
import { SiteAttendanceService } from 'src/app/services/site-attendance.service';
import { SiteQuestionsViewModel } from 'src/app/models/sitequestion-viewmodel';
import { e168Validator2 } from 'src/app/helpers/mobile-format-validator';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { SiteAttendanceQuestionAnswer } from 'src/app/models/attendance-request/site-attendance-question-answer';
import {
  AnonymousCheckInAttendanceRequestModel,
  ManualCheckInAttendanceRequestViewModel,
  RequestAttendanceMode,
  RequestSource
} from '../../../models/attendance-request/attendance-request-viewmodel';
import {
  RequiredDocumentViewModel,
  UserDocumentType
} from '../../../models/user-document-type';
import { finalize } from 'rxjs/operators';
import { BaseSiteWithVisitorTypesViewModel } from 'src/app/models/site-viewmodel';
import { AccompanyPeopleResult } from 'src/app/models/accompany-people-result';
import { BaseSiteRequiredFieldViewModel, SiteRequiredFieldValue } from 'src/app/models/site-required-field-viewmodel';
import {
  SiteVisitorTypeViewModel
} from '../../../models/site-visitortype-accompanier-viewmodel';
import { AccompanierMode } from '../../../enums/accompanier-mode';
import { AttendanceFieldWithValue, FieldPermission } from 'src/app/models/attendance-field-model';
import { AttendanceFieldRendererComponent } from '../../attendance-field-renderer/attendance-field-renderer.component';
import { OptInQuestionMode } from '../../../enums/opt-in-question-mode.enum';
import { SiteAttendanceInductionViewModel } from 'src/app/models/site-attendance-induction-view-model';
import { UserSelectorComponent } from 'src/app/custom-controls/user-selector/user-selector.component';
import { CheckInMode } from 'src/app/enums/checkin-mode.enum';
import { AnonymousUserInfo } from 'src/app/models/anonynous-userinfo';
import { StorageService } from 'src/app/services/storage.service';
import { SiteRequiredFieldWithAnswerViewModel } from 'src/app/models/site-required-field-with-answer-view-model';
import { CountryViewModel } from 'src/app/models/country-view-model';
import { ModalService } from 'src/app/services/modal.service';
import { Router } from '@angular/router';
import { SupplierDocumentViewModel } from 'src/app/models/supplier-document/site-supplier-document-version-signoff-viewModel';
import { SupplierDocumentRendererComponent } from '../../supplier-documents/supplier-document-renderer/supplier-document-renderer.component';
import { UserDocumentTypeService } from "../../../services/user-document-type.service";
import {
  convertDocumentToFormControl,
  deepCloneCircular,
  getUploadedDocumentsFromFormArray, setDocumentFormControlForQuestions, isSetSupplierCheckinData,
} from "../../../helpers/general-functions";
import { SupplierDocumentConfigMode } from 'src/app/enums/supplier-document-config-mode.enum';
import { CustomFormControl } from "../../../models/custom-formcontrol";
import { AdditionalDocumentConfig } from "../../../models/induction-viewmodel";
import {
  BaseSiteAnnouncementViewModel,
  InternalAnnouncementVisitViewModel
} from 'src/app/models/announcement-viewmodel';
import { BaseAnnouncementVisit, VisitStatus } from 'src/app/models/announcement-visit';
import { SiteAnnouncementForceViewMode } from 'src/app/enums/site-announcement-force-view-mode';
import { SupplierCheckinViewModel } from "../../../models/supplier-checkin-view-model";
import { SupplierCheckinModel } from "../../../models/supplier-checkin-model";
import {RequiredDocumentAnswer} from "../../../models/requiredDocumentAnswer";

@Component({
  selector: 'obc-manual-checkin',
  templateUrl: './manual-checkin.component.html',
  styleUrls: ['./manual-checkin.component.scss'],
})
export class ManualCheckinComponent implements OnInit {

  @Output() needToUpdateAttendanceCount = new EventEmitter();
  static UserInfoStoreKey = 'UserInfoStoreKey'
  inProgress: boolean = false;
  errorMessage: string = null;
  successMessage: string = null;
  allowComminiucateWithUser?: boolean = false;

  // enums
  OptInQuestionMode = OptInQuestionMode;
  FilterUserBy = UserAutoCompleteFilterByFields;

  @Input() mode: CheckInMode = CheckInMode.Host;

  // questions
  questions: SiteQuestionsViewModel[] = null;
  questionContainerValidation$: BehaviorSubject<{ valid: boolean, answers: SiteAttendanceQuestionAnswer[] }>
    = new BehaviorSubject<{ valid: boolean, answers: SiteAttendanceQuestionAnswer[] }>({ valid: false, answers: [] });
  questionContainerValidationSubscription: Subscription;
  questionContainerValid: boolean = true;


  answers: SiteAttendanceQuestionAnswer[];

  // user selector component
  @ViewChild("userSelector") userSelector: UserSelectorComponent;

  // accompanier mode
  accompanierMode: AccompanierMode;
  accompanyData$: Subject<AccompanyPeopleResult> = new Subject<AccompanyPeopleResult>();
  accompanyPeopleResult: AccompanyPeopleResult;
  supplierDocumentConfigMode: SupplierDocumentConfigMode;

  // required fields
  requiredFields: BaseSiteRequiredFieldViewModel[] = null;
  requiredFieldRenderer$: BehaviorSubject<{ valid: boolean, values: SiteRequiredFieldValue[] }> =
    new BehaviorSubject<{ valid: boolean, values: SiteRequiredFieldValue[] }>({ valid: false, values: null });
  requiredFieldContainerValidationSubscription: Subscription;
  requiredFieldValues: SiteRequiredFieldValue[] = null;
  isSiteRequiredFieldContainerValid: boolean = true;
  hasPermissionToAddSupplierDocumentComment: boolean;
  supplierDocCommentAcknowledgementText: string;

  // extra fields
  attendanceFields: AttendanceFieldWithValue[] = null;

  requiredDocumentFormControls: CustomFormControl[];
  requiredDocumentFormArray: FormArray;
  additionalDocumentConfig: AdditionalDocumentConfig;

  availableDocumentTypes: UserDocumentType[];
  selectedDocumentsIds: number[] = [];
  announcements: BaseSiteAnnouncementViewModel[] = [];
  visitedAnnouncements: BaseAnnouncementVisit[] = [];


  getFilteredExtraFields(isHost: boolean, isEdit: boolean) {
    return this.attendanceFields.filter(x => {
      const permission = isHost ? x.hostPermission : x.userPermission;
      return permission != FieldPermission.Disabled && (isEdit ? permission == FieldPermission.ViewAndEdit : false);
    });
  }

  @ViewChild('attendanceFieldsRef') attendanceFieldsRef: AttendanceFieldRendererComponent;

  //induction
  inductions: SiteAttendanceInductionViewModel[] = [];


  //supplier documents
  supplierDocuments: SupplierDocumentViewModel[] = [];
  @ViewChild('supplierDocumentRenderer') supplierDocumentRenderer: SupplierDocumentRendererComponent;


  documents: RequiredDocumentViewModel[] = [];
  allowUsersToAttachAdditionalDocuments: boolean = false;
  CheckInMode = CheckInMode;

  selectedSupplierId: number = null;
  finalUser: UserBasicInfoViewModel = new UserBasicInfoViewModel();

  get selectedVisitorType(): SiteVisitorTypeViewModel {
    return this.selectedVisitorTypeFormControl.value;
  };

  selectedVisitorTypeFormControl = new FormControl()

  forceViewSupplierDocumentsBeforeSignOff: boolean = true;

  allUserDocumentTypes: UserDocumentType[];
  supplierCompanyAllUserDocumentTypes: UserDocumentType[];


  //Supplier Check In Data
  supplierCheckinData: SupplierCheckinViewModel;
  supplierAnswers: SiteAttendanceQuestionAnswer[];
  supplierQuestionContainerValidation$: BehaviorSubject<{ valid: boolean, answers: SiteAttendanceQuestionAnswer[] }>
    = new BehaviorSubject<{ valid: boolean, answers: SiteAttendanceQuestionAnswer[] }>({ valid: false, answers: [] });
  supplierQuestionContainerValidationSubscription: Subscription;
  supplierQuestionContainerValid: boolean = true;
  supplierQuestions: SiteQuestionsViewModel[] = null;
  supplierVisitedAnnouncements: BaseAnnouncementVisit[] = [];
  supplierRequiredDocumentFormControls: CustomFormControl[];
  supplierRequiredDocumentFormArray: FormArray;

  get isMobileValid(): boolean {
    return new FormControl(this.mode == CheckInMode.Host ? this.finalUser?.mobile : this.userInfoFormGroup.controls.mobile.value, [e168Validator2])?.valid;
  }


  private _site: BaseSiteWithVisitorTypesViewModel = null;
  @Input() set site(value: BaseSiteWithVisitorTypesViewModel) {
    if (this._site?.id != value?.id && value?.id) {
      this.getHostCheckInData();
    }
    this._site = value;
  }

  //#region anonymous
  @Input() selectedCountry: CountryViewModel;

  loadUserInformation() {
    try {
      let info = this.getStoredAnonymousUserInfo();
      if (info) {
        let user = info.user;
        let fields = info.fields as SiteRequiredFieldValue[];
        if (user) {
          if (user.countryRegion)
            this.selectedCountry.alpha2 = user.countryRegion;
          if (user.mobile)
            this.userInfoFormGroup.controls['mobile'].setValue(user.mobile);
          if (user.firstName)
            this.userInfoFormGroup.controls['firstName'].setValue(user.firstName);
          if (user.lastName)
            this.userInfoFormGroup.controls['lastName'].setValue(user.lastName);

        }
        if (fields && fields.length) {
          for (const field of this.requiredFields) {
            let answers = fields.filter(v => v.fieldKey == field.fieldKey);
            if (answers && answers.length) {
              if (!this.requiredFieldValues)
                this.requiredFieldValues = [];
              let answer = answers[0].value;
              if (field.fieldKey == 'BirthDay')
                answer = new Date(answer);
              this.requiredFieldValues.push({ fieldKey: field.fieldKey, value: answer });
              (field as SiteRequiredFieldWithAnswerViewModel).answer = answer;
            }
          }
          this.requiredFields = JSON.parse(JSON.stringify(this.requiredFields));
        }

      }
    } catch {
    }
  }

  getStoredAnonymousUserInfo(): AnonymousUserInfo {
    let json = this.storageService.get(ManualCheckinComponent.UserInfoStoreKey);
    try {
      if (json != null && json.toString().length) {
        return JSON.parse(json);
      }
    } catch {
    }
    return null;
  }

  userInfoFormGroup = new FormGroup({
    mobile: new FormControl('', [e168Validator2]),
    lastName: new FormControl('', []),
    firstName: new FormControl('', []),
  });
  @Input() sessionId: string = null;

  //#endregion


  get siteId(): number {
    return this._site?.id;
  }

  get site(): BaseSiteWithVisitorTypesViewModel {
    return this._site;
  }

  constructor(private siteAttendanceService: SiteAttendanceService,
    private modalService: ModalService,
    private router: Router,
    private storageService: StorageService,
    private userDocumentTypeService: UserDocumentTypeService) {

  }

  ngOnInit() {
    this.selectedVisitorTypeFormControl.valueChanges.subscribe(() => {
      this.getHostCheckInData();
    });

    if (this.mode == CheckInMode.Anonymous) {
      this.userInfoFormGroup.controls['mobile'].valueChanges.subscribe(() => this.getHostCheckInData());
      this.loadUserInformation();
    }

    this.accompanyData$.subscribe((data) => {
      this.accompanyPeopleResult = data;
    });
  }

  user: any = {};

  onSelectUser($event) {
    this.finalUser = new UserBasicInfoViewModel($event);
    if (this.user.mobile != this.finalUser.mobile || this.user.id != this.finalUser.id) {
      this.getHostCheckInData();
      this.user = this.finalUser;
    }
  }

  onInputChange($event) {
    this.finalUser[$event.field] = $event.value;
    if ($event.field == 'mobile')
      if (this.isMobileValid === true) {
        this.getHostCheckInData();
      }
  }

  isReadyToGetCheckInData(): boolean {

    return this.isMobileValid &&
      this.siteId &&
      (
        (this._site.siteVisitorTypes?.length && this.selectedVisitorType != null)
        ||
        !(this._site.siteVisitorTypes?.length > 0)
      );
  }

  onUpdateUserDocumentsbySupplier(supplierId: number) {
    if (this.selectedSupplierId != supplierId) {
      this.selectedSupplierId = supplierId;
      this.getHostCheckInData();
    }
  }

  getHostCheckInData() {
    if (!this.isReadyToGetCheckInData()) return;
    //fetch check-in data
    this.inProgress = true;
    const model = this.mode == CheckInMode.Host ? {
      siteId: this.siteId,
      siteVisitorTypeId: this.selectedVisitorType?.siteVisitorTypeId,
      supplierId: this.selectedSupplierId,
      checkInUserId: this.finalUser.id,
      mobile: this.finalUser.mobile,
    } : {
      session: this.sessionId,
      siteVisitorTypeId: this.selectedVisitorType?.siteVisitorTypeId,
      supplierId: this.selectedSupplierId,
      mobile: this.userInfoFormGroup.controls.mobile.value,
      countryRegion: this.selectedCountry.alpha2,
    };
    const request = this.mode == CheckInMode.Host ?
      this.siteAttendanceService.getHostCheckInData(model) :
      this.siteAttendanceService.getAnonymousCheckInData(model);

    this.userDocumentTypeService.getAll(this.sessionId)
      .pipe(finalize(() => this.inProgress = false))
      .subscribe((res) => {
        this.allUserDocumentTypes = res;


        request
          .pipe(finalize(() => this.inProgress = false))
          .subscribe(result => {
            /*
                        result.data?.inductions.forEach(function (induction: SiteAttendanceInductionViewModel, _) {
                          let inductionDocuments = induction?.documents ?? [];

                          let inductionDocumentQuestions = convertDocumentQuestionToRequiredDocument(induction?.questions, induction.induction.id);
                          induction.documents = [...inductionDocuments, ...inductionDocumentQuestions];
                        });*/
            this.updateRequiredFields(result.data.requiredFields);
            this.updateQuestions(result.data.siteQuestions);
            this.documents = result.data?.documents;
            this.allowUsersToAttachAdditionalDocuments = result.data.allowUsersToAttachAdditionalDocuments;
            this.inductions = result.data.inductions;
            this.supplierDocuments = result.data.supplierDocuments;
            this.attendanceFields = result.data.extraFields;
            this.accompanierMode = result.data.accompanierMode;
            this.forceViewSupplierDocumentsBeforeSignOff = result.data.forceViewSupplierDocumentsBeforeSignOff ?? false
            this.supplierDocumentConfigMode = result.data.supplierDocumentConfigMode ?? false
            this.additionalDocumentConfig = result.data?.additionalDocumentConfig ?? {
              allowUsersToAttachAdditionalDocuments: result.data.allowUsersToAttachAdditionalDocuments,
              order: 999999
            };
            this.hasPermissionToAddSupplierDocumentComment = result.data?.hasPermissionToAddSupplierDocumentComment;
            this.supplierDocCommentAcknowledgementText = result.data?.supplierDocCommentAcknowledgementText;
            this.announcements = result.data?.announcements;
            this.supplierCheckinData = result.data?.supplierCheckinData;
            this.updateSupplierQuestions(result.data?.supplierCheckinData?.siteQuestions);
            this.refreshDocuments();
            this.fetchSupplierCompanyDocumentTypes();
          }, error => {
            this.errorMessage = 'we can not get required fields for selected user';
          })
      });
  }

  fetchSupplierCompanyDocumentTypes() {
    if(this.supplierCheckinData && this.supplierCheckinData?.supplierCompanyId) {
      this.userDocumentTypeService.companyGetAll(this.supplierCheckinData?.supplierCompanyId)
          .pipe(finalize(() => this.inProgress = false))
          .subscribe((res) => {
            this.supplierCompanyAllUserDocumentTypes = res;
          });
    }
  }

  updateQuestions(questions: SiteQuestionsViewModel[]) {
    this.resetQuestionRenderer();
    this.questions = questions;
    this.questions = setDocumentFormControlForQuestions(this.questions);
    this.questionContainerValidationSubscription = this.questionContainerValidation$.subscribe((res) => {
      if (this.questions == null || this.questions.length == 0) return;
      this.questionContainerValid = res.valid;
      this.answers = res.answers;
    });
  }

  updateSupplierQuestions(questions: SiteQuestionsViewModel[]) {
    if (this.supplierQuestionContainerValidationSubscription)
      this.supplierQuestionContainerValidationSubscription.unsubscribe();
    this.supplierQuestionContainerValid = true;
    this.supplierQuestions = null;

    this.supplierQuestions = questions;
    this.supplierQuestions = setDocumentFormControlForQuestions(this.supplierQuestions);
    this.supplierQuestionContainerValidationSubscription = this.supplierQuestionContainerValidation$.subscribe((res) => {
      if (this.supplierQuestions == null || this.supplierQuestions.length == 0) return;
      this.supplierQuestionContainerValid = res.valid;
      this.supplierAnswers = res.answers;
    });
  }


  resetQuestionRenderer() {
    if (this.questionContainerValidationSubscription)
      this.questionContainerValidationSubscription.unsubscribe();
    this.questionContainerValid = true;
    this.questions = null;
  }


  updateRequiredFields(requiredFields: BaseSiteRequiredFieldViewModel[]) {
    this.resetRequiredFieldContainer();
    this.requiredFields = requiredFields;
    this.requiredFieldContainerValidationSubscription = this.requiredFieldRenderer$.subscribe(res => {
      if (this.requiredFields == null || this.requiredFields.length == 0) return;
      this.isSiteRequiredFieldContainerValid = res.valid;
      this.requiredFieldValues = res.values;
    });
  }

  resetRequiredFieldContainer() {
    if (this.requiredFieldContainerValidationSubscription)
      this.requiredFieldContainerValidationSubscription.unsubscribe();
    this.isSiteRequiredFieldContainerValid = true;
    this.requiredFields = null;
  }

  getOptInCommunicationMessage() {
    return (this.site?.optInQuestionMode == OptInQuestionMode.CustomWithMessage && (this.site?.optInQuestionMessage ?? "") != "")
      ? this.site?.optInQuestionMessage
      : "Opt-in to communication from this site?";
  }

  get isFormValid(): boolean {
    
    let isValidDocuments = this.requiredDocumentFormArray == null ||
      this.requiredDocumentFormArray.controls.every(c => {
        let control = (c as CustomFormControl);
        let doc = control.item as RequiredDocumentViewModel;
        return doc.questionId == null ? c.valid : true;
      });

    let isValidSupplierDocuments = this.supplierRequiredDocumentFormArray == null ||
      this.supplierRequiredDocumentFormArray.controls.every(c => {
        let control = (c as CustomFormControl);
        let doc = control.item as RequiredDocumentViewModel;
        return doc.questionId == null ? c.valid : true;
      });

    let isAllAnnouncementsVisitedAndSignedOff = this.isAllAnnouncementsVisitedAndSignedOff(this.announcements);
    let isAllSupplierAnnouncementsVisitedAndSignedOff = this.isAllAnnouncementsVisitedAndSignedOff(this.supplierCheckinData?.announcements);

    return this.isInductionsValid && this.isSupplierInductionsValid &&
      !(this.attendanceFieldsRef?.valid == false) &&
      this.isMobileValid &&
      this.questionContainerValid &&
      this.supplierQuestionContainerValid &&
      (isValidDocuments && isValidSupplierDocuments) &&
      !(this.accompanyPeopleResult?.valid == false) &&
      this.isSiteRequiredFieldContainerValid &&
      (this.supplierDocumentRenderer == null || this.supplierDocumentRenderer.valid()) &&
      isAllAnnouncementsVisitedAndSignedOff && isAllSupplierAnnouncementsVisitedAndSignedOff;
  }

  isAllAnnouncementsVisitedAndSignedOff(announcements: BaseSiteAnnouncementViewModel[]) {
    if (announcements?.length == 0)
      return true;
    if (announcements?.some(s => s.forceViewMode != SiteAnnouncementForceViewMode.Disable && s.isVisited == false))
      return false;
    if (announcements?.some(s => s.forceViewMode == SiteAnnouncementForceViewMode.MandatoryViewAndSignOff && s.isCheckedMandatorySignOff == false))
      return false;
    return true;
  }

  private get isInductionsValid() {
    if (this.inductions?.length) {
      return !this.inductions.some(x => x.isValid == false);
    }
    return true;
  }

  private get isSupplierInductionsValid() {
    if (this.supplierCheckinData?.inductions?.length) {
      return !this.supplierCheckinData?.inductions?.some(x => x.isValid == false);
    }
    return true;
  }

  onCheckin() {
    this.inProgress = true;
    let documents = getUploadedDocumentsFromFormArray(this.requiredDocumentFormArray);
    let supplierDocuments = getUploadedDocumentsFromFormArray(this.supplierRequiredDocumentFormArray);
    
    let inductions = this.inductions?.length ? this.inductions.filter(x => !x.skipped)?.map(x => deepCloneCircular(x.answers)) ?? [] : [];
    let supplierInductions = this.supplierCheckinData?.inductions?.length ? this.supplierCheckinData?.inductions?.filter(x => !x.skipped)?.map(x => deepCloneCircular(x.answers)) ?? [] : [];

    // inductions and supplierInductions: Resetting RequiredDocumentAnswers as document answers instead of FormControls to send to server. 
    this.prepareInductionDocumentAnswers(inductions);
    this.prepareInductionDocumentAnswers(supplierInductions);
    
    let userInfo = this.mode == CheckInMode.Host ? null : {
      countryRegion: this.selectedCountry.alpha2,
      mobile: this.userInfoFormGroup.get('mobile').value,
      firstName: this.userInfoFormGroup.get('firstName').value,
      lastName: this.userInfoFormGroup.get('lastName').value,
    } as UserBasicInfoViewModel;
    const request = this.mode == CheckInMode.Host ? this.siteAttendanceService
      .ManualCheckIn({
        Mode: RequestAttendanceMode.Manual,
        siteAttendanceInfo: this.finalUser,
        siteId: this.siteId,
        answers: this.answers,
        documents: documents,
        inductions: inductions,
        accompanierCount: this.accompanyPeopleResult?.count,
        accompaniers: this.accompanyPeopleResult?.persons,
        allowComminiucateWithUser: this.site.optInQuestionMode == OptInQuestionMode.Disable ? null : this.allowComminiucateWithUser,
        requiredFieldValues: this.requiredFieldValues,
        visitorTypeId: this.selectedVisitorType?.visitorTypeId,
        signedSupplierDocumentTypeVersions: this.supplierDocumentRenderer.getAnswers(),
        extraFieldValues: this.attendanceFieldsRef != null ? this.attendanceFieldsRef.values : [],
        visitedAnnouncements: this.visitedAnnouncements,
        supplierCheckinData: {
          supplierInductions: supplierInductions,
          supplierAnswers: this.supplierAnswers,
          supplierVisitedAnnouncements: this.supplierVisitedAnnouncements,
          supplierDocuments: supplierDocuments
        } as SupplierCheckinModel,
        requestSource: RequestSource.Portal
      } as ManualCheckInAttendanceRequestViewModel) : this.siteAttendanceService.AnonymousCheckIn({
        Mode: RequestAttendanceMode.Manual,
        siteAttendanceInfo: userInfo,
        siteId: this.siteId,
        session: this.sessionId,
        answers: this.answers,
        documents: documents,
        inductions: inductions,
        accompanierCount: this.accompanyPeopleResult?.count,
        accompaniers: this.accompanyPeopleResult?.persons,
        allowComminiucateWithUser: this.site.optInQuestionMode == OptInQuestionMode.Disable ? null : this.allowComminiucateWithUser,
        requiredFieldValues: this.requiredFieldValues,
        visitorTypeId: this.selectedVisitorType?.visitorTypeId,
        signedSupplierDocumentTypeVersions: this.supplierDocumentRenderer.getAnswers(),
        extraFieldValues: this.attendanceFieldsRef != null ? this.attendanceFieldsRef.values : [],
        visitedAnnouncements: this.visitedAnnouncements,
        supplierCheckinData: {
          supplierInductions: supplierInductions,
          supplierAnswers: this.supplierAnswers,
          supplierVisitedAnnouncements: this.supplierVisitedAnnouncements,
          supplierDocuments: supplierDocuments
        } as SupplierCheckinModel
      } as AnonymousCheckInAttendanceRequestModel)


    request
      .pipe(finalize(() => (this.inProgress = false)))
      .subscribe(
        (res) => {
          if (res.success === true) {
            this.successMessage = res.message;
            this.resetForms(); // clean form

            if (this.mode == CheckInMode.Host) {
              this.finalUser = null;
              this.needToUpdateAttendanceCount.emit();
            } else {
              this.storeUserInformation(userInfo, this.requiredFieldValues);
              this.userInfoFormGroup.reset(); // clean form
              setTimeout(() => {
                this.successMessage = null;
                this.router.navigate(['anonymous']).then()
              }, 1000);
            }
          } else {
            this.modalService.error(res.message);
            this.errorMessage = res.message;
          }
          setTimeout(() => {
            this.errorMessage = null;
            this.successMessage = null;
          }, 10000);
        },
        (err) => console.log(err)
      );
  }

  private prepareInductionDocumentAnswers(inductions: SiteAttendanceInductionViewModel[]) {
    inductions.forEach((ind) => {
      let documentsAnswers: RequiredDocumentAnswer[] = [];
      ind.answers?.documents?.forEach((indDoc) => {
        if (indDoc.value) {
          documentsAnswers.push(indDoc.value as RequiredDocumentAnswer)
        }
      })
      ind.answers.documents = documentsAnswers;
    })
  }

  storeUserInformation(userInfo: UserBasicInfoViewModel, fields: SiteRequiredFieldValue[]) {
    try {
      let newData = { user: userInfo, fields: fields } as AnonymousUserInfo;
      let savedData = this.getStoredAnonymousUserInfo();
      if (newData.fields == null)
        newData.fields = [];
      if (savedData?.fields) {
        for (const of of savedData.fields) {
          if (!newData.fields.some(s => s.fieldKey == of.fieldKey))
            newData.fields.push(of);
        }
      }

      let birthDay = newData.fields?.filter(v => v.fieldKey == 'BirthDay');
      if (birthDay?.length)
        birthDay[0].value = this.getCalendarDateAsStarndardFormat(birthDay[0].value);
      this.storageService.set(ManualCheckinComponent.UserInfoStoreKey, JSON.stringify(newData));
    } catch {
    }
  }

  getCalendarDateAsStarndardFormat(date: string): any {
    let dateParts = date.substr(0, date.indexOf(',')).split('/');
    return new Date(+dateParts[2], (+dateParts[1]) - 1, +dateParts[0]);//.toISOString();
  }

  resetForms() {
    this.answers = null;
    this.allowComminiucateWithUser = false;
    this.requiredFieldValues = null;
    this.selectedVisitorTypeFormControl.setValue(null);
    this.selectedSupplierId = null;
    if (this.userSelector)
      this.userSelector.reset();

    this.ResetAll();
  }


  get isSelectedVisitorTypeValid(): boolean {
    if (!(this.site.siteVisitorTypes && this.site.siteVisitorTypes.length > 0))
      return true;
    else if ((this.site.siteVisitorTypes && this.site.siteVisitorTypes.length > 0) && this.selectedVisitorType?.visitorTypeId != null)
      return true;
    return false;
  }


  addSelectedDocumentToRequiredDocuments(documentType) {
    if (documentType) {
      this.documents = [...this.documents, {
        documentType: documentType,
        documentTypes: [documentType],
        displayOrder: ((this.documents?.length ?? 0) + (this.questions?.length ?? 0) + 1) ?? 1,
        additionalDocument: true
      } as RequiredDocumentViewModel];
      this.refreshDocuments();
    }
  }

  removeDocumentFromRequiredDocuments(documentType) {
    if (documentType) {
      this.documents = [...this.documents?.filter(item => item.documentType.id != documentType.id)];
      this.refreshDocuments();
    }
  }

  addDocumentToInductionDocuments(documentType, induction: SiteAttendanceInductionViewModel, isSupplierInduction: boolean = false) {
    if (documentType) {
      let inductionsSource = isSupplierInduction == false ? this.inductions : (this.supplierCheckinData?.inductions ?? []);
      let inductionIndex = inductionsSource.findIndex(findInduction => findInduction.induction.id === induction.induction.id);
      let newInduction = {...induction};
      newInduction.documents.push({
        documentType: documentType,
        documentTypes: [documentType],
        displayOrder: ((newInduction.documents?.length ?? 0) + (newInduction.questions?.length ?? 0) + 1) ?? 1,
        additionalDocument: true
      } as RequiredDocumentViewModel);
      inductionsSource.splice(inductionIndex, 1, newInduction);
    }
  }

  removeDocumentFromInductionDocuments(reqDocument, induction: SiteAttendanceInductionViewModel, isSupplierInduction: boolean = false) {
    if (reqDocument) {
      let inductionsSource = isSupplierInduction == false ? this.inductions : (this.supplierCheckinData?.inductions ?? []);
      let inductionIndex = inductionsSource?.findIndex(findInduction => findInduction.induction.id === induction.induction.id);
      let newInduction = deepCloneCircular(induction);
      newInduction.documents = [...newInduction.documents?.filter(item => item.documentType.id != reqDocument.id)];
      inductionsSource.splice(inductionIndex, 1, newInduction);
    }
  }

  ResetAll() {
    this.requiredDocumentFormControls = [];
    this.supplierRequiredDocumentFormControls = [];
    let res = convertDocumentToFormControl(this.documents, (this.questions?.filter(question => question.documentFormControl != null)?.map(question => question.documentFormControl) ?? []));
    this.requiredDocumentFormArray = res?.formArray;
    this.requiredDocumentFormControls = res?.controls;

    let supRes = convertDocumentToFormControl([], (this.supplierQuestions?.filter(question => question.documentFormControl != null)?.map(question => question.documentFormControl) ?? []));
    this.supplierRequiredDocumentFormArray = supRes?.formArray;
    this.supplierRequiredDocumentFormControls = supRes?.controls;
  }

  refreshDocuments() {
    this.selectedDocumentsIds = this.documents?.map(item => item.documentType?.id);
    this.availableDocumentTypes = this.allUserDocumentTypes?.filter(doc => this.selectedDocumentsIds?.indexOf(doc.id) === -1) || [];
    this.ResetAll();
  }

  onInductionAnnouncementCheckedMandatorySignOff(inductionAnnouncementVisit: { announcementId: number, inductionId: number }, isSupplier: boolean) {
    this.onAnnouncementVisit(inductionAnnouncementVisit.announcementId, isSupplier, inductionAnnouncementVisit.inductionId);
  }
  onAnnouncementCheckedMandatorySignOff(announcementId: number, isSupplier: boolean, inductionId?: number) {
    if (!isSupplier) {
      let index = this.visitedAnnouncements.findIndex(s => s.announcementId == announcementId && s.inductionId == inductionId);
      if (index >= 0) {
        this.visitedAnnouncements[index].isCheckedMandatorySignOff = true;
      }
    } else {
      let index = this.supplierVisitedAnnouncements.findIndex(s => s.announcementId == announcementId && s.inductionId == inductionId);
      if (index >= 0) {
        this.supplierVisitedAnnouncements[index].isCheckedMandatorySignOff = true;
      }
    }
  }

  onInductionAnnouncementVisit(indectionAnnouncementVisit: { announcementId: number, inductionId: number }, isSupplier: boolean) {
    this.onAnnouncementVisit(indectionAnnouncementVisit.announcementId, isSupplier, indectionAnnouncementVisit.inductionId);
  }
  onAnnouncementVisit(announcementId: number, isSupplier: boolean, inductionId?: number) {
    let visitedAnnouncements = !isSupplier ? this.visitedAnnouncements : this.supplierVisitedAnnouncements;
    if (visitedAnnouncements.some(s => s.announcementId == announcementId && s.inductionId == inductionId) == false) {
      visitedAnnouncements.push({
        announcementId: announcementId,
        isCheckedMandatorySignOff: false,
        visitStatus: VisitStatus.Full,
        isSupplierAnnouncement: !isSupplier ? false : true,
        inductionId: inductionId,
      } as BaseAnnouncementVisit)
    }
  }

  isSetSupplierCheckinData() {
    return isSetSupplierCheckinData(this.supplierCheckinData);
  }
}
