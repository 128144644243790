import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { LocationOverrideMode } from 'src/app/enums/location-override-mode-enum';
import { PermitOpenPolicy } from 'src/app/enums/permit-open-policy.enum';
import { PermitStartDatePolicyEnum } from 'src/app/enums/permit-start-date-policy.enum';
import { ProductPlanFeature } from 'src/app/enums/product-plan-feature.enum';
import { e168ValidatorNullableCompanyPhoneNumber } from 'src/app/helpers/mobile-format-validator';
import { CompanyInductionsAndCheckInConfigurationViewModel, CompanyKioskModeConfigurationViewModel, CompanyPermitConfigurationViewModel, CompanyReportsConfigurationViewModel } from 'src/app/models/company-feature-configuration-viewmodel';
import { CompanySiteContactInformationModel } from 'src/app/models/company-site-contact-information.view.model';
import { CompanyService } from 'src/app/services/company.service';
import { ModalService } from 'src/app/services/modal.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'obc-company-Feature-Configuration',
  templateUrl: './company-Feature-Configuration.component.html',
  styleUrls: ['./company-Feature-Configuration.component.scss']
})
export class CompanyFeatureConfigurationComponent implements OnInit {

  permitFormGroup: FormGroup = new FormGroup({
    permitOpenPolicy: new FormControl([Validators.required]),
    permitStartDateOffsetInDays: new FormControl(),
  });

  InductionsAndCheckInFormGroup: FormGroup = new FormGroup({
    minRequiredExpiryForDocuments: new FormControl(),
    visitorCanDisputeCheckIns: new FormControl(),
    contactTitle: new FormControl(),
    contactFullName: new FormControl(),
    contactNumber: new FormControl(),
  });

  KioskModeFormGroup: FormGroup = new FormGroup({
    disableLocationRequirementForKioskMode: new FormControl(0),
  });

  ReportsFormGroup: FormGroup = new FormGroup({
    enableReportsOnMobileApp: new FormControl(),
  });

  ProductPlanFeature = ProductPlanFeature;
  PermitOpenPolicy = PermitOpenPolicy;
  PermitStartDatePolicyEnum = PermitStartDatePolicyEnum;

  constructor(private companyService: CompanyService,
    public userService: UserService,
    private modalService: ModalService) {
  }

  ngOnInit(): void {
    this.getCompanyPermitConfiguration();
    this.getCompanyInductionsAndCheckinConfiguration();
    this.getCompanyReportsConfiguration();
    this.getCompanyKioskModeConfiguration();
  }

  getCompanyPermitConfiguration() {
    this.companyService.getCompanyPermitConfiguration().subscribe(res => {
      this.permitFormGroup.get('permitOpenPolicy')?.setValue(res.permitOpenPolicy);
      this.permitFormGroup.get('permitStartDateOffsetInDays')?.setValue(res.permitStartDateOffsetInDays);
    }, err => {
      this.modalService.error(err);
    });
  }

  onSaveCompanyPermitConfiguration() {
    let model = {
      permitOpenPolicy: this.permitFormGroup.get("permitOpenPolicy").value,
      permitStartDateOffsetInDays: this.permitFormGroup.get("permitStartDateOffsetInDays").value,
    } as CompanyPermitConfigurationViewModel;

    this.companyService.updateCompanyPermitConfiguration(model).subscribe(_ => {
      this.modalService.success("Company Permit Configuration updated successfully");
    }, err => {
      this.modalService.error("Error in update company permit configuration");
    });
  }

  getCompanyInductionsAndCheckinConfiguration() {
    this.companyService.getCompanyInductionsAndCheckInConfiguration().subscribe(res => {
      let contactInformation: CompanySiteContactInformationModel;
      if (res.contactInformationAsJson) {
        try {
          contactInformation = JSON.parse(res.contactInformationAsJson) as CompanySiteContactInformationModel;
        } catch (e) { }
      }

      this.InductionsAndCheckInFormGroup = new FormGroup({
        minRequiredExpiryForDocuments: new FormControl(res.minRequiredExpiryForDocuments),
        visitorCanDisputeCheckIns: new FormControl(res.visitorCanDisputeCheckIns),
        contactTitle: new FormControl(contactInformation?.contactTitle),
        contactFullName: new FormControl(contactInformation?.contactFullName),
        contactNumber: new FormControl(contactInformation?.contactNumber, [e168ValidatorNullableCompanyPhoneNumber]),
      });
    }, err => {
      this.modalService.error(err);
    });
  }

  onSaveInductionsAndCheckInConfiguration() {
    let companyContactInformationAsJson;
    if (this.InductionsAndCheckInFormGroup.get("contactTitle").value ||
      this.InductionsAndCheckInFormGroup.get("contactFullName").value ||
      this.InductionsAndCheckInFormGroup.get("contactNumber").value
    ) {
      companyContactInformationAsJson = JSON.stringify({
        contactTitle: this.InductionsAndCheckInFormGroup.get("contactTitle").value,
        contactFullName: this.InductionsAndCheckInFormGroup.get("contactFullName").value,
        contactNumber: this.InductionsAndCheckInFormGroup.get("contactNumber").value,
      } as CompanySiteContactInformationModel)
    }

    let model = {
      minRequiredExpiryForDocuments: this.InductionsAndCheckInFormGroup.get("minRequiredExpiryForDocuments").value,
      visitorCanDisputeCheckIns: this.InductionsAndCheckInFormGroup.get("visitorCanDisputeCheckIns").value as boolean,
      contactInformationAsJson: companyContactInformationAsJson,
    } as CompanyInductionsAndCheckInConfigurationViewModel;

    this.companyService.updateCompanyInductionsAndCheckInConfiguration(model).subscribe(_ => {
      this.modalService.success("Company Inductions And Checkin Configuration updated successfully");
    }, err => {
      this.modalService.error("Error in update company inductions and checkin configuration");
    });
  }

  getCompanyReportsConfiguration() {
    this.companyService.getCompanyReportsConfiguration().subscribe(res => {
      this.ReportsFormGroup.get('enableReportsOnMobileApp')?.setValue(res.enableReportsOnMobileApp);
    }, err => {
      this.modalService.error(err);
    });
  }

  onSaveReportsConfiguration() {
    let model = {
      enableReportsOnMobileApp: this.ReportsFormGroup.get("enableReportsOnMobileApp").value as boolean,
    } as CompanyReportsConfigurationViewModel;

    this.companyService.updateCompanyReportsConfiguration(model).subscribe(_ => {
      this.modalService.success("Company Reports Configuration updated successfully");
    }, err => {
      this.modalService.error("Error in update company reports configuration");
    });
  }

  getCompanyKioskModeConfiguration() {
    this.companyService.getCompanyKioskModeConfiguration().subscribe(res => {
      this.KioskModeFormGroup.get('disableLocationRequirementForKioskMode')?.setValue(res.disableLocationRequirementForKioskMode);
    }, err => {
      this.modalService.error(err);
    });
  }

  onSaveKioskModeConfiguration() {
    let saveValue = LocationOverrideMode.Disabled;
    let formValue = this.KioskModeFormGroup.get('disableLocationRequirementForKioskMode')?.value;

    saveValue = (formValue == null || formValue == false || formValue == 0) ? LocationOverrideMode.Disabled :
      ((formValue == true || formValue == 1) ? LocationOverrideMode.OverrideNonDisableModesToOptional :
        LocationOverrideMode.Disabled);

    let model = {
      disableLocationRequirementForKioskMode: saveValue ?? LocationOverrideMode.Disabled,
    } as CompanyKioskModeConfigurationViewModel;

    this.companyService.updateCompanyKioskModeConfiguration(model).subscribe(_ => {
      this.modalService.success("Company Kiosk Mode Configuration updated successfully");
    }, err => {
      this.modalService.error("Error in update company kiosk mode configuration");
    });
  }
}
