import { UserBusinessViewModel } from './user-business-viewmodel';
import { AnswerMode } from '../enums/answer-mode.enum';
import { OptInQuestionMode } from '../enums/opt-in-question-mode.enum';
import { EmergencyContactViewModel } from './emergency-contact-view-model';
import { TwoFactorVerificationMode } from '../enums/two-factor-verification-mode';

export class PrimitiveUserInformation {
  mobile: string;
  firstName: string;
  lastName: string;
}

export interface SendWelcomeEmailUserProfile extends PrimitiveUserInformation {
  companyId: number;
  email: string;
}

export interface BaseUserInformation extends PrimitiveUserInformation {
  email: string;
  birthDay?: Date;
  gender?: number;
  isEmailVerified: boolean;
  industryTypeText: string;
  companySupplier?: string;
  businessAbn?: string;
  businessAcn?: string;
  businessName?: string;
  twoFactorVerificationMode?: TwoFactorVerificationMode;
  hasTotpSecretKey?:boolean;
}
export interface UserProfileViewmodel extends BaseUserInformation {
  lastLoginDate?: Date;
  userBarcodeContent: string;
  industryTypeId?: number;
  industryTypeText: string;
  profileImage: string;
  userBusinesses: UserBusinessViewModel[];
  emergencyContactAsJson: string;
  allowComminiucateWithUser: boolean;
  isActive2fa?: boolean;
}
export interface UserContactInformation extends BaseUserInformation {
  checkInAnswerMode: AnswerMode;
  checkOutAnswerMode?: AnswerMode;
  completeCheckInAnswerDate?: Date;
  completeCheckOutAnswerDate?: Date;
  isDisputedCheckOutDate: boolean;
  isDisputedCheckInDate: boolean;
  isDisputable: boolean;
  optInQuestionMode: OptInQuestionMode;
  optInQuestionMessage: string;
  siteName: string;
  siteId: number;
  allowComminiucateWithUser?: boolean;
  companyId: number;
  checkInDate: Date;
  checkOutDate: Date;
  visitorTypeTitle: string;
  checkInDistanceTitle: string;
  checkOutDistanceTitle: string;
}


export interface RequestUserProfileUpdateViewmodel {
  mobile: string;
  firstName: string;
  lastName: string;
  email: string;
  birthDay?: any;//Actually it is datetime but to be able to remove timezone we send it as string to server
  gender?: number;
  profileImageBase64: string;
  userBusinesses: UserBusinessViewModel[];
  fileType: string;
  emergencyContactAsJson: string;
  twoFactorVerificationMode: TwoFactorVerificationMode;
}
