import { OptInQuestionMode } from './../../enums/opt-in-question-mode.enum';
import { MaxPeopleOnSiteMode } from 'src/app/enums/max-people-on-site-mode.enum';
import { Component, ElementRef, EventEmitter, HostListener, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { AccompanierMode } from "../../enums/accompanier-mode";
import { AutomaticCheckInMode } from "../../enums/automatic-checkin-mode";
import { ToolTipMode, ToolTipPlace, ToolTipTriggers } from "../../enums/tooltip-enums";
import { AutoCheckOutType } from "../../enums/auto-check-out-type.enum";
import { MultipleCheckinType } from "../../enums/multiple-checkin-type.enum";
import { AdminSiteViewModel, SiteViewModel } from "../../models/site-viewmodel";
import { FormArray, FormControl, FormGroup, NgForm, Validators } from "@angular/forms";
import { InductionMode } from "../../enums/site-induction-mode.enum";
import { Constants } from "../../enums/constants";
import { AsyncResult } from "../../models/helper-models";
import { SiteService } from "../../services/site.service";
import { CountryService } from "../../services/country.service";
import { UserService } from "../../services/user.service";
import { ModalService } from "../../services/modal.service";
import { LayoutService } from "../../services/layout.service";
import { AddressLookupService } from "../../services/address-lookup.service";
import { AddressLookupSuggestion } from "../../models/gmap/address-lookup-suggestion";
import { TypeaheadMatch, TypeaheadOrder } from "ngx-bootstrap/typeahead/public_api";
import { noop, Observable, Observer, of, Subscription } from "rxjs";
import { Timezone } from "../../models/time-zone";
import { CompanyViewModel } from "../../models/company-viewmodel";
import { uuid4 } from "../../helpers/uuid";
import { ProductPlanFeature } from "../../enums/product-plan-feature.enum";
import { deepClone, isEqual, maxNumber } from "../../helpers/general-functions";
import { finalize, map, switchMap, tap } from "rxjs/operators";
import { AddressLookupGetSuggestionModel } from "../../models/gmap/address-lookup-get-suggestion-model";
import { CheckInApprovalMode } from 'src/app/enums/check-in-approval-mode.enum';
import { SeatingModeLabels } from 'src/app/enums/seating-mode.enum';
import { getCheckinApprovalModeFriendlyMode } from 'src/app/helpers/friendly-label';
import { ActivatedRoute, Params, Router } from "@angular/router";
import { ToastrService } from 'ngx-toastr';
import { ManageSiteModel, ManageSiteViewModel } from "../../models/manage-site-view-model";
import { ManageSiteService } from "../../services/manage-site.service";
import { SiteQrCodeViewModel } from "../../models/site-qrcode-viewmodel";

import { DirtyComponents } from "../../models/dirty-components";
import { DelayedAnswerMode } from 'src/app/enums/delayed-answer-mode';
import { QrCodeCheckinMode as QrCodeCheckinMode } from 'src/app/enums/qr-code-checkin-mode';
import { CheckinLocationMode } from 'src/app/enums/checkin-location-mode.enum';
import {
  AccessControlAutomaticAttendanceMode,
  AccessControlInductionMode,
  AccessControlSiteBriefingMode,
  AccessControlSupplierDocumentMode,
  AccessControlUnitDirection
} from 'src/app/enums/access-control-automatic-attendance-mode';
import * as moment from 'moment';
import { timeFormat } from 'src/app/helpers/date-time-format';
import { SiteManagementMode } from "../../enums/site-management-mode";
import { ManageSupplierSiteService } from "../../services/manage-supplier-site.service";
import { ManageSiteSupplierModel } from "../../models/site-supplier-management/manage-site-supplier-view-model";
import { LocationOverrideMode } from 'src/app/enums/location-override-mode-enum';
import { AccessControlUnitProvider } from 'src/app/enums/access-control-unit-provider';
import { AccessControlAutomaticAttendanceModePipe } from 'src/app/pipes/access-control-automatic-attendance-mode.pipe';
import { AccessControlUnitProviderPipe } from 'src/app/pipes/access-control-unit-provider.pipe';
import { BiostarService } from 'src/app/services/biostar.service';
import { BioStarDevice } from 'src/app/models/biostar/view-models';
import { CompanySiteContactInformationModel } from "../../models/company-site-contact-information.view.model";
import { StorageService } from 'src/app/services/storage.service';
import { LocalStorageKey } from 'src/app/enums/local-storage-key.enum';

@Component({
  selector: 'obc-site-management',
  templateUrl: './site-management.component.html',
  styleUrls: ['./site-management.component.scss']
})
export class SiteManagementComponent implements OnInit {
  siteId: number;
  siteSupplierId: number;
  siteManagementMode: SiteManagementMode = SiteManagementMode.Site;
  SiteManagementModeEnum = SiteManagementMode;
  AccessControlUnitProvider = AccessControlUnitProvider;
  accessControlAutomaticAttendanceModeEnum = AccessControlAutomaticAttendanceMode;

  submitButtonFixed: boolean = false;
  showInfo: boolean = true;

  @HostListener('window:scroll') // for window scroll events
  onScroll() {
    const verticalOffset = window.pageYOffset
      || document.documentElement.scrollTop
      || document.body.scrollTop || 0;

    this.submitButtonFixed = verticalOffset > 103;
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler(event) {
    if (this.isAnyAccordionDirty()) {
      event.preventDefault();
      event.returnValue = '';
      return false;
    } else
      window.close()
  }

  canDeactivate(): Observable<Subscription> | Observable<boolean> | Promise<boolean> | boolean {
    if (this.isAnyAccordionDirty()) {
      return this.modalService.confirm("There are unsaved changes. Are you sure you want to leave?", "Unsaved Changes");
    }
    return true;
  }

  manageSiteModel: ManageSiteViewModel;
  DelayedAnswerMode = DelayedAnswerMode;
  CheckInApprovalMode = CheckInApprovalMode;
  currentCompanyId: number;
  currentUserIsGod: boolean = false;
  inProgress: boolean = false;
  accompanierMode = AccompanierMode;
  automaticCheckinMode = AutomaticCheckInMode;
  place = ToolTipPlace;
  trigger = ToolTipTriggers;
  mode = ToolTipMode;
  errorMessage: string = '';
  checkInDelayInMinutes = [0, 1, 2, 3, 4, 5];
  showInvalidSiteRadiusMessage: boolean = false;
  autoCheckOutTypes = AutoCheckOutType;
  multipleCheckinTypes = MultipleCheckinType;
  maxPeopleOnSiteMode = MaxPeopleOnSiteMode;
  SeatingModeLabels = SeatingModeLabels;
  siteLogoFormControl = new FormControl(null);
  optInQuestionMode = OptInQuestionMode;
  InductionMode = InductionMode;
  maxAllowedGeofencingThresholdInMinutes: number;
  enablePreCheckInFeature: boolean = false;
  enableAccessControlFeature: boolean = false;
  public constants = Constants;
  QrCodeCheckinMode = QrCodeCheckinMode;
  CheckinLocationMode = CheckinLocationMode;
  AccessControlInductionMode = AccessControlInductionMode;
  AccessControlSiteBriefingMode = AccessControlSiteBriefingMode;
  AccessControlSupplierDocumentMode = AccessControlSupplierDocumentMode;
  AccessControlUnitDirection = AccessControlUnitDirection;
  // minimumPreCheckInCodeLength = 6;
  isForcedCheckOutAfterMinute = new FormControl(false);
  dirtyComponents: DirtyComponents = new DirtyComponents();

  clearDirtyComponents() {
    Object.keys(this.dirtyComponents).forEach((key) => {
      this.dirtyComponents[key].snapshot = null;
      this.dirtyComponents[key].isDirty = false;
    });
    this.siteManagementFG.markAsPristine();
    this.siteManagementFG.get('advancedSettings.logoBase64')?.setValue(null);
    this.siteManagementFG.get('advancedSettings.logoExtension')?.setValue(null);
    this._clone_fg_ = this.cloneFormGroup(this.siteManagementFG);
  }

  _clone_fg_;
  siteManagementFG = new FormGroup({
    projectSettings: new FormGroup({
      isTemplate: new FormControl(false),
      name: new FormControl('', Validators.required),
      description: new FormControl(null),
      siteReference: new FormControl(null),
      isPublicSite: new FormControl(false),
      latitude: new FormControl(null, Validators.required),
      longitude: new FormControl(null, Validators.required),
      address: new FormControl(null, Validators.required),
      maxAllowedSiteRadius: new FormControl(SiteViewModel.MAX_ALLOWED_SITE_RADIUS_DEFAULT),
      siteRadius: new FormControl(50, Validators.required),
      isForcedCheckOutTime: new FormControl(true),
      isForcedCheckOutAfterMinute: new FormControl(false),
      forceCheckOutAfterMinute: new FormControl(null, [Validators.required, Validators.min(1)]),
      checkinLocationMode: new FormControl(CheckinLocationMode.Optional),
      checkInLocationOverrideInKioskMode: new FormControl(0),
      forceCheckOutTime: new FormControl("18:00"),
      checkInApprovalMode: new FormControl(CheckInApprovalMode.Disable),
      // contactUserId: new FormControl(''),
      siteSupervisorEmailAddress: new FormControl(''),
      allowUsersToAttachAdditionalDocuments: new FormControl(false),
    }),
    contactInformation: new FormGroup({
      contactInformationAsJson: new FormControl(null),
    }),
    advancedSettings: new FormGroup({
      seatingLocationFillMode: new FormControl(''),
      regionId: new FormControl(null),
      logoUrl: new FormControl(null),
      multipleCheckinType: new FormControl(MultipleCheckinType.Disable),
      enableNotifyNearUser: new FormControl(false),
      seatingModeLabels: new FormControl(''),
      optInQuestionMode: new FormControl(OptInQuestionMode.Disable),
      optInQuestionMessage: new FormControl(''),
      maxPeopleOnSiteMode: new FormControl(MaxPeopleOnSiteMode.Disable),
      maxPeopleOnSite: new FormControl(''),
      timezoneId: new FormControl(''),
      accompanierMode: new FormControl(AccompanierMode.Disable),
      logoExtension: new FormControl(null),
      logoBase64: new FormControl(null),
      integrationConfigAsJson: new FormControl(''),
    }),
    automatedAttendance: new FormGroup({
      autoCheckInType: new FormControl(AutomaticCheckInMode.Disable),
      autoCheckInDelayInMinute: new FormControl(0),
      autoCheckOutType: new FormControl(AutoCheckOutType.Optional),
      geofencingThresholdInMinutes: new FormControl(25),
      delayedAnswerModeForCheckIn: new FormControl(DelayedAnswerMode.Disable),
      delayedAnswerModeForCheckOut: new FormControl(DelayedAnswerMode.Disable),
      delayedAnswerNotificationAfterCheckOutNotifyCount: new FormControl(1),
      delayedAnswerNotificationFrequencyInMinute: new FormControl(''),
      delayedAnswerForCheckInThresholdInHour: new FormControl(null),
      delayedAnswerForCheckOutThresholdInHour: new FormControl(null),
    }),
    cameraQR: new FormGroup({
      qrCodeCheckinMode: new FormControl(QrCodeCheckinMode.AnonymousFlutterWeb),
      forceCheckOutAnonymousAfterInMinute: new FormControl(30),
    }),
    offsite: new FormGroup({
      enablePreCheckIn: new FormControl(false),
      enableOffsiteInductions: new FormControl(true),
      enableOffsiteQR: new FormControl(true),
      enablePreCheckInForFrequentUsers: new FormControl(false),
      enablePreCheckInByCode: new FormControl(false),
      preCheckInCode: new FormControl(''),
      preCheckInPassword: new FormControl(''),
    }),
    accessControl: new FormGroup({
      accessControlUnitProvider: new FormControl(AccessControlUnitProvider.Disable),
      enableAccessControlUnitReport: new FormControl(false),

      isBioStarEnabled: new FormControl(false),
      bioStarApiServerUrl: new FormControl(''),
      bioStarApiUsername: new FormControl(''),
      bioStarApiPassword: new FormControl(''),
      enableBioStarAccessGroup: new FormControl(''),
      bioStarAccessGroup: new FormControl(''),
      bioStarUserGroup: new FormControl(''),

      accessControlAutomaticCheckInMode: new FormControl(AccessControlAutomaticAttendanceMode.Disabled),
      accessControlAutomaticCheckOutMode: new FormControl(AccessControlAutomaticAttendanceMode.Disabled),
      accessControlInductionCheckingMode: new FormControl(AccessControlInductionMode.ApprovedInductions),
      accessControlSiteBriefingCheckingMode: new FormControl(AccessControlSiteBriefingMode.NoRestriction),
      accessControlSupplierDocumentCheckingMode: new FormControl(AccessControlSupplierDocumentMode.NoRestriction),
      siteAccessControlUnits: new FormArray([]),
      newAccessControlUnit: new FormGroup({
        id: new FormControl(''),
        controlUnitIdentifier: new FormControl(''),
        displayName: new FormControl(''),
        entryBarcodeScannerIdentifier: new FormControl(''),
        exitBarcodeScannerIdentifier: new FormControl(''),
        entryGateIdentifier: new FormControl(''),
        exitGateIdentifier: new FormControl(''),
        limitEntryGateRepeatedScansInSeconds: new FormControl(0),
        limitExitGateRepeatedScansInSeconds: new FormControl(0),
        activeHoursFrom: new FormControl(''),
        activeHoursTo: new FormControl(''),
        evacuationIdentifier: new FormControl(''),
        direction: new FormControl(AccessControlUnitDirection.InOut),
      }),
    }),
  });


  get accessControlUnits(): FormArray {
    return ((this.siteManagementFG.get('accessControl') as FormGroup).get('siteAccessControlUnits') as FormArray);
  }

  onNewControlUnit() {
    var formGroup = (this.siteManagementFG.controls.accessControl as FormGroup).controls.newAccessControlUnit as FormGroup;
    if (!formGroup.controls.displayName.value) { this.modalService.error('Display Name is required'); return; }

    this.accessControlUnits.push(new FormGroup({
      id: new FormControl(formGroup.controls.id.value),
      controlUnitIdentifier: new FormControl(formGroup.controls.controlUnitIdentifier.value),
      displayName: new FormControl(formGroup.controls.displayName.value),
      entryBarcodeScannerIdentifier: new FormControl(formGroup.controls.entryBarcodeScannerIdentifier.value),
      exitBarcodeScannerIdentifier: new FormControl(formGroup.controls.exitBarcodeScannerIdentifier.value),
      entryGateIdentifier: new FormControl(formGroup.controls.entryGateIdentifier.value),
      exitGateIdentifier: new FormControl(formGroup.controls.exitGateIdentifier.value),
      limitEntryGateRepeatedScansInSeconds: new FormControl(formGroup.controls.limitEntryGateRepeatedScansInSeconds.value),
      limitExitGateRepeatedScansInSeconds: new FormControl(formGroup.controls.limitExitGateRepeatedScansInSeconds.value),
      activeHoursFrom: new FormControl(formGroup.controls.activeHoursFrom.value),
      activeHoursTo: new FormControl(formGroup.controls.activeHoursTo.value),
      evacuationIdentifier: new FormControl(formGroup.controls.evacuationIdentifier.value),
      direction: new FormControl(formGroup.controls.direction.value),
    }));
    formGroup.reset({
      id: '',
      controlUnitIdentifier: '',
      displayName: '',
      entryBarcodeScannerIdentifier: '',
      exitBarcodeScannerIdentifier: '',
      entryGateIdentifier: '',
      exitGateIdentifier: '',
      activeHoursFrom: '',
      activeHoursTo: '',
      evacuationIdentifier: '',
      limitEntryGateRepeatedScansInSeconds: 0,
      limitExitGateRepeatedScansInSeconds: 0,
      direction: AccessControlUnitDirection.InOut,
    });

  }

  onRemoveAccessControlUnit(index: number) {
    this.accessControlUnits.removeAt(index);
  }

  setSubscriptionsOnDelayedAnswerModeFields() {
    this.siteManagementFG.get('automatedAttendance.delayedAnswerModeForCheckIn').valueChanges.subscribe(_ => this.onDelayedAnswerModeFields());
    this.siteManagementFG.get('automatedAttendance.delayedAnswerModeForCheckOut').valueChanges.subscribe(_ => this.onDelayedAnswerModeFields());
    this.siteManagementFG.get('automatedAttendance.delayedAnswerNotificationFrequencyInMinute').valueChanges.subscribe(_ => this.onDelayedAnswerModeFields());
  }

  setAutomatedAttendanceCheckoutThresholdSubscription() {
    if (this.siteManagementMode == SiteManagementMode.Site) {
      this.siteManagementFG.get('automatedAttendance.autoCheckOutType').valueChanges.subscribe(_ => {
        this.__updateAutomatedAttendanceCheckoutThresholdValidator();
      });
      this.siteManagementFG.get('projectSettings.checkinLocationMode').valueChanges.subscribe(_ => {
        this.__updateAutomatedAttendanceCheckoutThresholdValidator();
      });
      this.__updateAutomatedAttendanceCheckoutThresholdValidator();
    }
  }
  __updateAutomatedAttendanceCheckoutThresholdValidator() {
    if (this._checkIfGeofencingThresholdInMinutesIsRequired()) {
      this.siteManagementFG.get('automatedAttendance.geofencingThresholdInMinutes').setValidators(Validators.required);
    } else {
      this.siteManagementFG.get('automatedAttendance.geofencingThresholdInMinutes').clearValidators();
    }
    this.siteManagementFG.get('automatedAttendance.geofencingThresholdInMinutes').updateValueAndValidity();
  }

  onDelayedAnswerModeFields() {

    let automatedAttendance = (this.siteManagementFG.get('automatedAttendance') as FormGroup);

    let checkOutMode = automatedAttendance.controls['delayedAnswerModeForCheckOut'].value;

    if (checkOutMode != DelayedAnswerMode.Disable && automatedAttendance.controls['delayedAnswerNotificationFrequencyInMinute']?.value > 0)
      automatedAttendance.controls['delayedAnswerNotificationAfterCheckOutNotifyCount'].setValidators([Validators.required]);
    else
      automatedAttendance.controls['delayedAnswerNotificationAfterCheckOutNotifyCount'].clearValidators();

    automatedAttendance.controls['delayedAnswerNotificationAfterCheckOutNotifyCount'].updateValueAndValidity();

  }

  maxPeopleOnSiteModeFn(value) {
    value = value.value ? value.value : value;
    if (+value != MaxPeopleOnSiteMode.Disable) {
      this.siteManagementFG.get('advancedSettings.maxPeopleOnSite')
        .setValidators([Validators.required]);
    } else {
      this.siteManagementFG.get('advancedSettings.maxPeopleOnSite')
        .clearValidators();
    }
    this.siteManagementFG.get('advancedSettings.maxPeopleOnSite').updateValueAndValidity();
  }

  optInQuestionModeFn(value) {
    value = value.value ? value.value : value;
    if (+value == OptInQuestionMode.CustomWithMessage) {
      this.siteManagementFG.get('advancedSettings.optInQuestionMessage')
        .setValidators([Validators.required]);
    } else {
      this.siteManagementFG.get('advancedSettings.optInQuestionMessage')
        .clearValidators();
    }
    this.siteManagementFG.get('advancedSettings.optInQuestionMessage').updateValueAndValidity();
  }

  qrCodeCheckinModeFn(value) {
    if (value != QrCodeCheckinMode.Disable) {
      this.siteManagementFG.get('cameraQR.forceCheckOutAnonymousAfterInMinute')
        .setValidators([Validators.required]);
    } else {
      this.siteManagementFG.get('cameraQR.forceCheckOutAnonymousAfterInMinute')
        .clearValidators();
    }
    this.siteManagementFG.get('cameraQR.forceCheckOutAnonymousAfterInMinute').updateValueAndValidity();
  }

  checkinLocationModeFn(value) {
    this.siteManagementFG.get('projectSettings.checkinLocationMode')?.setValue(value);
    if (value == CheckinLocationMode.Disable) {
      this.setSubscriptionsOnDelayedAnswerModeFields();
    }
  }


  findInvalidControlsCount(formGroupName): number {
    let invalid = 0;
    let formGroup = this.siteManagementFG.get(formGroupName);
    const controls = formGroup['controls'];
    Object.keys(controls)?.forEach((key) => {
      if (controls[key]?.invalid && controls[key]?.touched) {
        invalid++;
      }
    });
    return invalid;
  }

  isAnyAccordionDirty() {
    return Object.keys(this.siteManagementFG['controls'])
      .some((key) => this.checkIfFormGroupIsDirty(key)) ||
      Object.values(this.dirtyComponents)
        .some((value) => value.isDirty);
  }

  areAllAccordionsValid() {
    if (this.siteManagementMode == SiteManagementMode.Supplier) return true;

    let allStates: boolean[] = [];

    Object.keys(this.siteManagementFG['controls']).forEach((key) => {
      allStates.push(this.siteManagementFG['controls'][key].valid)
    });
    return allStates.every(item => item);
  }

  mapItemsToAdminSiteViewModel() {
    let site: AdminSiteViewModel = deepClone(this.manageSiteModel?.site) ?? new AdminSiteViewModel();
    Object.keys(this.siteManagementFG['controls']).forEach((key) => {
      Object.entries(this.siteManagementFG['controls'][key]['controls']).forEach(([fcKey, fcValue]) => {
        site[fcKey] = fcValue['value'];
      });
    });

    if (site.siteAccessControlUnits?.length) {
      for (let unit of site.siteAccessControlUnits) {
        if (unit.activeHoursFrom)
          unit.activeHoursFrom = this.dateToTime(unit.activeHoursFrom);
        if (unit.activeHoursTo)
          unit.activeHoursTo = this.dateToTime(unit.activeHoursTo);
      }
    }
    return site;
  }

  dateToTime(date: Date) {
    return moment(date, timeFormat).format('HH:mm').toString();
  }

  mapSiteInfoToFormGroup() {

    if (this.manageSiteModel?.site) {
      Object.entries(this.manageSiteModel?.site).forEach(([key, value]) => {
        let formGroup = 'projectSettings';
        if (this.siteManagementFG['controls'].automatedAttendance['controls'][key])
          formGroup = 'automatedAttendance';
        else if (this.siteManagementFG['controls'].advancedSettings['controls'][key])
          formGroup = 'advancedSettings';
        else if (this.siteManagementFG['controls'].cameraQR['controls'][key])
          formGroup = 'cameraQR';
        else if (this.siteManagementFG['controls'].offsite['controls'][key])
          formGroup = 'offsite';
        else if (this.siteManagementFG['controls'].accessControl['controls'][key])
          formGroup = 'accessControl';
        else if (this.siteManagementFG['controls'].contactInformation['controls'][key])
          formGroup = 'contactInformation';
        try {
          if (formGroup == 'accessControl' && key == 'siteAccessControlUnits') {
            var unitsFormArray = this.siteManagementFG.get(`${formGroup}.${key}`) as FormArray;
            unitsFormArray?.clear();
            if (value?.length) {
              value.map(unit => {
                var fg = new FormGroup({});
                Object.entries(unit).forEach(([unitPropName, unitPropVal]) => {
                  fg.addControl(unitPropName, new FormControl(unitPropVal));
                });
                unitsFormArray?.push(fg);
              });
            }
          } else
            this.siteManagementFG.get(`${formGroup}.${key}`)?.setValue(value ?? '');
        } catch {
          console.log(`Error in set value of form: ${formGroup} : ${key} : ${value}`);
        }
      });
      let checkInLocationOverrideInKioskMode = this.manageSiteModel.site.checkInLocationOverrideInKioskMode;
      this.siteManagementFG.get('projectSettings.checkInLocationOverrideInKioskMode')?.setValue(checkInLocationOverrideInKioskMode);
    }

    if (typeof this.siteManagementFG.get('accessControl.bioStarApiServerUrl')?.value == 'undefined' ||
      this.siteManagementFG.get('accessControl.bioStarApiServerUrl')?.value == '' ||
      this.siteManagementFG.get('accessControl.bioStarApiServerUrl')?.value == null) {
      this.siteManagementFG.get('accessControl.isBioStarEnabled')?.setValue(false);
    }
    else {
      this.siteManagementFG.get('accessControl.isBioStarEnabled')?.setValue(true);
    }

    if (typeof this.siteManagementFG.get('automatedAttendance.delayedAnswerForCheckOutThresholdInHour')?.value == 'undefined' ||
      this.siteManagementFG.get('automatedAttendance.delayedAnswerForCheckOutThresholdInHour')?.value == '') {
      this.siteManagementFG.get('automatedAttendance.delayedAnswerForCheckOutThresholdInHour')?.setValue(null);
    }
    if (typeof this.siteManagementFG.get('automatedAttendance.delayedAnswerForCheckInThresholdInHour')?.value == 'undefined' ||
      this.siteManagementFG.get('automatedAttendance.delayedAnswerForCheckInThresholdInHour')?.value == '') {
      this.siteManagementFG.get('automatedAttendance.delayedAnswerForCheckInThresholdInHour')?.setValue(null);
    }
    if (typeof this.siteManagementFG.get('advancedSettings.regionId')?.value == 'undefined' ||
      this.siteManagementFG.get('advancedSettings.regionId')?.value == '') {
      this.siteManagementFG.get('advancedSettings.regionId')?.setValue(null);
    }
    if (typeof this.siteManagementFG.get('cameraQR.forceCheckOutAnonymousAfterInMinute')?.value == 'undefined' ||
      this.siteManagementFG.get('cameraQR.forceCheckOutAnonymousAfterInMinute')?.value == null) {
      this.siteManagementFG.get('cameraQR.forceCheckOutAnonymousAfterInMinute')?.setValue(30);
    }

    let forceCheckoutAfterTimePeriod = this.siteManagementFG.get('projectSettings.forceCheckOutAfterMinute')?.value;
    if (typeof forceCheckoutAfterTimePeriod != 'undefined' &&
      forceCheckoutAfterTimePeriod != null && parseInt(forceCheckoutAfterTimePeriod) > 0) {
      this.isForcedCheckOutAfterMinuteMethod(true);
    } else {
      this.isForcedCheckOutAfterMinuteMethod(false);
    }
    this._clearValidatorsIfIsTemplate();
    this.siteManagementFG.markAsPristine()

  }


  isNewSiteRequest() {
    return this.router.url == '/manage-site/new' || this.router.url == '/manage-site/new-template';
  }

  _clearValidatorsIfIsTemplate() {
    if (this.siteManagementFG.get('projectSettings.isTemplate')?.value ?? false) {
      this.siteManagementFG.get('projectSettings.latitude')?.clearValidators();
      this.siteManagementFG.get('projectSettings.longitude')?.clearValidators();
      this.siteManagementFG.get('projectSettings.address')?.clearValidators();
      this.siteManagementFG.get('projectSettings.latitude')?.updateValueAndValidity()
      this.siteManagementFG.get('projectSettings.longitude')?.updateValueAndValidity()
      this.siteManagementFG.get('projectSettings.address')?.updateValueAndValidity()
    }
  }


  getSiteInfo() {
    if (!this.isNewSiteRequest()) {
      this.inProgress = true;
      this.route.params.subscribe((param: Params) => {
        this.siteId = +param['id'];
        this.siteSupplierId = +param['siteSupplierId'];
        if (this.siteSupplierId) {
          this.siteManagementMode = SiteManagementMode.Supplier;
        }
        (this.siteManagementMode == SiteManagementMode.Site ?
          this.manageSiteService.getSiteFullData(this.siteId) :
          this.manageSupplierSiteService.getSiteSupplierFullData(this.siteSupplierId))
          .pipe(finalize(() => {
            if (this.manageSiteModel?.site?.isActive === false) {
              this.router.navigate([`sites`], { skipLocationChange: false });
            }
          }))
          .subscribe(res => {
            if (res) {
              this.manageSiteModel = res;
              this.mapSiteInfoToFormGroup();
              Object.keys(this.siteManagementFG['controls']).forEach((key) => {
                this.siteManagementFG['controls'][key].valueChanges.subscribe(_ => {
                  this.checkIfFormGroupIsDirty(key);
                });
              });

              this._clone_fg_ = this.cloneFormGroup(this.siteManagementFG);
              this.setSubscriptionsOnDelayedAnswerModeFields();
              this.onDelayedAnswerModeFields();
              this.setAutomatedAttendanceCheckoutThresholdSubscription();

              this.inProgress = false;
            }
          }, error => {
            this.modalService.error("There is no data to display");
          });
      });
    } else {
      this.siteManagementFG.get('projectSettings.isTemplate')?.setValue(this.router.url == '/manage-site/new-template');

      this.setAutomatedAttendanceCheckoutThresholdSubscription();
      this._clearValidatorsIfIsTemplate();
    }
  }


  onRegionSelect(value) {
    this.siteManagementFG.get('advancedSettings.regionId').setValue(value);
  }

  @ViewChild('siteForm', { static: false }) siteForm: NgForm;

  @Input() set site(_site: SiteViewModel) {

  }

  validateCoordinate(point: number) {
    return point && !isNaN(point);
  }

  @Output() public result = new EventEmitter<AsyncResult>();


  routerSubscription;

  constructor(
    private siteService: SiteService,
    private countryService: CountryService,
    public userService: UserService,
    private modalService: ModalService,
    private layoutService: LayoutService,
    private addressLookupService: AddressLookupService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private manageSiteService: ManageSiteService,
    private manageSupplierSiteService: ManageSupplierSiteService,
    private accessControlAutomaticAttendanceModePipe: AccessControlAutomaticAttendanceModePipe,
    private accessControlUnitProviderPipe: AccessControlUnitProviderPipe,
    private biostarService: BiostarService,
    private storageService: StorageService,
    private ngZone: NgZone,
  ) {
  }


  selectedAddress: AddressLookupSuggestion;
  sortConfig: TypeaheadOrder = {
    direction: 'asc',
    field: 'primaryTitle',
  };
  suggestions$: Observable<AddressLookupSuggestion[]>;
  uuid: string;
  timezones: Timezone[] = null;
  currentCompany: CompanyViewModel = null;


  updateContactInformation(value: boolean) {
    // we're adding a new required form control if the value of `contactNumber` inside json string is not valid.
    // and removing the new created form control when it's valid
    let contactInformationFG: FormGroup = this.siteManagementFG['controls'].contactInformation as FormGroup;
    if (value) {
      contactInformationFG.addControl('validator', new FormControl(null, Validators.required));
    } else {
      contactInformationFG.removeControl('validator');
    }
    this.siteManagementFG.get('contactInformation.contactInformationAsJson').updateValueAndValidity()
  }
  getContactInformationDetail() {
    let contactInformationString = this.siteManagementFG.get('contactInformation.contactInformationAsJson').value;
    if (contactInformationString) {
      try {
        let parsedContactInformation = JSON.parse(contactInformationString) as CompanySiteContactInformationModel;
        if (parsedContactInformation) {
          return parsedContactInformation.contactFullName;
        }
      } catch (_) { }
    }
    return '';
  }

  ngOnInit(): void {

    // this.initDirtyComponents();

    this.layoutService.header = 'Site Settings';

    this.getSiteInfo();
    this.uuid = uuid4();

    this.getAddressList();
    this.currentCompany = this.userService.currentCompany;
    this.userService.currentCompany$.subscribe(
      (res) => (this.currentCompany = res)
    );
    this.userService.info.subscribe((res) => {
      this.currentUserIsGod = res?.userPermissions?.isGodUser;
      this.currentCompanyId = res?.currentCompanyId;
      this.enablePreCheckInFeature = this.userService.hasCompanyFeature(ProductPlanFeature.PreCheckIn);
      this.enableAccessControlFeature = this.userService.hasCompanyFeature(ProductPlanFeature.AccessControl);
    });
    this.countryService
      .getTimeZones()
      .subscribe((res) => (this.timezones = res));

    this.siteLogoFormControl.valueChanges.subscribe(res => {
      if (res == null) {
        this.siteManagementFG.get('advancedSettings.logoBase64')?.setValue(null);
        this.siteManagementFG.get('advancedSettings.logoExtension')?.setValue(null);
      } else {
        this.siteManagementFG.get('advancedSettings.logoBase64')?.setValue(res.content.split(';base64,')[1]);
        this.siteManagementFG.get('advancedSettings.logoExtension')?.setValue(res.type);
      }
    })
    this.maxAllowedGeofencingThresholdInMinutes = maxNumber([SiteViewModel.MAX_ALLOWED_AUTOMATIC_CHECKOUT_THRESHOLD_MINUTES_DEFAULT,
    this.siteManagementFG.get('automatedAttendance.geofencingThresholdInMinutes')?.value ?? 0]);

    var closeInfoPanel = this.storageService.get(LocalStorageKey.SiteSettingsInfoClose);
    this.showInfo = closeInfoPanel == "1" ? false : true;
  }

  onRemoveLogo() {
    this.siteService.removeSiteLogo(this.manageSiteModel?.site?.id).subscribe(_ => {
      this.siteManagementFG.get('advancedSettings.logoBase64')?.setValue(null);
      this.siteManagementFG.get('advancedSettings.logoExtension')?.setValue(null);
      this.siteManagementFG.get('advancedSettings.logoUrl')?.setValue(null);
    })
  }

  loader: boolean = false;

  getAddressList() {
    this.suggestions$ = new Observable((observer: Observer<string>) => {
      observer.next(this.siteManagementFG.get('projectSettings.address')?.value);
    }).pipe(
      switchMap((query: string) => {
        if (query) {
          this.loader = true;
          let model: AddressLookupGetSuggestionModel = {
            address: query,
            sessionToken: this.uuid,
          };
          return this.addressLookupService.getAddressSuggestionList(model).pipe(
            map((data: AddressLookupSuggestion[]) => (data && data) || []),
            tap(
              () => noop,
              (_) => {
                // var rrr = (err && err.message) || 'Something goes wrong';
              }
            ),
            finalize(() => {
              this.loader = false;
            })
          );
        }

        return of([]);
      })
    );
  }

  _checkIfGeofencingThresholdInMinutesIsRequired() {
    return this.siteManagementFG.get('projectSettings.checkinLocationMode')?.value != undefined &&
      this.siteManagementFG.get('projectSettings.checkinLocationMode')?.value != CheckinLocationMode.Disable &&
      this.siteManagementFG.get('automatedAttendance.autoCheckOutType')?.value != undefined &&
      this.siteManagementFG.get('automatedAttendance.autoCheckOutType')?.value != AutoCheckOutType.Disable;
  }

  updateInsert() {
    if (this.siteManagementMode == SiteManagementMode.Site) {
      this.siteManagementFG.get('automatedAttendance.autoCheckInDelayInMinute')?.setValue(
        this.siteManagementFG.get('automatedAttendance.autoCheckInDelayInMinute')?.value ?? 0
      )
      if (this.isValid() === false) return;

      if (this._checkIfGeofencingThresholdInMinutesIsRequired() &&
        (typeof this.siteManagementFG.get('automatedAttendance.geofencingThresholdInMinutes')?.value == 'undefined' ||
          this.siteManagementFG.get('automatedAttendance.geofencingThresholdInMinutes')?.value == undefined)) {
        this.modalService.error("Automatic Check Out Threshold Is Needed", "");
        return;
      }
      if (this.siteManagementFG.get('cameraQR.qrCodeCheckinMode')?.value != QrCodeCheckinMode.Disable
        && (this.siteManagementFG.get('cameraQR.forceCheckOutAnonymousAfterInMinute')?.value == null
          || this.siteManagementFG.get('cameraQR.forceCheckOutAnonymousAfterInMinute')?.value <= 0)) {
        this.modalService.error(`Auto Check Out ${this.constants.ANONYMOUS_ATTENDANCE_TITLE} After must be greater than zero when ${this.constants.ANONYMOUS_ATTENDANCE_TITLE} Check-In By QR is enabled.`, "");
        return;
      }

      // if (this.siteManagementFG.get('projectSettings.siteSupervisorEmailAddress')?.value != null && this.siteManagementFG.get('projectSettings.siteSupervisorEmailAddress')?.value.trim().length && !validateEmail(this.siteManagementFG.get('projectSettings.siteSupervisorEmailAddress')?.value)) {
      //   this.modalService.error("Supervisor email is invalid.", "");
      //   return;
      // }

      if (this.siteManagementFG.get('projectSettings.checkinLocationMode')?.value == CheckinLocationMode.Disable) {
        this.siteManagementFG.get('automatedAttendance.autoCheckOutType')?.setValue(this.autoCheckOutTypes.Optional);
      }

      if (!this.siteManagementFG.get('projectSettings.isForcedCheckOutTime')?.value) {
        this.siteManagementFG.get('projectSettings.forceCheckOutTime')?.setValue(null);
      }

      if (!this.isForcedCheckOutAfterMinute?.value) {
        this.siteManagementFG.get('projectSettings.forceCheckOutAfterMinute')?.setValue(null);
      }

      if (this.siteManagementFG.get('accessControl.accessControlUnitProvider')?.value != AccessControlUnitProvider.BioStar) {
        this.siteManagementFG.get('accessControl.isBioStarEnabled')?.setValue(false);
        this.siteManagementFG.get('accessControl.enableBioStarAccessGroup')?.setValue(false);
      }

      if (this.siteManagementFG.get('accessControl.isBioStarEnabled')?.value == false) {
        this.siteManagementFG.get('accessControl.bioStarApiServerUrl')?.setValue(null);
        this.siteManagementFG.get('accessControl.bioStarApiUsername')?.setValue(null);
        this.siteManagementFG.get('accessControl.bioStarApiPassword')?.setValue(null);
      }


      if (this.siteManagementFG.get('accessControl.enableBioStarAccessGroup')?.value == false) {
        this.siteManagementFG.get('accessControl.bioStarAccessGroup')?.setValue(null);
        this.siteManagementFG.get('accessControl.bioStarUserGroup')?.setValue(null);
      }
    }

    let site: AdminSiteViewModel = this.mapItemsToAdminSiteViewModel();

    site.checkInLocationOverrideInKioskMode = this.checkInLocationOverrideInKioskModeCondition(site);

    this.inProgress = true
    if (this.isNewSiteRequest() && this.siteManagementMode == SiteManagementMode.Site) {
      this.siteService.add(site)
        .pipe(
          finalize(() => {
            this.inProgress = false
          }),
        ).subscribe(
          (res) => {
            this.result.emit({ isFinished: true, isSucceeded: true });
            this.router.navigate(['/manage-site/' + res.id]);
            window.scrollTo(0, 0);
          },
          (error) => {
            this.modalService.error(error ?? 'An error occurred in adding new site!').subscribe(_ => {
            });
          }
        );
    } else {
      let fullData;
      if (this.siteManagementMode == SiteManagementMode.Site) {
        fullData = {
          site,
          announcements: this.manageSiteModel?.announcements,
          checkInQuestions: this.manageSiteModel?.checkInQuestions,
          checkOutQuestions: this.manageSiteModel?.checkOutQuestions,
          forms: this.manageSiteModel?.forms?.map(i => i.formId),
          inductions: this.manageSiteModel?.inductions?.map(i => i.inductionId),
          scannableGroups: this.manageSiteModel?.scannableGroups,
          documentInfo: this.manageSiteModel?.documentInfo,
          requiredFields: this.manageSiteModel?.requiredFields.fields,
          siteVisitorTypes: this.manageSiteModel?.siteVisitorTypes?.map(i => i.visitorTypeId),
          visitorTypeAccompaniers: this.manageSiteModel?.visitorTypeAccompaniers,
        } as ManageSiteModel;
      } else {
        fullData = {
          announcements: this.manageSiteModel?.announcements,
          checkInQuestions: this.manageSiteModel?.checkInQuestions,
          checkOutQuestions: this.manageSiteModel?.checkOutQuestions,
          forms: this.manageSiteModel?.forms?.map(i => i.formId),
          inductions: this.manageSiteModel?.inductions?.map(i => i.inductionId),
        } as ManageSiteSupplierModel;
        //
      }

      (this.siteManagementMode == this.SiteManagementModeEnum.Site ?
        this.manageSiteService.saveSiteFullData(this.manageSiteModel?.site?.id, fullData) :
        this.manageSupplierSiteService.saveSitSupplierFullData(this.siteSupplierId, fullData)
      ).pipe(
        finalize(() => {
          this.inProgress = false
        }),
      ).subscribe(
        (res) => {
          this.manageSiteModel = res.data;
          this.mapSiteInfoToFormGroup();
          this.clearDirtyComponents()
          this.result.emit({ isFinished: true, isSucceeded: true });
          this.toastr.info('Your changes have been applied');

          // close all accordions
          document.querySelectorAll<HTMLElement>('.panel-open .panel-heading')
            .forEach(item => item.click());
          window.scrollTo(0, 0);
        },
        (error) => {
          this.modalService.error(error ?? 'An error occurred in updating site!');
        }
      );
    }
  }

  checkInLocationOverrideInKioskModeCondition(site: AdminSiteViewModel): LocationOverrideMode {
    let mode = LocationOverrideMode.Disabled;
    if (+site.checkinLocationMode !== CheckinLocationMode.Mandatory &&
      +site.checkinLocationMode !== CheckinLocationMode.MandatoryOnSite) {
      mode = site.checkInLocationOverrideInKioskMode =
        (this.manageSiteModel != null && this.manageSiteModel.site != null) ?
          this.manageSiteModel.site.checkInLocationOverrideInKioskMode :
          site?.CheckInLocationOverrideInKioskMode;
    }
    else {
      let frmVal = this.siteManagementFG.get('projectSettings.checkInLocationOverrideInKioskMode')?.value;
      mode = frmVal == null ? LocationOverrideMode.Disabled :
        frmVal === false ? LocationOverrideMode.Disabled :
          frmVal === true ? LocationOverrideMode.OverrideNonDisableModesToOptional :
            frmVal === 0 ? LocationOverrideMode.Disabled :
              frmVal === 1 ? LocationOverrideMode.OverrideNonDisableModesToOptional :
                LocationOverrideMode.Disabled;
    }
    return mode ?? LocationOverrideMode.Disabled;
  }
  validateLatLng() {
    return !(!this.siteManagementFG.get('projectSettings.isTemplate')?.value &&
      ((!this.siteManagementFG.get('projectSettings.latitude')?.value || isNaN(parseFloat(this.siteManagementFG.get('projectSettings.latitude')?.value))) ||
        (!this.siteManagementFG.get('projectSettings.longitude')?.value || isNaN(parseFloat(this.siteManagementFG.get('projectSettings.longitude')?.value))) ||
        (!this.siteManagementFG.get('projectSettings.address')?.value || (this.siteManagementFG.get('projectSettings.address')?.value == ""))
      ));
  }

  isValid(): boolean {
    if (maxNumber(this.checkInDelayInMinutes) < this.siteManagementFG.get('automatedAttendance.autoCheckInDelayInMinute')?.value) {
      this.modalService.error(`Maximum Check In Delay In Minute is ${maxNumber(this.checkInDelayInMinutes)}, Set current value to maximum by default`);
      this.siteManagementFG.get('automatedAttendance.autoCheckInDelayInMinute')?.setValue(
        maxNumber(this.checkInDelayInMinutes)
      )
      return false;
    }
    if (!this.validateLatLng()) {
      this.modalService.error('Please select a valid address from list or pick a place on map');
      return false;
    }

    if ((this.siteManagementFG.get('automatedAttendance.enableDelayedAnswerForCheckIn')?.value || this.siteManagementFG.get('automatedAttendance.enableDelayedAnswerForCheckOut')?.value) &&
      this.siteManagementFG.get('automatedAttendance.delayedAnswerNotificationFrequencyInMinute')?.value != null && this.siteManagementFG.get('automatedAttendance.delayedAnswerNotificationFrequencyInMinute')?.value > 0 &&
      this.siteManagementFG.get('automatedAttendance.delayedAnswerNotificationAfterCheckOutNotifyCount')?.value == null) {
      this.modalService.error('Please select Delayed Answer Notification After Checkout');
      return false;
    }

    if (
      this.siteManagementFG.get('advancedSettings.optInQuestionMode')?.value == OptInQuestionMode.CustomWithMessage && (!
        this.siteManagementFG.get('advancedSettings.optInQuestionMessage')?.value ||
        this.siteManagementFG.get('advancedSettings.optInQuestionMessage')?.value == "")) {
      this.modalService.error('Please fill the Opt-in question message');
      return false;
    }

    if (this.currentUserIsGod == false && this.siteManagementFG.get('automatedAttendance.geofencingThresholdInMinutes')?.value > this.maxAllowedGeofencingThresholdInMinutes) {
      this.modalService.error(`Automatic check out threshold should be less than ${this.maxAllowedGeofencingThresholdInMinutes}`);
      return false;
    }

    if (this.siteManagementFG.get('projectSettings.maxAllowedSiteRadius')?.value == null) {
      this.modalService.error(`Max Allowed Site Radius can not be null`);
      return false;
    }


    if (typeof
      this.siteManagementFG.get('projectSettings.siteRadius')?.value == 'undefined' ||
      this.siteManagementFG.get('projectSettings.siteRadius')?.value == null ||
      this.siteManagementFG.get('projectSettings.siteRadius')?.value == 0) {
      this.modalService.error(`Site radius must has value`);
      return false;
    }
    if (
      (this.siteManagementFG.get('offsite.enablePreCheckIn')?.value ||
        this.siteManagementFG.get('offsite.enableOffsiteInductions')?.value) &&
      !this.siteManagementFG.get('offsite.enablePreCheckInForFrequentUsers')?.value &&
      !this.siteManagementFG.get('offsite.enablePreCheckInByCode')?.value &&
      !this.siteManagementFG.get('offsite.enableOffsiteQR')?.value) {
      this.modalService.error('At least one access mode must be enabled!');
      return false;
    }
    // if (this.enablePreCheckInWithCode && (!this._site.preCheckInCode || this._site.preCheckInCode.toString().trim().length == 0)) {
    //   this.errorMessage = 'Pre check-in code is required!';
    //   return false;
    // }
    // if (this.enablePreCheckInWithCode && this._site.preCheckInCode.toString().length < this.minimumPreCheckInCodeLength) {
    //   this.errorMessage = `Pre check-in code length must be atleast ${this.minimumPreCheckInCodeLength} characters!`;
    //   return false;
    // }

    return true;
  }

  onSelectAddress(event: TypeaheadMatch): void {
    this.selectedAddress = event.item;
    this.siteManagementFG.get('projectSettings.address')?.setValue(this.selectedAddress.primaryTitle +
      ' ' +
      this.selectedAddress.secondaryTitle);
    this.inProgress = true;
    this.addressLookupService
      .getDetailsOfSelectedPlace(this.selectedAddress.id, this.uuid)
      .pipe(
        finalize(() => {
          this.inProgress = false;
        })
      )
      .subscribe(
        (result) => {
          if (this.validateCoordinate(+result.latitude))
            this.siteManagementFG.get('projectSettings.latitude')?.setValue(result.latitude.toString());
          if (this.validateCoordinate(+result.longitude))
            this.siteManagementFG.get('projectSettings.longitude')?.setValue(result.longitude.toString());
        },
        (_) => {
        }
      );
  }

  updateLocation(event) {
    setTimeout(() => { // prevent errors when this happens inside change detection cycle
      this.ngZone.run(() => { // inital load of the map is not in the zone
        this.siteManagementFG.get('projectSettings.latitude')?.setValue(+event.lat);
        this.siteManagementFG.get('projectSettings.longitude')?.setValue(+event.lng);
      });
    });
  }

  validateSiteRadius() {
    if (this.currentUserIsGod) {
      this.siteManagementFG.get('projectSettings.maxAllowedSiteRadius')?.setValue(
        this.siteManagementFG.get('projectSettings.siteRadius')?.value > this.siteManagementFG.get('projectSettings.maxAllowedSiteRadius')?.value
          ? this.siteManagementFG.get('projectSettings.siteRadius')?.value
          : this.siteManagementFG.get('projectSettings.maxAllowedSiteRadius')?.value);
    } else {
      this.showInvalidSiteRadiusMessage =
        this.siteManagementFG.get('projectSettings.siteRadius')?.value > this.siteManagementFG.get('projectSettings.maxAllowedSiteRadius').value;

      this.siteManagementFG.get('projectSettings.siteRadius')?.setValue(Math.min(
        this.siteManagementFG.get('projectSettings.siteRadius')?.value,
        this.siteManagementFG.get('projectSettings.maxAllowedSiteRadius')?.value
      ));
    }
  }
  validateMaxSiteRadius() {
    this.siteManagementFG.get('projectSettings.siteRadius')?.setValue(
      this.siteManagementFG.get('projectSettings.maxAllowedSiteRadius')?.value < this.siteManagementFG.get('projectSettings.siteRadius')?.value
        ? this.siteManagementFG.get('projectSettings.maxAllowedSiteRadius')?.value
        : this.siteManagementFG.get('projectSettings.siteRadius')?.value);
  }

  validateGeofencingThresholdInMinutes() {
    if (!this.currentUserIsGod) {
      this.siteManagementFG.get('automatedAttendance.geofencingThresholdInMinutes')?.setValue(
        this.siteManagementFG.get('automatedAttendance.geofencingThresholdInMinutes')?.value > this.maxAllowedGeofencingThresholdInMinutes
          ? this.maxAllowedGeofencingThresholdInMinutes
          : this.siteManagementFG.get('automatedAttendance.geofencingThresholdInMinutes')?.value);
    }
  }


  deleteSite() {
    this.modalService.confirm("Are You Sure?", `Delete ${this.manageSiteModel?.site?.name}`).subscribe(result => {
      if (result) {
        this.inProgress = true;
        this.siteService.delete(this.manageSiteModel?.site?.id)
          .pipe(finalize(() => this.inProgress = false))
          .subscribe(_ => {
            this.router.navigate(['company']);
          }, _ => {
            //if (error.status == 547) {
            this.modalService.confirm(
              "Warning! Could not delete the site because it has check-in/out records. Do you want to deactivate it instead?",
              `Delete ${this.manageSiteModel?.site?.name}`,
              "Deactivate",
              "Cancel").subscribe(result => {
                if (result) {
                  this.callChangeActiveService();
                }
              });
            //this.modalService.error("This site contains saved information like check-in/out users", "you can't remove this site");
            //} else
            //  this.modalService.error(error);
          });
      }
    })
  }

  changeActiveState() {
    let header = this.manageSiteModel?.site?.isActive ? `Deactivate ${this.manageSiteModel?.site?.name}` : `Activate ${this.manageSiteModel?.site?.name}`
    this.modalService.confirm("Are You Sure?", header).subscribe(result => {
      if (result) {
        this.callChangeActiveService();
      }
    })
  }

  callChangeActiveService() {
    this.inProgress = true;
    this.siteService.changeActiveState(this.manageSiteModel?.site?.id, !this.manageSiteModel?.site?.isActive)
      .pipe(finalize(() => this.inProgress = false))
      .subscribe(_ => {
        this.router.navigate(['sites']);
      }, error => {
        this.modalService.error(error ?? 'An error occurred');
      });
  }

  regenerateSecretKey() {
    let title: string, message: string;
    if (this.manageSiteModel?.site?.secretCode) {
      title = 'Rotate QR Code';
      message = 'This will create new QR code and previously printed QR codes will become invalid. Are you sure you want to continue?'
    } else {
      title = 'Enable QR Code';
      message = 'Are you sure you want to enable QR code access to the site?'
    }
    this.modalService.confirm(message, title).subscribe(result => {
      if (result) {
        this.inProgress = true;
        this.siteService.regenerateSecretKey(this.manageSiteModel?.site?.id)
          .pipe(finalize(() => {
            this.inProgress = false;
          }))
          .subscribe((result: SiteViewModel) => {
            this.manageSiteModel.site.secretCode = result?.secretCode;
            this.showSecretKey();
          }, _ => {
            this.modalService.error("We can't generate this key now, Please try again")
          });
      }
    });
  }

  siteQRCode: SiteQrCodeViewModel = new SiteQrCodeViewModel();
  offsiteQRCode: SiteQrCodeViewModel = new SiteQrCodeViewModel();

  clearSecretKey() {
    this.modalService.confirm('Are you sure you want disable QR code access?', 'Disable QR code').subscribe(result => {
      if (result) {
        this.inProgress = true;
        this.siteService.clearSecretKey(this.manageSiteModel?.site?.id)
          .pipe(finalize(() => {
            this.inProgress = false;
          }))
          .subscribe(_ => {
            this.manageSiteModel.site.secretCode = undefined;
            this.siteQRCode = new SiteQrCodeViewModel();
          }, _ => {
            this.modalService.error("Please try again")
          });
      }
    });
  }

  showQrCodeWithInstruction() {
    window.open(`/General/site-instructions/${this.manageSiteModel?.site?.id}/${this.manageSiteModel?.site?.secretCode}`, `site-instructions-${this.manageSiteModel?.site?.id}`);
    //window.open(`/General/full-qrcode/${this.manageSiteModel?.site?.id}/${this.manageSiteModel?.site?.secretCode}`, '_blank', 'top=0,left=0,height=100%,width=auto');
  }

  showSecretKey() {
    this.inProgress = true;
    this.siteService.showSecretKey(this.manageSiteModel?.site?.id)
      .pipe(finalize(() => {
        this.inProgress = false;
      }))
      .subscribe(result => {
        this.siteQRCode.qrCodeImageBase64 = result.qrCodeImageBase64;
      }, _ => {
        this.modalService.error("please try again")
      });
  }

  showOffsiteSecretKey() {
    this.inProgress = true;
    this.siteService.showOffsiteSecretKey(this.manageSiteModel?.site?.id)
      .pipe(finalize(() => {
        this.inProgress = false;
      }))
      .subscribe(result => {
        this.offsiteQRCode.qrCodeImageBase64 = result.qrCodeImageBase64;
        this.offsiteQRCode.qrCodeContent = result.qrCodeContent;
      }, _ => {
        this.modalService.error("please try again")
      });
  }

  @ViewChild("printSection") printSection: ElementRef;
  @ViewChild("printOffsiteSection") printOffsiteSection: ElementRef;

  print(printOffsite: boolean) {
    let printContents, popupWin;
    printContents = ((printOffsite ? this.printOffsiteSection : this.printSection).nativeElement as HTMLLinkElement).innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html lang="en">
        <head>
          <title>Print tab</title>
          <style>
          //........Customized style.......
          </style>
        </head>
    <body onload="window.print();"><div style="width:80%;">${printContents}</div></body>
      </html>`
    );
    popupWin.document.close();
  }


  getMultipleCheckinType(multipleCheckinType) {
    switch (multipleCheckinType) {
      case MultipleCheckinType.Disable:
        return "Disable";
      case MultipleCheckinType.Enable:
        return "Enable";
      case MultipleCheckinType.EnableForThisCompany:
        return "Enable For This Company";
    }
  }

  getMaxPeopleOnSiteMode(maxPeopleOnSiteMode) {
    switch (maxPeopleOnSiteMode) {
      case MaxPeopleOnSiteMode.Disable:
        return "Disable";
      case MaxPeopleOnSiteMode.Warning:
        return "Send Warning Email To Supervisor";
      case MaxPeopleOnSiteMode.Reject:
        return "Send Email To Supervisor And Reject";
    }
  }

  getCheckinApprovalModeFriendlyMode(mode: CheckInApprovalMode) {
    return getCheckinApprovalModeFriendlyMode(mode);
  }


  checkIfFormGroupIsDirty(formGroup) {
    if (this.manageSiteModel) {
      let currentSite = {};
      Object.entries(this.cloneFormGroup(this.siteManagementFG)[formGroup])?.forEach(([fcKey, fcValue]) => {
        currentSite[fcKey] = fcValue;
      })

      let clonedSite = {};
      if (this._clone_fg_) {
        Object.entries(this._clone_fg_[formGroup])?.forEach(([fcKey, fcValue]) => {
          clonedSite[fcKey] = fcValue;
        })
      }

      if (!isEqual(currentSite, clonedSite)) {
        return true;
      }
    }
    return false;
  }

  cloneFormGroup(formGroup) {
    let clonedFormGroup = {};
    Object.keys(formGroup['controls']).forEach((key) => {
      let stringifies = {};
      Object.keys(formGroup['controls'][key]['controls']).forEach((fcKey) => {
        let value = formGroup['controls'][key]['controls'][fcKey].value;
        if (value != null && value.constructor === Array) {
          let stringValue = '';
          for (let i of value) {
            stringValue += JSON.stringify(i);
          }
          value = stringValue;
        }
        stringifies[fcKey] = value != null ? value.toString() : "";
      });
      clonedFormGroup[key] = stringifies;
    });
    return clonedFormGroup;
  }

  isForcedCheckOutAfterMinuteMethod(switchValue) {
    this.isForcedCheckOutAfterMinute?.setValue(switchValue);
    var forceCheckout = this.siteManagementFG.get('projectSettings.forceCheckOutAfterMinute');
    if (switchValue) {
      forceCheckout?.setValue(
        this.manageSiteModel?.site?.forceCheckOutAfterMinute ?? 0);
      forceCheckout.setValidators([Validators.required, Validators.min(1)]);
    } else {
      forceCheckout?.setValue(null);
      forceCheckout.clearValidators();
    }
    forceCheckout.updateValueAndValidity();
  }

  updateForcedCheckOutAfterMinute(minute) {
    // if(parseInt(minute) > 0) {
    this.siteManagementFG.get('projectSettings.forceCheckOutAfterMinute')?.setValue(+minute);
    // } else {
    //   this.siteManagementFG.get('projectSettings.forceCheckOutAfterMinute')?.setValue(null);
    // }

  }

  /*===================*/
  updateManageSiteModel(value) {
    this.manageSiteModel = { ...value };
  }

  get isActiveAutoCheckInPanel() {
    return this.siteManagementFG.get('projectSettings.checkinLocationMode')?.value &&
      this.siteManagementFG.get('projectSettings.checkinLocationMode')?.value != CheckinLocationMode.Disable;
  }

  get checkinLocationMode() {
    return +this.siteManagementFG.get('projectSettings.checkinLocationMode')?.value;
  }

  onUpdate = false;
  resetSelectedSite() {
    let originalSiteId = this.siteId;
    this.onUpdate = true;
    this.siteId = 0;
    setTimeout(() => {
      this.siteId = originalSiteId;
      setTimeout(() => {
        this.onUpdate = false;
      }, 10);
    }, 10);
  }

  onChangeSelectedSite(site) {
    if (this.onUpdate == true)
      return;

    let dirtyResult = this.canDeactivate();
    if (dirtyResult == false) {
      this.resetSelectedSite();
    }
    else if (dirtyResult == true) {
      this.updateSelectedSite(site);
    }
    else //if (dirtyResult != true)
    {
      (dirtyResult as Observable<Boolean>).subscribe(r => {
        if (r != true) {
          this.resetSelectedSite();
        }
        else if (r == true) {
          this.updateSelectedSite(site);
        }
      })
    }
  }

  updateSelectedSite(site) {
    this.clearDirtyComponents();
    if (site.MaxPeopleOnSiteMode == null) {
      this.siteManagementFG.get('advancedSettings.maxPeopleOnSite').clearValidators();
      this.siteManagementFG.get('advancedSettings.maxPeopleOnSite').updateValueAndValidity();

    }
    if (site.OptInQuestionMode == null) {
      this.siteManagementFG.get('advancedSettings.optInQuestionMessage').clearValidators();
      this.siteManagementFG.get('advancedSettings.optInQuestionMessage').updateValueAndValidity();
    }

    if (site.isExternalSite && this.siteSupplierId != site.externalSiteSupplierId) {
      this.inProgress = true;
      this.router.navigateByUrl('/manage-site/supplier/' + site.externalSiteSupplierId);
    }
    else if (!site.isExternalSite && this.siteId != site.id) {
      this.inProgress = true;
      this.router.navigateByUrl('/manage-site/' + site.id);
    }
  }
  getAccessControlHeaderInfo() {
    let provider: AccessControlUnitProvider = this.siteManagementFG.get('accessControl.accessControlUnitProvider')?.value;
    if (provider == AccessControlUnitProvider.Disable)
      return [
        {
          'title': '',
          'value': 'Disable',
        },
      ];
    let providerTitle = {
      'title': 'Provider: ',
      'value': this.accessControlUnitProviderPipe.transform(provider),
    };
    if (provider == AccessControlUnitProvider.OneBreadCrumb)
      return [
        providerTitle,
        {
          'title': 'Automatic Check-In: ',
          'value': this.accessControlAutomaticAttendanceModePipe.transform(this.siteManagementFG.get('accessControl.accessControlAutomaticCheckInMode')?.value),
        },
        {
          'title': 'Automatic Check-Out: ',
          'value': this.accessControlAutomaticAttendanceModePipe.transform(this.siteManagementFG.get('accessControl.accessControlAutomaticCheckOutMode')?.value),
        }];
    if (provider == AccessControlUnitProvider.BioStar)
      return [
        providerTitle,
      ];
  }

  get isAccessControlDisabled(): boolean {
    return this.siteManagementFG.get('accessControl.accessControlUnitProvider')?.value == AccessControlUnitProvider.Disable;
  }
  get is1BcProviederSelected(): boolean {
    return this.siteManagementFG.get('accessControl.accessControlUnitProvider')?.value == AccessControlUnitProvider.OneBreadCrumb;
  }
  get isBioProviederSelected(): boolean {
    return this.siteManagementFG.get('accessControl.accessControlUnitProvider')?.value == AccessControlUnitProvider.BioStar;
  }

  //#region BioStar

  get isBiostarEnabled(): boolean {
    return this.manageSiteModel?.additionalData?.isBioStarEnabled
      ?? false;
  }
  bioStartDeviceList: BioStarDevice[];
  bioDevicesFetchInProgress: boolean = false;

  getBioStarDeviceList() {
    this.bioDevicesFetchInProgress = true;
    return this.biostarService.getBioStarDevices(this.siteId)
      .subscribe(res => {
        this.bioDevicesFetchInProgress = false;
        this.bioStartDeviceList = res;
      })
  }

  initBioStarDeviceList() {
    if (!this.bioStartDeviceList) {
      this.getBioStarDeviceList()
    }
  }
  showSelectBioStarDeviceModal(template: any) {
    this.initBioStarDeviceList()
    this.modalService.show(template);
  }
  onSelectBiostarDevice(device: BioStarDevice) {
    let newAccessControlUnit = (this.siteManagementFG.controls['accessControl'] as FormGroup).controls['newAccessControlUnit'] as FormGroup;
    newAccessControlUnit.controls['displayName'].setValue(device.name);
    newAccessControlUnit.controls['controlUnitIdentifier'].setValue(device.id);
    this.modalService.hide();
  }

  changeBioStarActiveState(state) {
    this.siteManagementFG.get('accessControl.isBioStarEnabled')?.setValue(state);
    if (!state) {
      this.siteManagementFG.get('accessControl.bioStarApiServerUrl')?.clearValidators();
      this.siteManagementFG.get('accessControl.bioStarApiUsername')?.clearValidators();
      this.siteManagementFG.get('accessControl.bioStarApiPassword')?.clearValidators();
      this.siteManagementFG.get('accessControl.bioStarApiServerUrl')?.updateValueAndValidity();
      this.siteManagementFG.get('accessControl.bioStarApiUsername')?.updateValueAndValidity();
      this.siteManagementFG.get('accessControl.bioStarApiPassword')?.updateValueAndValidity();
    }
    else {
      this.siteManagementFG.get('accessControl.bioStarApiServerUrl')?.setValidators([Validators.required]);
      this.siteManagementFG.get('accessControl.bioStarApiUsername')?.setValidators([Validators.required]);
      this.siteManagementFG.get('accessControl.bioStarApiPassword')?.setValidators([Validators.required]);
      this.siteManagementFG.get('accessControl.bioStarApiServerUrl')?.updateValueAndValidity();
      this.siteManagementFG.get('accessControl.bioStarApiUsername')?.updateValueAndValidity();
      this.siteManagementFG.get('accessControl.bioStarApiPassword')?.updateValueAndValidity();
    }
  }

  changeBioStarEnableAccessGroup(state) {
    this.siteManagementFG.get('accessControl.enableBioStarAccessGroup')?.setValue(state);
    if (!state) {
      this.siteManagementFG.get('accessControl.bioStarAccessGroup')?.clearValidators();
      this.siteManagementFG.get('accessControl.bioStarAccessGroup')?.updateValueAndValidity();
      this.siteManagementFG.get('accessControl.bioStarUserGroup')?.clearValidators();
      this.siteManagementFG.get('accessControl.bioStarUserGroup')?.updateValueAndValidity();
    }
    else {
      if (this.siteManagementFG.get('accessControl.enableBioStarAccessGroup')?.value === true) {
        this.siteManagementFG.get('accessControl.bioStarAccessGroup')?.setValidators([Validators.required]);
        this.siteManagementFG.get('accessControl.bioStarUserGroup')?.setValidators([Validators.required]);
      }
      this.siteManagementFG.get('accessControl.bioStarAccessGroup')?.updateValueAndValidity();
      this.siteManagementFG.get('accessControl.bioStarUserGroup')?.updateValueAndValidity();
    }
  }

  closeInfoPanel() {
    this.showInfo = false;
    this.storageService.set(LocalStorageKey.SiteSettingsInfoClose, "1");
  }
  //#endregion
}
