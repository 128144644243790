<div class="d-flex flex-wrap  mb-1" [ngClass]="{'flex-column justify-content-between' : renderMode == 'column','flex-row justify-content-lg-start' : renderMode == 'list' }">
  <div style="padding-top: 4px;">
    <i *ngIf="renderMode=='list' && !hideLabel" class="fab fa-elementor"></i>
    <i *ngIf="formControl.valid && permission == FieldPermission.ViewAndEdit" style="color: green;" class="fas fa-check-circle ml-1 mr-1"></i>
    <i *ngIf="!formControl.valid  && permission == FieldPermission.ViewAndEdit && mode=='edit'" style="color: red;" class="fas fa-times-circle  mr-2"></i>
    <span class="ml-1 mr-2" *ngIf="!hideLabel">{{_field.title}}: </span>
  </div>
  <div class="form-group form-group-sm d-flex justify-content-end">
    <span style="line-height: 32px;" *ngIf="!formControl.enabled">{{formControl.value}}</span>
    <input *ngIf="formControl.enabled" [type]="_field.type == FieldValueType.Numeric ? 'number':'text'" style="max-width: 350px;" [formControl]="formControl" class="form-control">
    <div class="input-group-append" *ngIf="!_isEditable && !(permission == FieldPermission.ViewOnly)">
      <button class="btn btn-sm btn-outline-secondary" *ngIf="mode=='edit'" (click)="onSave();mode='view';updateFormControlStatus()">
        <i class="fas fa-save"></i>
      </button>
      <a tooltip="Discard Changes" style="line-height: 40px;" class="cursor-pointer text-danger ml-2" *ngIf="mode=='edit'" (click)="onDiscardChanges();mode='view';updateFormControlStatus()">
        <i class="fas fa-times-circle"></i>
      </a>
      <a tooltip="Edit" style="line-height: 32px;" class="cursor-pointer text-danger ml-2" *ngIf="mode=='view'" (click)="mode='edit';updateFormControlStatus()">
        <i class="fas fa-edit"></i>
      </a>
    </div>
  </div>
</div>



