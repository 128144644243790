import { ModalService } from './../../../services/modal.service';
import { SiteAttendanceService } from './../../../services/site-attendance.service';
// import { AnnouncementViewmodel, BaseAnnouncementViewmodel, SiteWithAnnouncementViewModel } from './../../../models/announcement-viewmodel';
import { LoaderComponent } from './../../../custom-controls/loader/loader.component';
import { SiteQuestionService } from './../../../services/site-question.service';
import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AnonymousUserProfileWithStatusViewmodel } from 'src/app/models/anonymous-user-profile-with-status-viewmodel';
import { UserService } from 'src/app/services/user.service';
import { SiteAttendanceQuestionAnswer } from 'src/app/models/attendance-request/site-attendance-question-answer';
import { SiteQuestionsViewModel } from 'src/app/models/sitequestion-viewmodel';
import { FileType } from 'src/app/enums/file-type.enum';
import { AnnouncementService } from 'src/app/services/announcement.service';
import {
  AnonymousCheckOutAttendanceRequestModel,
  RequestAttendanceMode
} from 'src/app/models/attendance-request/attendance-request-viewmodel';
import { AnonymousUserAttendanceCheckInsViewmodel } from 'src/app/models/anonymous-user-attendance-check-ins-viewmodel';
import { AttendanceFieldWithValue, FieldPermission } from 'src/app/models/attendance-field-model';
import {SiteQuestionViewModel} from "../../../models/site-question-viewmodel";
import {CustomFormControl} from "../../../models/custom-formcontrol";
import {FormArray} from "@angular/forms";
import {
  convertDocumentToFormControl,
  getUploadedDocumentsFromFormArray, isSetSupplierCheckinData,
  setDocumentFormControlForQuestions
} from "../../../helpers/general-functions";
import {SupplierCheckinViewModel} from "../../../models/supplier-checkin-view-model";
import {RequiredDocumentViewModel} from "../../../models/user-document-type";
import {SupplierCheckinModel} from "../../../models/supplier-checkin-model";

@Component({
  selector: 'obc-anonymous-checkout',
  templateUrl: './anonymous-checkout.component.html',
  styleUrls: ['./anonymous-checkout.component.scss']
})
export class AnonymousCheckoutComponent implements OnInit {


  _questions: SiteQuestionViewModel[] = null;
  questions$: BehaviorSubject<SiteQuestionViewModel[]> = new BehaviorSubject<SiteQuestionViewModel[]>(this._questions);
  questionContainerValidation$: BehaviorSubject<{
    valid: boolean;
    answers: SiteAttendanceQuestionAnswer[];
  }> = new BehaviorSubject<{
    valid: boolean;
    answers: SiteAttendanceQuestionAnswer[];
  }>({valid: true, answers: []});
  questionContainerValid: boolean;
  answers: SiteAttendanceQuestionAnswer[];
  set questions(model: SiteQuestionViewModel[]) {
    this._questions = model;
    this._questions = setDocumentFormControlForQuestions(this._questions);
    this.questions$.next(this._questions);
  }

  /*Supplier Data*/
  supplierCheckinData: SupplierCheckinViewModel;
  _supplierQuestions: SiteQuestionViewModel[] = null;
  supplierRequiredDocumentFormControls: CustomFormControl[] = [];
  supplierRequiredDocumentFormArray: FormArray;
  supplierQuestions$: BehaviorSubject<SiteQuestionViewModel[]> = new BehaviorSubject<SiteQuestionViewModel[]>(this._supplierQuestions);
  supplierQuestionContainerValidation$: BehaviorSubject<{
    valid: boolean;
    answers: SiteAttendanceQuestionAnswer[];
  }> = new BehaviorSubject<{
    valid: boolean;
    answers: SiteAttendanceQuestionAnswer[];
  }>({valid: true, answers: []});
  supplierQuestionContainerValid: boolean;
  supplierAnswers: SiteAttendanceQuestionAnswer[];
  set supplierQuestions(model: SiteQuestionViewModel[]) {
    this._supplierQuestions = model;
    this._supplierQuestions = setDocumentFormControlForQuestions(this._supplierQuestions);
    this.supplierQuestions$.next(this._supplierQuestions);
  }


  model = new BehaviorSubject<AnonymousUserProfileWithStatusViewmodel>(null);
  inprocess = false;
  reloadHandle: any = null;
  AnnouncementType = FileType;
  questionValidator$: BehaviorSubject<{ valid: boolean, answers: SiteAttendanceQuestionAnswer[] }> =
    new BehaviorSubject({ valid: false, answers: [] });

  requiredDocumentFormControls: CustomFormControl[] = [];
  requiredDocumentFormArray: FormArray;

  _mode: 'view' | 'answer' = "view";
  get mode() {
    return this._mode;
  }
  set mode(value: any) {
    this._mode = value;
    if (this._mode == "view")
      this.activeReloadStatus();
    else
      this.clearReloadStatus();
  }
  // questions = new BehaviorSubject<SiteQuestionViewModel[]>(null);


  /*Supplier Data*/
  // supplierQuestionValidator$: BehaviorSubject<{ valid: boolean, answers: SiteAttendanceQuestionAnswer[] }> =
  //   new BehaviorSubject({ valid: false, answers: [] });
  // supplierQuestions = new BehaviorSubject<SiteQuestionViewModel[]>(null);
  // supplierRequiredDocumentFormControls: CustomFormControl[] = [];
  // supplierRequiredDocumentFormArray: FormArray;

  constructor(private userService: UserService,
    private announcementSertvice: AnnouncementService,
    private siteAttendanceService: SiteAttendanceService,
    private modalService: ModalService,
    private siteQuestionService: SiteQuestionService) { }

  ngOnInit() {
    this.getAnonymousCheckInStatus();

    this.questionContainerValidation$.subscribe((res) => {
      this.questionContainerValid = res.valid;
      this.answers = res.answers;
    });

    this.supplierQuestionContainerValidation$.subscribe((res) => {
      this.supplierQuestionContainerValid = res.valid;
      this.supplierAnswers = res.answers;
    });

    this.mode = "view";
  }

  private activeReloadStatus() {
    this.clearReloadStatus();
    this.reloadHandle = setInterval(() => {
      this.getAnonymousCheckInStatus();
    }, 5 * 60 * 1000);
  }

  private clearReloadStatus() {
    if (this.reloadHandle)
      clearInterval(this.reloadHandle);
  }

  nocheckinFound = false;
  selectedStatus: AnonymousUserAttendanceCheckInsViewmodel = null;
  private getAnonymousCheckInStatus() {
    this.inprocess = true;
    this.clearReloadStatus();
    this.userService.getAnonymousUserProfileWithCheckIns()
      .pipe(finalize(() => {
        this.inprocess = false;
        this.activeReloadStatus();
      }))
      .subscribe(res => {
        this.model.next(res);
        if (res != null && res.userAttendances && res.userAttendances.length)
          this.nocheckinFound = false;
        else
          this.nocheckinFound = true;
      }, err => {
        if (err.status == 404) {
          this.nocheckinFound = true;
          //this.modalService.error("No Check-in Found");
        }
      });
  }

  getFilteredExtraFields(fields: AttendanceFieldWithValue[]) {
    return fields.filter(x => {
      var premission = x.userPermission;
      return premission != FieldPermission.Disabled;
    });
  }

  onSaveExtraField($event, key: string, loader: LoaderComponent) {
    loader.show = true;
    this.siteAttendanceService.updateAnonymousAttendanceExtraField($event, key)
      .pipe(finalize(() => loader.show = false))
      .subscribe(res => {
      }, err => {
        this.modalService.error(err)
      });
  }

  onCheckOut(status: AnonymousUserAttendanceCheckInsViewmodel, loader: LoaderComponent) {
    loader.show = true;
    this.selectedStatus = status;
    this.siteQuestionService.GetQuestionsToCheckOutForAnonymousUser(status.siteAttendance.anonymousCheckInKey)
      .pipe(finalize(() => loader.show = false))
      .subscribe(res => {
        if (res == null) {
          this.getAnonymousCheckInStatus();
          return;
        }

        this.questions = res.siteQuestions;
        this.supplierCheckinData = res.supplierCheckinData;
        this.supplierQuestions = res.supplierCheckinData?.siteQuestions
        this.mode = 'answer';

        this.questions$.next(this._questions);
        this.supplierQuestions$.next(this._supplierQuestions);

        this.ResetAll();

      }, err => this.getAnonymousCheckInStatus());
  }

  checkout(loader: LoaderComponent) {
    loader.show = true;
    const model = {
      Mode: RequestAttendanceMode.Self,
      answers: this.answers,
      latitude: null,
      longtitude: null,
      documents: getUploadedDocumentsFromFormArray(this.requiredDocumentFormArray),
      supplierCheckOutData: {
        supplierAnswers: this.supplierAnswers,
        supplierDocuments: getUploadedDocumentsFromFormArray(this.supplierRequiredDocumentFormArray),
      } as SupplierCheckinModel
    } as AnonymousCheckOutAttendanceRequestModel;
    this.siteAttendanceService.AnonyousCheckOut(this.selectedStatus.siteAttendance.anonymousCheckInKey, model)
      .pipe(finalize(() => loader.show = false))
      .subscribe(res => {
        if (res.success)
          this.modalService.info("You Are Successfully Checked Out", "Check-Out").subscribe(res => {
            this.getAnonymousCheckInStatus();
            this.mode = "view";
          })
        else
          this.modalService.error(res.message, "Check-Out Failed");
      });
  }

  // resetQuestionContainer(res: SiteQuestionViewModel[]) {
  //   this.questions.next(res);
  // }
  // resetSupplierQuestionContainer(res: SiteQuestionViewModel[]) {
  //   this.supplierQuestions.next(res);
  // }

  onAcceptSupplier(){
    this.getAnonymousCheckInStatus();
  }

  ResetAll() {
    this.requiredDocumentFormControls = [];
    this.supplierRequiredDocumentFormControls = [];

    let res = convertDocumentToFormControl([], (this._questions?.filter(question => question.documentFormControl != null)?.map(question => question.documentFormControl) ?? []));
    this.requiredDocumentFormArray = res?.formArray;
    this.requiredDocumentFormControls = res?.controls;

    let supRes = convertDocumentToFormControl([], (this._supplierQuestions?.filter(question => question.documentFormControl != null)?.map(question => question.documentFormControl) ?? []));
    this.supplierRequiredDocumentFormArray = supRes?.formArray;
    this.supplierRequiredDocumentFormControls = supRes?.controls;
  }

  get isFormValid(): boolean {

    let isValidDocuments = this.requiredDocumentFormArray == null ||
      this.requiredDocumentFormArray.controls.every(c => {
        let control = (c as CustomFormControl);
        let doc = control.item as RequiredDocumentViewModel;
        return doc.questionId == null ? c.valid : true;
      });

    let isValidSupplierDocuments = this.supplierRequiredDocumentFormArray == null ||
      this.supplierRequiredDocumentFormArray.controls.every(c => {
        let control = (c as CustomFormControl);
        let doc = control.item as RequiredDocumentViewModel;
        return doc.questionId == null ? c.valid : true;
      });

    return this.questionContainerValid && this.supplierQuestionContainerValid && isValidDocuments && isValidSupplierDocuments;
  }

  isSetSupplierCheckinData() {
    return isSetSupplierCheckinData(this.supplierCheckinData);
  }
}
