export enum ProcoreIntegrationType {
  ImportSite = 0,
  ImportUser = 1,
  ExportSiteAttendance = 2,
  UploadSiteSupplierDocumentsEnabled = 3,
  UploadSiteSupplierDocumentVersionSignOffsEnabled = 4,
  UploadSiteInductionsEnabled = 5,
  ImportCompanyDirectory = 6,
  ImportProjectDirectory = 7,
  UploadPermitFormsEnabled = 8,
  UploadSiteAssetFormsEnabled = 9,
  ImportCompanyRoles = 10,
  ImportProjectUserRoles = 11,
  UploadSiteBriefingsEnabled = 12,
}

export enum IntegrationOperation {
  ImportSites = 0,
  ImportProjectUsers = 1,
  ImportCompanyDirectory = 2,
  ImportProjectDirectory = 3,
  ExportSiteSupplierDocuments = 4,
  ExportSiteInductions = 5,
  ExportPermitForms = 6,
  ExportSiteAssetForms = 7,
  ExportSiteAttendances = 8,
  ImportCompanyRoles = 9,
  ImportProjectUserRoles = 10,
  ExportTimeCards = 11,
  ExportAggregatedManpower = 12,
  ExportVisitorLogs = 13,
  ExportManpowerLogs = 14,
  ImportAttendances = 15,
  ExportSiteBriefings = 16,
}
