<div class="row">
  <div class="col-md-12" *ngIf="formGroup">
    <form class="form-horizontal form-bordered" [formGroup]="formGroup" (ngFormSubmit)="addForm()">
      <section class="card">
        <div class="card-header">
          {{ formToEdit ? 'Edit Form' : 'Add Form'}}
          <div class="card-actions">
            <a (click)="hide.emit()" class="card-action card-action-dismiss" data-card-dismiss=""></a>
          </div>
        </div>
        <div class="card-body">
          <obc-loader [show]="inProgress"></obc-loader>
          <div class="row">
            <div class="col-md-12 col-xl-5">
              <div class="card">
                <div class="card-body">
                  <div class="form-group row">
                    <div class="col-md-6 col-sm-12">
                      <label class="control-label">{{currentFormType == formType.SiteBriefing ? 'Form Title' :
                        'Name'}}</label>
                      <input type="text" class="form-control" formControlName="name"
                        [maxlength]="currentFormType == formType.SiteBriefing ? 100 : 500">
                    </div>
                    <div class="col-md-5 col-sm-12">
                      <label class="control-label">Button Title</label>
                      <input type="text" class="form-control" formControlName="buttonTitle"
                        [maxlength]="currentFormType == formType.SiteBriefing ? 50 : 500">
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-md-6 col-sm-12">
                      <label class="control-label">Type</label>
                      <div class="d-flex flex-row">
                        <select class="custom-select form-control" formControlName="type"
                          [attr.disabled]="isEditMode?'':null">
                          <option *ngFor="let type of getFormTypes()" [ngValue]="formType[type]">
                            {{ formType[type] | formType }}
                          </option>
                        </select>
                      </div>
                    </div>

                    <div class="col-md-6 col-sm-12"
                      *ngIf="currentFormType == formType.Generic || currentFormType == formType.AssetRegistration || currentFormType == formType.SiteBriefing">
                      <label class="control-label">{{currentFormType == formType.SiteBriefing ? 'What sites does this
                        apply to?' : 'Scope'}}</label>
                      <div class="d-flex flex-row">
                        <select class="custom-select form-control" formControlName="scope">
                          <option *ngFor="let scope of formScope | enumToArray" [ngValue]="formScope[scope]">
                            {{ formScope[scope] | formScope:currentFormType}}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-6 col-sm-12" *ngIf="currentFormType == formType.Permit">
                      <label class="control-label">Apply For</label>
                      <div class="d-flex flex-row">
                        <select class="custom-select form-control" formControlName="applyFor">
                          <option *ngFor="let applyFor of formApplyFor | enumToArray"
                            [ngValue]="formApplyFor[applyFor]">
                            {{ formApplyFor[applyFor] | formApplyFor }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-6 col-sm-12 mt-3" *ngIf="currentFormType == formType.Generic">
                      <label class="control-label">Ask Mode</label>
                      <div class="d-flex flex-row">
                        <select class="custom-select form-control" formControlName="askMode">
                          <option *ngFor="let askMode of formAskMode | enumToArray" [ngValue]="formAskMode[askMode]">
                            {{ formAskMode[askMode] | formAskMode }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row" *ngIf="showDocumentKindSection()">
                    <div class="col-md-12 col-sm-12">
                      <label class="control-label">Supplier Document Kind(s)</label>
                      <div class="d-flex flex-row">
                        <obc-check-list-form-control formControlName="documentKinds"
                          *ngIf="formGroup.controls['type'].value == formType.SupplierDocumentReview "
                          style="font-size: smaller;" [values]="documentKindCheckList">
                        </obc-check-list-form-control>
                      </div>
                    </div>
                  </div>
                  <ng-container *ngIf="currentFormType == formType.AssetOperation">
                    <div class="form-group row">
                      <div class="col-md-6 col-sm-12">
                        <label class="control-label">FormCategory</label>
                        <div class="d-flex flex-row">
                          <label>
                            <input class="radio" [value]="AssetOperationFormCategoryType.All"
                              formControlName="assetOperationFormCategoryType" type="radio">
                            All Categories
                          </label>
                          <label class="ml-3">
                            <input class="radio" [value]="AssetOperationFormCategoryType.Specific"
                              formControlName="assetOperationFormCategoryType" type="radio">
                            Specific Category
                          </label>
                        </div>
                      </div>
                      <ng-container
                        *ngIf="formGroup.get('assetOperationFormCategoryType').value == AssetOperationFormCategoryType.Specific">
                        <div class="w-100"></div>
                        <div class="col-md-6 col-sm-12">
                          <obc-loader *ngIf="formCategoriesLoading"></obc-loader>
                          <ng-select #formCategorySelect formControlName="assetOperationFormCategories"
                            [closeOnSelect]="false" [items]="formCategories" bindLabel="title" [multiple]="true"
                            [compareWith]="formCategorySelectCompareFn">
                          </ng-select>
                        </div>
                      </ng-container>
                    </div>
                  </ng-container>
                  <div class="form-group row"
                    *ngIf="currentFormType == formType.AssetRegistration || currentFormType == formType.AssetOperator">
                    <div class="col-md-6 col-sm-12">
                      <label class="control-label">Approval Mode</label>
                      <div class="d-flex flex-row">
                        <select class="custom-select form-control" formControlName="approvalMode">
                          <option *ngFor="let approvalMode of FormApprovalMode | enumToArray"
                            [ngValue]="FormApprovalMode[approvalMode]">
                            {{ FormApprovalMode[approvalMode] | formApprovalMode }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row" *ngIf="currentFormType == formType.SiteBriefing">
                    <div class="col-md-6 col-sm-12">
                      <label class="control-label">Sign-Off</label>
                      <div class="d-flex flex-row">
                        <label>
                          <input class="radio" [value]="OptionalMandatoryState.Optional"
                            formControlName="optionalMandatoryState" type="radio">
                          Optional
                        </label>
                        <label class="ml-3">
                          <input class="radio" [value]="OptionalMandatoryState.Mandatory"
                            formControlName="optionalMandatoryState" type="radio">
                          Mandatory
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row">
                    <div class="col-md-6 col-sm-12">
                      <label class="control-label">Status</label>
                      <div class="d-flex flex-row">
                        <obc-switch formControlName="isEnable" [trueLabel]="' Active '"
                          [falseLabel]="' Inactive '"></obc-switch>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row" *ngIf="currentFormType == formType.Permit">
                    <div class="form-group col-md-6 col-sm-12">
                      <label class="control-label">Permit Closure Policy</label>
                      <select formControlName="permitClosurePolicy" class="form-control">
                        <option *ngFor="let permit of PermitClosurePolicy | enumToArray"
                          [ngValue]="PermitClosurePolicy[permit]">
                          {{PermitClosurePolicy[permit] | permitClosurePolicy}}</option>
                      </select>
                    </div>
                    <div class="form-group col-md-6 col-sm-12">
                      <label class="control-label">Status After Approval</label>
                      <select formControlName="permitStatusAfterApproval" class="form-control">
                        <ng-container *ngFor="let permit of PermitStatus | enumToArray">
                          <option *ngIf="PermitStatus[permit].toString() != PermitStatus.Close.toString()"
                            [ngValue]="PermitStatus[permit]">
                            {{PermitStatus[permit] | permitStatus}}</option>
                        </ng-container>
                      </select>
                    </div>
                  </div>
                  <div class="form-group row" *ngIf="currentFormType == formType.SupplierForm ||
    currentFormType == formType.AssetRegistration ||
    currentFormType == formType.AssetOperator ||
    currentFormType == formType.AssetOperation">
                    <div class="col-md-6 col-sm-12">
                      <label class="control-label">Enable Expiry Date</label>
                      <div class="d-flex flex-row">
                        <obc-switch formControlName="isEnableSupplierFormExpiryDate"></obc-switch>
                      </div>
                    </div>
                  </div>
                  <ng-container *ngIf="currentFormType == formType.AssetRegistration">
                    <div class="form-group row">
                      <div class="col-md-6 col-sm-12">
                        <label class="control-label">Operator Registration Form</label>
                        <div class="d-flex flex-row">
                          <obc-form-selector [formType]="formType.AssetOperator" [selectFirstItemOfOneItemArray]="false"
                            class="form-selector-full" (selectedForm)="onSelectForm($event,'OperatorRegistrationForm')"
                            [formId]="assetOperatorFormId">
                          </obc-form-selector>
                        </div>
                      </div>
                    </div>
                  </ng-container>
                  <ng-container *ngIf="currentFormType == formType.Permit">
                    <div class="form-group row">
                      <div class="col-md-6 col-sm-12">
                        <label class="control-label">Permit Open Form</label>
                        <div class="d-flex flex-row">
                          <obc-form-selector [formType]="formType.Generic" [selectFirstItemOfOneItemArray]="false"
                            class="form-selector-full" (selectedForm)="onSelectForm($event,'PermitInUseForm')"
                            [formId]="permitInUseFormId">
                          </obc-form-selector>
                        </div>
                      </div>
                      <div class="col-md-6 col-sm-12">
                        <label class="control-label">Permit On-hold Form</label>
                        <div class="d-flex flex-row">
                          <obc-form-selector [formType]="formType.Generic" [selectFirstItemOfOneItemArray]="false"
                            class="form-selector-full" (selectedForm)="onSelectForm($event,'PermitOnholdForm')"
                            [formId]="permitOnholdFormId">
                          </obc-form-selector>
                        </div>
                      </div>
                      <div class="col-md-6 col-sm-12">
                        <label class="control-label">Permit Close Form</label>
                        <div class="d-flex flex-row">
                          <obc-form-selector [formType]="formType.Generic" [selectFirstItemOfOneItemArray]="false"
                            class="form-selector-full" (selectedForm)="onSelectForm($event,'PermitCloseForm')"
                            [formId]="permitCloseFormId">
                          </obc-form-selector>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6 col-sm-12">
                        <label class="control-label">Can Set Schedule</label>
                        <div class="d-flex flex-row">
                          <label>
                            <input class="radio" [value]="true" formControlName="isEnablePermitSchedule" type="radio">
                            Enable
                          </label>
                          <label class="ml-3">
                            <input class="radio" [value]="false" formControlName="isEnablePermitSchedule" type="radio">
                            Disable
                          </label>
                        </div>
                      </div>
                      <div class="col-md-6 col-sm-12">
                        <label class="control-label">Can Request Exclusive Access</label>
                        <div class="d-flex flex-row">
                          <label>
                            <input class="radio" [value]="true" formControlName="isEnablePermitZone" type="radio">
                            Enable
                          </label>
                          <label class="ml-3">
                            <input class="radio" [value]="false" formControlName="isEnablePermitZone" type="radio">
                            Disable
                          </label>
                        </div>
                      </div>
                    </div>
                  </ng-container>

                  <div class="form-group row">
                    <div class="col-md-12">
                      <label class="control-label">Form Group</label>
                      <input name="groupName" placeholder="Search and add form group"
                        [formControl]="formGroup.get('groupName')" [typeahead]="formGroups$" [isAnimated]="true"
                        [adaptivePosition]="true" [typeaheadAsync]="true" [typeaheadOptionsInScrollableView]="6"
                        (typeaheadOnSelect)="onSelectFormGroup($event)"
                        [typeaheadItemTemplate]="customFormGroupTemplate" [typeaheadMinLength]="2"
                        class="form-control" />
                      <div class="input-group-prepend" *ngIf="formGroupLoader">
                        <div class="spinner-border text-primary" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>
                      </div>
                    </div>
                    <ng-template #customFormGroupTemplate let-model="item">
                      <div>
                        <strong>{{ model }}</strong>
                      </div>
                    </ng-template>
                  </div>
                  <div class="row" *ngIf="isVisitorTypeFieldVisible">
                    <div class="col-md-12">
                      <app-visitor-type-selector [selectedVisitorIds]="formGroup.controls.visitorTypeIds.value"
                        (selectedVisitorIdsChange)="onSelectedVisitorTypeChange($event)">
                      </app-visitor-type-selector>
                    </div>
                  </div>
                  <div class="col-12 mt-3" *ngIf="currentFormType != formType.SiteBriefing">
                    <div class="d-flex align-items-center">
                      <label class="mt-1 mr-2 mb-0">Allow users to attach additional documents</label>
                      <obc-switch [trueLabel]="' Yes '" [falseLabel]="' No '"
                        formControlName="allowUsersToAttachAdditionalDocuments">
                      </obc-switch>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-12 col-xl-7">
              <div class="card">
                <div class="card-body">
                  <obc-items-mixture [orderableItems]="formGroup.controls['items'].value" (items)="orderedItems($event)"
                    [showAddDocumentButton]="currentFormType != formType.SiteBriefing" [formType]="currentFormType">
                  </obc-items-mixture>
                </div>
                <div class="card-footer">
                  <button class="btn btn-warning mr-1 pull-right" [disabled]="!isFormGroupValid"
                    (click)="onModalPreview(PreviewForm)">
                    Show Preview
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <footer class="card-footer text-right">

          <button class="btn btn-primary mr-1" [disabled]="!isFormGroupValid" (click)="addForm()">
            {{ formToEdit ? 'Update' : 'Add'}}
          </button>
          <button type="reset" (click)="hide.emit()" class="btn btn-default">Cancel</button>
        </footer>
      </section>
    </form>
  </div>
</div>
<ng-template #PreviewForm>
  <obc-preview-upsert [previewType]="'form'" [formModel]="formModel" [modalHeader]="'Form Preview'"
    (hide)="bsModalRef.hide()">

  </obc-preview-upsert>
</ng-template>