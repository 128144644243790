import { Pipe, PipeTransform } from '@angular/core';
import { OptInQuestionMode } from '../enums/opt-in-question-mode.enum';

@Pipe({
  name: 'optInQuestionMode'
})
export class OptInQuestionModePipe implements PipeTransform {

  transform(value: unknown): any {
    if (value === OptInQuestionMode.Disable) { return "Disable"; }
    if (value === OptInQuestionMode.Enable) { return "Enable"; }
    if (value === OptInQuestionMode.CustomWithMessage) { return "Custom Message"; }
    return value;
  }

}
