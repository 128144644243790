<accordion-group
  *ngIf="
    siteManagementMode == SiteManagementModeEnum.Site &&
    ((userService.isCompanyAdminUser() &&
      userService.hasCompanyFeature(ProductPlanFeature.VisitorTypes)) ||
      (userService.isCompanyAdminUser() &&
        manageSiteModel?.visitorTypeAccompaniers?.length &&
        userService.hasCompanyFeature(ProductPlanFeature.VisitorTypes)))
  "
>
  <div accordion-heading>
    <obc-accordion-header
      [isAccordionDirty]="
        dirtyComponents?.visitorTypeAccompaniers?.isDirty ||
        dirtyComponents.visitorTypes?.isDirty
      "
      [mainTitle]="'Visitor Types'"
      [icon]="'fa fa-user'"
      [iconColor]="'#0097A7'"
      [info]="visitorTypesHeaderInfo"
    >
    </obc-accordion-header>
  </div>
  <div>
    <div
      class="row"
      *ngIf="
        userService.isCompanyAdminUser() &&
        userService.hasCompanyFeature(ProductPlanFeature.VisitorTypes)
      "
    >
      <div class="col-md-12 mb-4">
        <div class="row">
          <div class="col-md-12">
            <obc-manage-site-visitor-type
              [manageSiteViewModel]="manageSiteModel"
              (isVisitorTypesEnabled)="visitorTypesStatus = $event"
              (onchange)="onChangeVisitor($event)"
            >
            </obc-manage-site-visitor-type>
          </div>
        </div>
      </div>
    </div>

    <div
      class="row"
      *ngIf="
        userService.isCompanyAdminUser() &&
        manageSiteModel?.visitorTypeAccompaniers?.length &&
        userService.hasCompanyFeature(ProductPlanFeature.VisitorTypes)
      "
    >
      <div class="col-md-12 mb-4">
        <section>
          <obc-loader [show]="siteVisitorTypeAccompanierProcess"></obc-loader>
          <header>
            <h2 class="accordion-subtitle">Manage Visitor Accompanier Mode</h2>
          </header>
          <div>
            <div class="row">
              <div class="col-md-12 mt-2">
                <table class="table table-striped mb-0">
                  <thead>
                    <tr>
                      <th>Visitor Type</th>
                      <th>Use Site Accompany Setting</th>
                      <th>Visitor Accompanier Mode</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      *ngFor="
                        let siteVisitorAccompanier of manageSiteModel?.visitorTypeAccompaniers
                      "
                    >
                      <td>
                        {{ siteVisitorAccompanier.visitorTypeTitle }}
                      </td>
                      <td>
                        <obc-switch
                          falseLabel="No"
                          trueLabel="Yes"
                          [value]="
                            siteVisitorAccompanier.accompanierMode != null
                          "
                          (switchChanged)="
                            toggleDefaultSiteMode(siteVisitorAccompanier)
                          "
                        >
                        </obc-switch>
                      </td>
                      <td style="text-align: center">
                        <select
                          *ngIf="siteVisitorAccompanier.accompanierMode != null"
                          class="custom-select"
                          name="accompanierMode"
                          [ngModel]="siteVisitorAccompanier.accompanierMode"
                          (change)="
                            onsiteVisitorAccompanierChange(
                              siteVisitorAccompanier,
                              $event.target
                            )
                          "
                        >
                          <option
                            *ngFor="let index of accompanierMode | enumToArray"
                            value="{{ accompanierMode[index] }}"
                          >
                            {{ accompanierMode[index] | accompanierMode }}
                          </option>
                        </select>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</accordion-group>
<accordion-group
  *ngIf="
    siteManagementMode == SiteManagementModeEnum.Site &&
    userService.hasCompanyFeature(ProductPlanFeature.RequiredDocumentField)
  "
>
  <div accordion-heading>
    <obc-accordion-header
      [isAccordionDirty]="
        dirtyComponents?.documentInfo?.isDirty ||
        dirtyComponents?.requiredFields?.isDirty
      "
      [mainTitle]="'Visitor Information'"
      [icon]="'fa fa-info'"
      [iconColor]="'#346a1a'"
      [info]="documentExtraInfoHeaderInfo"
    ></obc-accordion-header>
  </div>
  <div>
    <div class="d-flex justify-content-end align-items-center mb-3">
      <a
        (click)="onShowRequiredDocumentsModal(requiredDocumentsModal)"
        class="btn btn-sm btn-info text-decoration-none text-white"
      >
        <i class="fa fa-plus"></i>
        Add Documents
      </a>
    </div>
    <div class="">
      <div class="row">
        <div class="col-md-12">
          <table class="table border mb-0 table-scrollable">
            <thead>
              <tr>
                <th class="col-6">
                  Documents ({{
                    manageSiteModel?.documentInfo?.requiredDocuments?.length
                  }})
                </th>
                <th class="col-4">State</th>
                <th class="col-2 text-right">Remove</th>
              </tr>
            </thead>
            <tbody style="height: auto !important">
              <tr
                *ngIf="
                  !(
                    manageSiteModel?.documentInfo?.requiredDocuments &&
                    manageSiteModel?.documentInfo?.requiredDocuments.length
                  )
                "
              >
                <td
                  style="width: 100%; float: none; border-top: none"
                  colspan="3"
                >
                  <p class="text-center mb-0">Please Select Documents</p>
                </td>
              </tr>
              <ng-container
                *ngFor="
                  let doc of manageSiteModel?.documentInfo?.requiredDocuments
                "
              >
                <tr class="no-bottom-border">
                  <td class="col-6">{{ doc.userDocumentTypeTitle }}</td>
                  <td
                    class="col-4"
                    style="padding: 0; position: relative; top: 6px"
                  >
                    <select
                      class="custom-select"
                      [(ngModel)]="doc.state"
                      (change)="onRequiredDocumentChange()"
                    >
                      <option [ngValue]="0">Optional</option>
                      <option [ngValue]="1">Mandatory</option>
                    </select>
                  </td>
                  <td class="col-2">
                    <button
                      type="button"
                      (click)="removeRequiredDocument(doc)"
                      [disabled]="requiredDocumentProcess"
                      class="btn btn-warning btn-xs pull-right"
                      title="Remove from documents"
                    >
                      <i class="fas fa-times"></i>
                    </button>
                  </td>
                </tr>
                <tr
                  class="no-top-border"
                  *ngIf="
                    userService.isCompanyAdminUser() &&
                    userService.hasCompanyFeature(
                      ProductPlanFeature.VisitorTypes
                    ) &&
                    manageSiteModel?.siteVisitorTypes?.length > 0
                  "
                >
                  <td colspan="3" class="col-12">
                    <div class="col-md-12">
                      <obc-manage-site-question-visitor-type
                        [manageSiteViewModel]="manageSiteModel"
                        [questionVisitorTypeIds]="doc?.visitorTypeIds"
                        [mode]="ManageSiteQuestionVisitorTypeMode.Document"
                        [siteEntityId]="doc.id"
                        (onChange)="documentVisitorTypeClicked(doc, $event)"
                      >
                      </obc-manage-site-question-visitor-type>
                    </div>
                  </td>
                </tr>
              </ng-container>
            </tbody>
            <tfoot>
              <tr>
                <td colspan="3" class="col-12">
                  <div class="d-flex align-items-center">
                    <label class="mr-2 mb-0"
                      >Allow users to attach additional documents</label
                    >
                    <obc-switch
                      [trueLabel]="' Enabled '"
                      [falseLabel]="' Disabled '"
                      [value]="
                        manageSiteModel?.documentInfo
                          ?.allowUsersToAttachAdditionalDocuments
                      "
                      (switchChanged)="toggleAttachAdditionalDocuments()"
                    >
                    </obc-switch>
                  </div>
                </td>
              </tr>
            </tfoot>
          </table>
        </div>
        <div class="col-md-12 mt-2" *ngIf="manageSiteModel?.requiredFields">
          <table class="table table-striped mb-0">
            <thead>
              <tr>
                <th>Extra Information</th>
                <th style="text-align: center">State</th>
              </tr>
            </thead>
            <tbody>
              <ng-container
                *ngFor="let field of manageSiteModel?.requiredFields?.fields"
              >
                <tr class="no-bottom-border">
                  <td>{{ field.title }}</td>
                  <td style="text-align: center">
                    <select
                      class="custom-select"
                      [(ngModel)]="field.state"
                      (change)="onRequiredFieldChange()"
                    >
                      <ng-container
                        *ngFor="
                          let fieldState of manageSiteModel?.requiredFields
                            ?.states
                        "
                      >
                        <option
                          *ngIf="fieldState.state != 3"
                          [ngValue]="fieldState.state"
                        >
                          {{
                            field.fieldKey == "Supplier" &&
                            fieldState.state == 2
                              ? "Mandatory (All CompanySuppliers)"
                              : fieldState.title
                          }}
                        </option>
                        <option
                          *ngIf="
                            field.fieldKey == 'Business' &&
                            fieldState.state == 3
                          "
                          [ngValue]="fieldState.state"
                        >
                          Mandatory (Validate ABN/ACN)
                        </option>
                        <option
                          *ngIf="
                            field.fieldKey == 'Supplier' &&
                            fieldState.state == 3
                          "
                          [ngValue]="fieldState.state"
                        >
                          Mandatory (Site Suppliers Only)
                        </option>
                      </ng-container>
                    </select>
                  </td>
                </tr>
                <tr *ngIf="field.state != 0" class="no-top-border">
                  <td colspan="2">
                    <div
                      class="col-md-12"
                      *ngIf="isAllowedToManageVisitorTypes"
                    >
                      <obc-manage-site-question-visitor-type
                        [questionVisitorTypeIds]="field?.visitorTypeIds"
                        [manageSiteViewModel]="manageSiteModel"
                        [mode]="ManageSiteQuestionVisitorTypeMode.RequiredField"
                        [siteEntityId]="field.id"
                        (onChange)="fieldVisitorTypeClicked(field, $event)"
                      >
                      </obc-manage-site-question-visitor-type>
                    </div>
                  </td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</accordion-group>
<accordion-group
  *ngIf="
    (siteManagementMode == SiteManagementModeEnum.Site &&
      userService.hasUserPermissionForCompany(
        UserPermission.ManageSiteQuestion,
        siteId
      )) ||
    (siteManagementMode == SiteManagementModeEnum.Supplier &&
      manageSiteModel?.supplierSite?.enableSiteQuestionManage)
  "
>
  <div accordion-heading>
    <obc-accordion-header
      [isAccordionDirty]="dirtyComponents?.checkInQuestions?.isDirty"
      [mainTitle]="'Check-In Questions'"
      [icon]="'fa fa-sign-in-alt'"
      [iconColor]="'#888a76'"
      [info]="checkInQuestionHeaderInfo"
    >
    </obc-accordion-header>
  </div>
  <div>
    <div class="d-flex justify-content-end mb-3">
      <a
        (click)="onShowCheckinQuestionsModal(checkinQuestionsModal)"
        class="btn btn-sm btn-info text-decoration-none text-white"
      >
        <i class="fa fa-plus"></i>
        Add Check-In Questions
      </a>
    </div>
    <obc-loader [show]="checkinQuestionProgress"></obc-loader>
    <div class="">
      <div class="row">
        <div class="col-md-12">
          <table class="table table-responsive-md table-striped mb-0">
            <thead>
              <tr>
                <th>Question</th>
                <th><span class="pull-right">Remove</span></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  *ngIf="
                    !siteCheckInQuestions || siteCheckInQuestions.length == 0
                  "
                  style="width: 100%; float: none; border-top: none"
                  colspan="3"
                >
                  <p class="text-center mb-0">
                    Please Select Check-In Questions
                  </p>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <div
                    *ngIf="
                      siteCheckInQuestions && siteCheckInQuestions.length > 0
                    "
                    class="mb-2 alert alert-info d-flex align-items-center"
                  >
                    <i class="fas fa-info mr-2"></i>
                    <span
                      >You can change the order of items by dragging them</span
                    >
                  </div>
                  <ng-container *ngIf="showObcSortable">
                    <obc-sortable
                      [(ngModel)]="siteCheckInQuestions"
                      [template]="draggableItemTemplate"
                      (onChangeOrder)="onChangeOrder(true)"
                    ></obc-sortable>
                  </ng-container>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</accordion-group>
<accordion-group
  *ngIf="
    (siteManagementMode == SiteManagementModeEnum.Site &&
      userService.hasUserPermissionForCompany(
        UserPermission.ManageSiteQuestion,
        siteId
      )) ||
    (siteManagementMode == SiteManagementModeEnum.Supplier &&
      manageSiteModel?.supplierSite?.enableSiteQuestionManage)
  "
>
  <div accordion-heading>
    <obc-accordion-header
      [isAccordionDirty]="dirtyComponents?.checkOutQuestions?.isDirty"
      [mainTitle]="'Check-Out Questions'"
      [icon]="'fa fa-sign-out-alt'"
      [iconColor]="'#987573'"
      [info]="checkOutQuestionHeaderInfo"
    ></obc-accordion-header>
  </div>
  <div>
    <div class="d-flex justify-content-end mb-3">
      <a
        (click)="onShowCheckoutQuestionsModal(checkoutQuestionsModal)"
        class="btn btn-sm btn-info text-decoration-none text-white"
      >
        <i class="fa fa-plus"></i>
        Add Check-Out Questions
      </a>
    </div>
    <obc-loader [show]="checkoutQuestionProgress"></obc-loader>
    <div class="">
      <div class="row">
        <div class="col-md-12">
          <table class="table table-responsive-md table-striped mb-0">
            <thead>
              <tr>
                <th>Question</th>
                <th><span class="pull-right">Remove</span></th>
              </tr>
            </thead>
            <tbody>
              <tr
                *ngIf="
                  !siteCheckOutQuestions || siteCheckOutQuestions.length == 0
                "
              >
                <td
                  style="width: 100%; float: none; border-top: none"
                  colspan="3"
                >
                  <p class="text-center mb-0">
                    Please Select Check-Out Questions
                  </p>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <div
                    *ngIf="
                      siteCheckOutQuestions && siteCheckOutQuestions.length > 0
                    "
                    class="mb-2 alert alert-info d-flex align-items-center"
                  >
                    <i class="fas fa-info mr-2"></i>
                    <span
                      >You can change the order of items by dragging them</span
                    >
                  </div>
                  <ng-container *ngIf="showObcSortable">
                    <obc-sortable
                      [(ngModel)]="siteCheckOutQuestions"
                      [template]="draggableItemTemplate"
                      (onChangeOrder)="onChangeOrder(false)"
                    ></obc-sortable>
                  </ng-container>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</accordion-group>
<obc-site-announcement-manage
  *ngIf="
    (siteManagementMode == SiteManagementModeEnum.Site &&
      userService.hasUserPermissionForCompany(
        UserPermission.ManageSiteAnnouncement,
        siteId
      )) ||
    (siteManagementMode == SiteManagementModeEnum.Supplier &&
      manageSiteModel?.supplierSite?.enableSiteAnnouncementManage)
  "
  [siteManagementMode]="siteManagementMode"
  [dirtyComponents]="dirtyComponents"
  [manageSiteViewModel]="manageSiteModel"
>
</obc-site-announcement-manage>
<app-site-induction
  [dirtyComponents]="dirtyComponents"
  [manageSiteViewModel]="manageSiteModel"
  *ngIf="
    (siteManagementMode == SiteManagementModeEnum.Site &&
      userService.isCompanyAdminUser()) ||
    (siteManagementMode == SiteManagementModeEnum.Supplier &&
      manageSiteModel?.supplierSite?.enableSiteInductionManage)
  "
  [noCard]="true"
  [siteId]="siteId"
></app-site-induction>
<app-site-form
  [formType]="FormType.Generic"
  [dirtyComponents]="dirtyComponents"
  [manageSiteViewModel]="manageSiteModel"
  *ngIf="
    (siteManagementMode == SiteManagementModeEnum.Site &&
      userService.isCompanyAdminUser() &&
      userService.hasCompanyFeature(ProductPlanFeature.Form)) ||
    (siteManagementMode == SiteManagementModeEnum.Supplier &&
      manageSiteModel?.supplierSite?.enableSiteFormsManage)
  "
  [noCard]="true"
  [siteId]="siteId"
></app-site-form>
<app-site-form
  [formType]="FormType.Permit"
  [dirtyComponents]="dirtyComponents"
  [manageSiteViewModel]="manageSiteModel"
  *ngIf="
    siteManagementMode == SiteManagementModeEnum.Site &&
    userService.isCompanyAdminUser() &&
    userService.hasCompanyFeature(ProductPlanFeature.Form)
  "
  [noCard]="true"
  [siteId]="siteId"
></app-site-form>

<app-site-form
  [formType]="FormType.AssetRegistration"
  [dirtyComponents]="dirtyComponents"
  [manageSiteViewModel]="manageSiteModel"
  *ngIf="
    siteManagementMode == SiteManagementModeEnum.Site &&
    userService.isCompanyAdminUser() &&
    userService.hasCompanyFeature(ProductPlanFeature.Form)
  "
  [noCard]="true"
  [siteId]="siteId"
></app-site-form>

<app-site-form
  [formType]="FormType.SiteBriefing"
  [dirtyComponents]="dirtyComponents"
  [manageSiteViewModel]="manageSiteModel"
  *ngIf="
      siteManagementMode == SiteManagementModeEnum.Site && 
      userService.isCompanyAdminUser() && 
      userService.hasCompanyFeature(ProductPlanFeature.Form) && 
      userService.hasCompanyFeature(ProductPlanFeature.SiteBriefing)"
  [noCard]="true"
  [siteId]="siteId">
</app-site-form>


<obc-site-scannable-group
  *ngIf="siteManagementMode == SiteManagementModeEnum.Site"
  [dirtyComponents]="dirtyComponents"
  [manageSiteViewModel]="manageSiteModel"
  [noCard]="true"
>
</obc-site-scannable-group>

<ng-template #requiredDocumentsModal>
  <div class="card scrollable-list">
    <div class="card-header">
      Select Site Documents
      <button
        type="button"
        class="close"
        (click)="bsRequiredDocumentsModalRef.hide()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-12">
          <table
            class="table table-striped border mb-0 table-scrollable obc-table"
          >
            <thead>
              <tr>
                <th class="col-6">
                  All Documents ({{ allDocuments?.length }})
                </th>
                <th class="col-4">State</th>
                <th class="col-2 text-center">Add</th>
              </tr>
              <tr>
                <td class="col-12" colspan="2">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Search in documents"
                    [(ngModel)]="filterDocumentsTerm"
                  />
                  <!-- <obc-filter-data-list [reset]="requiredDocumentProcess" [originalData]="allUserDocumentTypes"
                    [(dataList)]="allDocuments" [placeHolder]="'Search in documents'">
                  </obc-filter-data-list> -->
                </td>
              </tr>
            </thead>
            <tbody style="height: 250px">
              <tr
                *ngIf="
                  !allUserDocumentTypes ||
                  allUserDocumentTypes.length == 0
                "
              >
                <td class="col-12 text-center">
                  <p>there is no documents to add</p>
                </td>
              </tr>
              <tr
                *ngFor="
                  let doc of allUserDocumentTypes
                    | filter: filterDocumentsTerm:['title']
                "
              >
                <td class="col-6">{{ doc.title }}</td>
                <td
                  class="col-4"
                  style="padding: 0; position: relative; top: 6px"
                >
                  <select class="custom-select" [(ngModel)]="doc.state">
                    <option [ngValue]="0">Optional</option>
                    <option [ngValue]="1">Mandatory</option>
                  </select>
                </td>
                <td class="col-2 text-center">
                  <button
                    type="button"
                    (click)="addRequiredDocument(doc)"
                    [disabled]="requiredDocumentProcess"
                    class="btn btn-info btn-xs pull-right"
                    title="Add to documents"
                  >
                    <i class="fas fa-plus"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #checkinQuestionsModal>
  <div class="card">
    <div class="card-header">
      Select Check-In Question(s)
      <button
        type="button"
        class="close"
        (click)="bsCheckinQuestionsModalRef.hide()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-12">
          <obc-loader [show]="progress"></obc-loader>
          <table class="table table-responsive-md table-striped mb-0 obc-table">
            <thead>
              <tr>
                <th>
                  Question
                  <!-- <a target="_blank" class="pointer" role="menuitem" tabindex="-1" [routerLink]="['/question']">
                    <small>(Add New Question)</small>
                  </a> -->
                </th>
                <th>
                  <span
                    class="pull-right btn btn-primary"
                    (click)="onAddQuestionClick(questionsTemplateCheckIn)"
                    >Add</span
                  >
                </th>
              </tr>
              <tr>
                <td colspan="2">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Search in questions"
                    [(ngModel)]="filterCheckinQuestionTerm"
                  />
                </td>
              </tr>
            </thead>
          </table>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 scrollable-list" style="max-height: 400px">
          <table class="table table-responsive-md table-striped mb-0 obc-table">
            <tbody>
              <tr
                *ngIf="
                  !companyCheckinQuestions ||
                  companyCheckinQuestions.length == 0
                "
              >
                <td
                  colspan="2"
                  style="width: 100%; float: none; border-top: none"
                >
                  <p class="text-center mb-0">there is no question to add</p>
                </td>
              </tr>
              <tr
                *ngFor="
                  let queze of companyCheckinQuestions
                    | filter: filterCheckinQuestionTerm:['questionText']
                "
              >
                <td>
                  <obc-question-extended-title [question]="queze">
                  </obc-question-extended-title>
                </td>
                <td>
                  <button
                    type="button"
                    class="pull-right btn btn-xs btn-info"
                    *ngIf="!progress"
                    (click)="addQuestionToSite(queze.id, true)"
                  >
                    <i class="fas fa-plus"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #checkoutQuestionsModal>
  <div class="card">
    <div class="card-header">
      Select Check-Out Question(s)
      <button
        type="button"
        class="close"
        (click)="bsCheckoutQuestionsModalRef.hide()"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="card-body">
      <div class="row">
        <div class="col-md-12">
          <obc-loader [show]="progress"></obc-loader>
          <table class="table table-responsive-md table-striped mb-0 obc-table">
            <thead>
              <tr>
                <th>
                  Question
                  <!-- <a
                    target="_blank"
                    class="pointer"
                    role="menuitem"
                    tabindex="-1"
                    [routerLink]="['/question']"
                  >
                    <small>(Add New Question)</small>
                  </a> -->
                </th>
                <th>
                  <span
                    class="pull-right btn btn-primary"
                    (click)="onAddQuestionClick(questionsTemplateCheckOut)"
                    >Add</span
                  >
                </th>
              </tr>
              <tr>
                <td colspan="2">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Search in questions"
                    [(ngModel)]="filterCheckoutQuestionTerm"
                  />
                </td>
              </tr>
            </thead>
          </table>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 scrollable-list" style="max-height: 400px">
          <table class="table table-responsive-md table-striped mb-0 obc-table">
            <tbody>
              <tr
                *ngIf="
                  !companyCheckoutQuestions ||
                  companyCheckoutQuestions.length == 0
                "
              >
                <td
                  colspan="2"
                  style="width: 100%; float: none; border-top: none"
                >
                  <p class="text-center mb-0">there is no question to add</p>
                </td>
              </tr>
              <tr
                *ngFor="
                  let queze of companyCheckoutQuestions
                    | filter: filterCheckoutQuestionTerm:['questionText']
                "
              >
                <td>
                  <obc-question-extended-title [question]="queze">
                  </obc-question-extended-title>
                </td>
                <td>
                  <button
                    type="button"
                    class="pull-right btn btn-xs btn-info"
                    *ngIf="!progress"
                    (click)="addQuestionToSite(queze.id, false)"
                  >
                    <i class="fas fa-plus"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #questionsTemplateCheckIn>
  <obc-question-add
    (result)="onResultQuestionAdd($event, 'checkIn')"
    [withResult]="true"
  ></obc-question-add>
</ng-template>

<ng-template #questionsTemplateCheckOut>
  <obc-question-add
    (result)="onResultQuestionAdd($event, 'checkOut')"
    [withResult]="true"
  ></obc-question-add>
</ng-template>

<ng-template
  #draggableItemTemplate
  let-item="item"
  let-index="index"
  let-questionType="questionType"
>
  <div class="row pt-2" [ngClass]="index == 0 ? '' : 'border-top'">
    <div class="col-md-12 d-flex justify-content-between align-items-start">
      <obc-question-extended-title
        [question]="getQuestionViewModel(item.value.questionId)"
        class="flex-fill"
      >
      </obc-question-extended-title>

      <button
        type="button"
        (click)="
          removeFromSiteQuestion(
            item.value.questionId,
            item.value.isCheckInQuestion
          )
        "
        class="pull-right btn btn-xs btn-warning"
      >
        <i class="fas fa-times"></i>
      </button>
    </div>
    <div
      class="col-md-12"
      *ngIf="
        userService.isCompanyAdminUser() &&
        userService.hasCompanyFeature(ProductPlanFeature.VisitorTypes)
      "
    >
      <obc-manage-site-question-visitor-type
        [manageSiteViewModel]="manageSiteModel"
        [questionVisitorTypeIds]="item?.value?.visitorTypeIds"
        [mode]="ManageSiteQuestionVisitorTypeMode.Question"
        [siteEntityId]="item.value.id"
        (onChange)="
          questionVisitorTypeClicked(
            item?.value,
            $event,
            item.value.isCheckInQuestion
          )
        "
      >
      </obc-manage-site-question-visitor-type>
    </div>
  </div>
</ng-template>
