export enum FieldValueType {
  Text = 0,
  Numeric = 1,
}

export enum FieldPermission {
  Disabled = 0,
  ViewOnly = 1,
  ViewAndEdit = 2,
}

export interface AttendanceFieldModel {
  title: string;
  fieldKey: string;
  type: FieldValueType;
  userPermission: FieldPermission;
  hostPermission: FieldPermission;
}

export interface AttendanceFieldWithValue extends AttendanceFieldModel {
  value: string;
}

export interface AttendanceFieldViewModel {
  fieldKey: string;
  value: string;
}
