import { Component, OnInit, ViewChild } from '@angular/core';
import { isDateValid } from "../../../helpers/general-functions";
import { BaseSiteViewModel } from "../../../models/site-viewmodel";
import { FormControl, FormGroup } from "@angular/forms";
import { LayoutService } from "../../../services/layout.service";
import { Router } from "@angular/router";
import { ModalService } from "../../../services/modal.service";
import { finalize } from "rxjs/operators";
import { ApproveService } from "../../../services/approve-service";
import { SiteActiveStatus } from 'src/app/enums/site-active-status.enum';
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { ISiteSupplierDocumentTypeVersion } from "../../../models/supplier-document/site-supplier-document-type";
import { QuestionAnswerInputModel } from "../../../models/question-answer-inputmodel";
import { UserService } from 'src/app/services/user.service';
import { UserPermission } from 'src/app/enums/user-permission.enum';
import {
  BasePendingToApproveViewModel,
  PendingToApproveViewModel
} from 'src/app/models/approve/pending-to-approve-view-model';
import { ApproveRejectQueryModel } from 'src/app/models/approve/approve-reject-query-model';
import { DatetimePickerMode } from 'src/app/enums/datetime-picker-mode.enum';
import { ApproveEntityType } from 'src/app/enums/approve-entity-type';
import { PendingToApproveStatus } from 'src/app/enums/approve-status';
import { SiteAssetRequestApproveStatus } from 'src/app/enums/site-asset-request-approve-status.enum';
import { ToastrService } from 'ngx-toastr';
import { ApproveEntityTypePipe } from 'src/app/pipes/approve-entity-type.pipe';
import { SupplierDocumentReviewMode } from 'src/app/enums/supplier-document-review-mode.enum';
import { GridColumn, GridConfig, GridTemplate, InitialFilter } from 'src/app/models/grid/grid.config';
import { Table } from 'src/app/enums/table.enum';
import { FilterType } from 'src/app/enums/filter-type.enum';
import { DatetimePipe } from 'src/app/pipes/datetime.pipe';
import { DatePipe } from 'src/app/pipes/date.pipe';
import { SupplierDocumentWorkflowConstant } from 'src/app/enums/workflow/workflow-activities-enum';
import { ShowSupplierDocumentReviewButton } from 'src/app/enums/show-supplier-document-review-button';
import { ObcWorkflowStatus } from 'src/app/enums/workflow/workflow-status.enum';
import { PortalDisplayMode } from 'src/app/enums/portal-display-mode.enum';
import { DefaultWorkflowDisplayMode } from 'src/app/enums/constants';
import { FormService } from 'src/app/services/form.service';
import { InductionService } from "../../../services/induction-service";
import { BasePagingModel } from "../../../models/paging_model";
import { SiteInductionRejectStatus } from "../../../enums/site-induction-reject-status";

@Component({
  selector: 'obc-pending-to-approve-list',
  templateUrl: './pending-to-approve-list.component.html',
  styleUrls: ['./pending-to-approve-list.component.scss']
})
export class PendingToApproveListComponent implements OnInit {
  getInductionActionButtonText(item: PendingToApproveViewModel) {
    return (item.status == PendingToApproveStatus.Pending ? 'Review' :
      item.status == PendingToApproveStatus.Approved
        && (!item.expirationDate || new Date(item.expirationDate) > new Date(Date.now()))
        ? 'Request Revision' : 'View Details');
  }
  @ViewChild('grid') grid: any;
  //base-grid filters
  gridColumns: GridColumn<BasePendingToApproveViewModel>[];
  gridConfig: GridConfig = {} as GridConfig;
  initialFilters: InitialFilter[] = [];

  SupplierDocumentReviewModeEnum = SupplierDocumentReviewMode
  selectedSite: BaseSiteViewModel;
  currentSiteTitle: string;
  inProgress = false;
  pendingList: PendingToApproveViewModel[] = [];
  serverResult: BasePendingToApproveViewModel;
  ApproveEntityType = ApproveEntityType;
  searchQueryForm: FormGroup = null;
  SiteActiveStatus = SiteActiveStatus;
  selectedEntityType: ApproveEntityType = null;
  selectedEntityTypeForReviewModal: ApproveEntityType = null;
  selectedEntityEndDate: Date = null;
  selectedEntityId: number;
  userPermissions = UserPermission;
  selectedFormDataId: number;
  formModalRef: BsModalRef;

  selectedPendingToApproveStatus: PendingToApproveStatus = PendingToApproveStatus.Pending;
  PendingToApproveStatus = PendingToApproveStatus;
  SiteAssetRequestApproveStatus = SiteAssetRequestApproveStatus;

  DatetimePickerMode = DatetimePickerMode;
  startDate: FormControl = new FormControl(null);
  endDate: FormControl = new FormControl(null);
  searchTerm: FormControl = new FormControl(null);

  endMinAvailableDate: Date;
  startMaxAvailableDate: Date;
  selectedAssetId: number;
  selectedAssetOperatorId: number;
  selectedItemIsEnableFormExpiryDate: boolean;

  fromDetailArgs: any = {}

  WorkflowStatus = ObcWorkflowStatus;
  SupplierDocumentWorkflowConstant = SupplierDocumentWorkflowConstant;
  ShowSupplierDocumentReviewButton = ShowSupplierDocumentReviewButton;

  portalDisplayMode: PortalDisplayMode = DefaultWorkflowDisplayMode;
  selectedInstanceId: string;
  includeInactive: boolean = false;

  dirtyCheckSubscribe;
  selectedFormDirtyState: boolean = false;

  expandedItems: { [key: number]: boolean } = {};

  constructor(private layoutService: LayoutService,
    public approveService: ApproveService,
    private modalService: ModalService,
    private bsModalService: BsModalService,
    public userService: UserService,
    private toastr: ToastrService,
    private approveEntityTypePipe: ApproveEntityTypePipe,
    private datetimePipe: DatetimePipe,
    private datePipe: DatePipe,
    private formService: FormService,
    private inductionService: InductionService,
    private router: Router) {
  }

  getPath(): string {
    return window.location.pathname + window.location.hash;
  }

  setCurrentSite(site: BaseSiteViewModel) {
    this.selectedSite = site;
    this.currentSiteTitle = site?.name;
    this.fetchPendingList();
  }

  ngOnInit() {
    this.portalDisplayMode = this.userService.getCompanyWorkflowDisplayMode();
    this.layoutService.header = "Approvals";
    this.prepareGridConfig();

    this.inductionService.inductionHistoryLog({
      siteInductionId: 4972,
      pagingInfo: {
        pageNumber: 0,
        pageSize: 10
      } as BasePagingModel
    }).subscribe((res) => {
      console.log(res)
    })

  }

  prepareGridConfig() {
    let me = this;
    this.initialFilters = [{
      key: "status",
      value: [PendingToApproveStatus.Pending],
      displayInFilterRenderer: true
    },
    {
      key: "includeInactive",
      value: this.includeInactive,
      displayInFilterRenderer: true
    }];
    let apiUrl = '/api/approve/approve-list';
    this.gridConfig = new GridConfig({
      csvReportUrl: '/api/approve/approve-list-csv-as-job',
      apiUrl: apiUrl,
      scheduleReport: null,
      tableType: Table.Approval,
      displayGeneralSearch: true,
      refreshButtonValidity: true,
      generalSearchPlaceholder: "Filter Result by First Name, Last Name, Mobile, Title, ...",
      initialFilters: this.initialFilters,
      apiResultCallback: (res) => {
        this.serverResult = res;
        return {
          data: res.pendingToApproveList,
          totalCount: res.total,
        };
      }
    });

    this.gridColumns = [
      {
        key: "entityDateTime",
        name: "Date Submitted",
        type: FilterType.DateTimeRange,
        enableSort: true,
        enableFilter: true,
        propertyNameInQuery: 'approveDateRange',
        visible: true,
        isArray: false,
      },
      {
        key: "status",
        type: FilterType.ApprovalStatus,
        name: "Status",
        gridTemplate: new GridTemplate().ComponentTemplateName('pendingStatus'),
        propertyNameInQuery: 'approveStatuses',
        visible: true,
      },
      {
        key: "approveEntityType",
        type: FilterType.ApproveEntityType,
        name: "Type",
        gridTemplate: new GridTemplate().ComponentTemplateName('entityType'),
        propertyNameInQuery: 'approveEntityTypes',
        visible: true,
      },
      {
        key: "title",
        name: "Title",
        type: FilterType.SingleText,
        gridTemplate: new GridTemplate().ComponentTemplateName('title')
          .CsvFields([
            'title',
            'subtitle',
            'userMobile',
          ]),
        propertyNameInQuery: 'titles',
        enableSort: false,
        visible: true
      },
      {
        key: "siteName",
        name: "Site",
        type: FilterType.Site,
        propertyNameInQuery: 'siteIds',
        visible: true,
      },
      {
        key: "userFullName",
        name: "Full Name",
        type: FilterType.SingleText,
        propertyNameInQuery: 'userFullNames',
        enableSort: false,
        visible: true,
      },
      {
        key: "supplierName",
        name: "Supplier",
        type: FilterType.Supplier,
        propertyNameInQuery: 'supplierIds',
        enableSort: false,
        visible: true,
      },
      {
        key: "approvalDateTime",
        name: "Approval Time",
        type: FilterType.DateTimeRange,
        propertyNameInQuery: 'approvalDateTimeRange',
        enableSort: true,
        enableFilter: true,
        gridTemplate: new GridTemplate().ComponentTemplateName('approvalDateTime').CsvFields([
          'approvalDateTime',
          'approvalStatusUserFullName'
        ]),
        visible: true,
        isArray: false,
        hideEmptyColumnsEmptinessConditionCallback(res: PendingToApproveViewModel[]) {
          return res?.every(item => {
            return item.status == PendingToApproveStatus.Pending;
          });
        },
      },
      {
        key: "dateTime",
        name: "Start/End Date",
        type: FilterType.DateTimeRange,
        propertyNameInQuery: 'dateTimeRange',
        gridTemplate: new GridTemplate().ComponentTemplateName('dateTime').CsvFields([
          'dateTime',
          'endDateTime'
        ]),
        enableSort: false,
        visible: true,
        isArray: false,
        hideEmptyColumnsEmptinessConditionCallback(res: PendingToApproveViewModel[]) {
          return res?.every(item => {
            let show = !me.getStartDate(item).hasDate && !me.getEndDate(item).hasDate;
            return show;
          });
        },
      },
      {
        key: "description",
        name: "Review Comment",
        gridTemplate: new GridTemplate().ComponentTemplateName('description'),
        enableFilter: false,
        enableSort: false,
        visible: true,
        isArray: false,
        hideEmptyColumnsEmptinessConditionCallback(res: PendingToApproveViewModel[]) {
          return res?.every(item => {
            return !item.description;
          });
        },
      },
      {
        key: "actions",
        name: "Actions",
        gridTemplate: new GridTemplate().ComponentTemplateName('actions'),
        enableFilter: false,
        enableSort: false,
        visible: true,
      },
    ];
  }

  isTextOverflow(element: HTMLParagraphElement): boolean {
    return element.scrollHeight > element.clientHeight;
  }

  toggleExpand(item: PendingToApproveViewModel) {
    this.expandedItems[item.entityId] = !this.expandedItems[item.entityId];
  }

  fetchPendingList() {
    this.grid?.loadData();
  }

  approveReject(item: PendingToApproveViewModel, isApprove: boolean) {
    this.inProgress = true;

    const model = {
      approveEntityType: item.approveEntityType,
      entityId: item.entityId,
      isApprove: isApprove,
    } as ApproveRejectQueryModel;

    this.approveService.approveRejectEntity(model)
      .pipe(finalize(() => {
        this.inProgress = false
      }))
      .subscribe(res => {
        if (res == true) {
          if (isApprove == true)
            this.toastr.success('Approved Successfully.');
          else
            this.toastr.success('Rejected Successfully.');
          this.fetchPendingList();
        } else
          this.modalService.error(`${isApprove == true ? 'Approve' : 'Reject'} Failed.`);
      }, err => {
        this.modalService.error(err)
      })
  }

  selectedSiteSupplierDocument: ISiteSupplierDocumentTypeVersion;
  bsModalRef: BsModalRef;

  onOpenFormReview(template: any, item: PendingToApproveViewModel) {
    this.selectedSiteSupplierDocument = {
      files: item.files,
      displayVersion: item.description,
      lastSupplierVersionId: item.entityId,
      supplierDocumentTypeName: item.title,
      documentKindId: item.documentKindId,
      expirationDate: item.expirationDate,
    } as ISiteSupplierDocumentTypeVersion;

    this.formService.isFormDirty.next(false);
    this.formModalRef = this.bsModalService.show(template, {
      class: "modal-lg modal-full-width",
      ignoreBackdropClick: true,
      keyboard: false,
    });
    this.dirtyCheckSubscribe = this.formService.isFormDirty.asObservable().subscribe(e => {
      this.selectedFormDirtyState = e;
    });
  }


  questionAnswerInputModel: QuestionAnswerInputModel;

  onOpenDetail(template: any, item: PendingToApproveViewModel) {
    this.questionAnswerInputModel = {
      siteAttendanceId: item.attendanceId,
      isCheckInAnswer: item.approveEntityType == ApproveEntityType.Attendance
    } as QuestionAnswerInputModel;
    this.modalService.show(template, "modal-lg");
  }

  closeModal() {
    try {
      if (this.formModalRef) {
        if (!this.selectedFormDirtyState) {
          this.dirtyCheckSubscribe?.unsubscribe();
          this.formModalRef.hide();
          this.grid.loadData();
        } else {
          this.modalService.confirm('Exit and lose progress?', '', 'Yes, exit', 'No, go back and continue')
            .subscribe(res => {
              if (res == true) {
                this.dirtyCheckSubscribe?.next(false);
                this.dirtyCheckSubscribe?.unsubscribe();
                this.formModalRef.hide();
                this.grid.loadData();
              }
            });
        }
      }
    } catch {
    }

    try {
      this.modalService.hide();
      this.grid.loadData();
    } catch {
    }

    try {
      if (!this.selectedFormDirtyState) {
        this.dirtyCheckSubscribe?.unsubscribe();
        this.workflowEngineModal.hide();
      } else {
        this.modalService.confirm('Exit and lose progress?', '', 'Yes, exit', 'No, go back and continue')
          .subscribe(res => {
            if (res == true) {
              this.dirtyCheckSubscribe?.next(false);
              this.dirtyCheckSubscribe?.unsubscribe();
              this.workflowEngineModal.hide();
            }
          });
      }
    } catch {
    }
  }


  onOpenFormDetail(template: any, item: PendingToApproveViewModel) {
    if (item.approveEntityType == ApproveEntityType.Induction)
      this.selectedFormDataId = item.entityId;
    else {
      this.selectedFormDataId = item.relatedFormDataId;
      this.selectedEntityId = item.entityId;
    }
    this.modalService.show(template, "modal-lg")
  }

  onOpenFormDataDetail(template: any, formDataId: number) {
    this.selectedEntityId = formDataId;
    this.modalService.show(template, "modal-lg")
  }

  onOpenApproveRejectTemplate(template: any, item: PendingToApproveViewModel) {
    this.selectedEntityId = item.entityId;
    this.selectedEntityTypeForReviewModal = item.approveEntityType;
    this.selectedItemIsEnableFormExpiryDate = item.isEnableFormExpiryDate;
    this.selectedEntityEndDate = item.endDateTime;
    this.modalService.show(template, "modal-lg")
  }

  adjustDates() {
    if (isDateValid(this.startDate)) {
      this.endMinAvailableDate = this.startDate.value;
      this.endMinAvailableDate.setHours(0, 0, 0, 0);
    } else {
      this.endMinAvailableDate = null;
    }
    if (isDateValid(this.endDate)) {
      this.startMaxAvailableDate = this.endDate.value;
      this.startMaxAvailableDate.setHours(0, 0, 0, 0);
    } else {
      this.startMaxAvailableDate = null;
    }
  }

  showResetStatusButton(item: PendingToApproveViewModel): boolean {
    return item.approveEntityType != ApproveEntityType.SiteSupplierDocument &&
      (item.status == PendingToApproveStatus.Approved || item.status == PendingToApproveStatus.Rejected) &&
      (((item.siteId == null || item.siteId == 0) && this.userService.isCompanyAdminUser()) ||
        (item.siteId > 0 && this.userService.hasAdminPermissionOnSite(item.siteId)));
  }

  showRequestRevisionButton(item: PendingToApproveViewModel): boolean {
    return item.approveEntityType == ApproveEntityType.Induction &&
      (item.status != PendingToApproveStatus.Approved ||
        (item.status == PendingToApproveStatus.Approved &&
          (this.userService.isCompanyAdminUser() ||
            (item.siteId > 0 && this.userService.hasAdminPermissionOnSite(item.siteId)))));
  }

  onResetStatus(item: PendingToApproveViewModel, requestRevisionTemplate: any) {
    const entityTypeTitle = this.approveEntityTypePipe.transform(item.approveEntityType);
    if (item.approveEntityType == ApproveEntityType.Induction) {
      this.modalService.confirm('This feature is now deprecated and will be removed in the future. Please use "Request Revision" moving forward.', `Reset ${entityTypeTitle}`, 'Reset Status Anyway', 'Request Revision', 'btn-danger', 'btn-success', true)
        .subscribe(result => {
          if (result === false) {
            this.onDisplayRequestRevisionModal(requestRevisionTemplate, item);
          }
          else if (result === true) {
            this.onGeneralResetStatus(item);
          }
        });
    }
    else {
      this.onGeneralResetStatus(item);
    }
  }

  onGeneralResetStatus(item: PendingToApproveViewModel) {
    const entityTypeTitle = this.approveEntityTypePipe.transform(item.approveEntityType);
    this.modalService.confirm('Are you sure  you want to reset the status to pending?', `Reset ${entityTypeTitle}`).subscribe(result => {
      if (result === true) {
        this.inProgress = true;
        let subscription =
          this.approveService
            .resetEntityStatus({ entityId: item.entityId, approveEntityType: item.approveEntityType })
            .subscribe(res => {
              if (res)
                this.toastr.success(`${entityTypeTitle} status has successfully reset!`);
              else
                this.toastr.error(`Can not reset ${entityTypeTitle} status!`)
            }, _ => {
              this.toastr.error(`Can not reset ${entityTypeTitle} status! Some error happened`)
            }, () => {
              subscription.unsubscribe();
              this.fetchPendingList();
              this.inProgress = false;
            })
      }
    });
  }

  selectedItemToRequestRevision: PendingToApproveViewModel;
  requestRevisionModalRef: any;
  onDisplayRequestRevisionModal(template: any, item: PendingToApproveViewModel) {
    this.selectedItemToRequestRevision = item;
    this.requestRevisionModalRef = this.modalService.show(template, 'modal-90p');
  }

  showStartEndDateTime(type: ApproveEntityType): boolean {
    return type == ApproveEntityType.Induction ||
      type == ApproveEntityType.PermitForms ||
      type == ApproveEntityType.SupplierForms;
  }

  getDateShowValue(date: Date, type: ApproveEntityType, main: string, detail: string): { hasDate: boolean, showValue: string } {
    if (date && this.showStartEndDateTime(type) && this.isShowTemplateColumns(main, detail)) {
      let showValue =
        (type == ApproveEntityType.PermitForms ||
          type == ApproveEntityType.Induction ?
          this.datePipe :
          this.datetimePipe)
          .transform(date);
      return { hasDate: true, showValue: showValue };
    }
    return { hasDate: false, showValue: '' };
  }

  getStartDate(item: PendingToApproveViewModel): { hasDate: boolean, showValue: string } {
    return this.getDateShowValue(item.dateTime, item?.approveEntityType, "dateTime", "dateTime");
  }

  getEndDate(item: PendingToApproveViewModel): { hasDate: boolean, showValue: string } {
    return this.getDateShowValue(item.endDateTime, item?.approveEntityType, "dateTime", "endDateTime");
  }

  isShowTemplateColumns(mainColumn, detailColumn) {
    return this.grid.isShowTemplateColumns(mainColumn, detailColumn);
  }


  hasWorkflow(item: PendingToApproveViewModel) {
    return item?.approveEntityType == ApproveEntityType.SiteSupplierDocument &&
      item?.inWorkflowStep == ShowSupplierDocumentReviewButton.WorkflowInstanceHasAccess &&
      item?.status == PendingToApproveStatus.Pending;
  }

  isLagacyReview(item: PendingToApproveViewModel) {
    return item?.status == PendingToApproveStatus.Pending &&
      item.inWorkflowStep == ShowSupplierDocumentReviewButton.NoWorkflowInstance &&
      item?.approveEntityType == ApproveEntityType.SiteSupplierDocument &&
      item.inWorkflowStep == ShowSupplierDocumentReviewButton.NoWorkflowInstance &&
      this.serverResult?.hasSupplierReviewForm == true &&
      this.serverResult?.supplierDocumentReviewMode != SupplierDocumentReviewMode.Disabled &&
      !this.hasWorkflow(item);
  }

  isSimpleApproveReject(item: PendingToApproveViewModel) {
    return item?.status == PendingToApproveStatus.Pending &&
      (item?.approveEntityType != ApproveEntityType.Induction &&
        item?.approveEntityType != ApproveEntityType.PermitForms &&
        item?.approveEntityType != ApproveEntityType.SiteAsset &&
        item?.approveEntityType != ApproveEntityType.SiteAssetOperator &&
        item?.approveEntityType != ApproveEntityType.SupplierForms &&
        (item?.approveEntityType != ApproveEntityType.SiteSupplierDocument ||
          (item?.approveEntityType == ApproveEntityType.SiteSupplierDocument &&
            item?.inWorkflowStep == ShowSupplierDocumentReviewButton.NoWorkflowInstance &&
            ((this.serverResult?.supplierDocumentReviewMode == SupplierDocumentReviewMode.Disabled) ||
              (this.serverResult?.supplierDocumentReviewMode == SupplierDocumentReviewMode.Optional &&
                this.serverResult?.hasSupplierReviewForm == false)
            ) &&
            !this.hasWorkflow(item) &&
            !this.isLagacyReview(item)
          )
        )
      ) ||
      (item?.approveEntityType == ApproveEntityType.SiteAssetOperator && !item?.relatedFormDataId);
  }

  workflowEngineModal: BsModalRef<unknown>;
  openWorkflowEngine(instanceId: string, modalTemplate: any) {
    this.selectedInstanceId = instanceId;

    if (this.portalDisplayMode == PortalDisplayMode.PopUp) {
      this.formService.isFormDirty.next(false);
      this.workflowEngineModal = this.bsModalService.show(modalTemplate, {
        class: "modal-full-screen",
        ignoreBackdropClick: true,
        keyboard: false,
      });
      this.dirtyCheckSubscribe = this.formService.isFormDirty.asObservable().subscribe(e => {
        this.selectedFormDirtyState = e;
      });
    }
    else if (this.portalDisplayMode == PortalDisplayMode.Page) {
      this.router.navigate([`/workflow-engine/${this.selectedInstanceId}`], { queryParams: { returnPath: this.getPath() } });
    }
  }

  onActiveChanged(value) {
    let filter = this.initialFilters?.find((item) => item.key == 'includeInactive');
    filter.value = !!value;
    this.fetchPendingList();
  }

  SiteInductionRejectStatus = SiteInductionRejectStatus;
}
