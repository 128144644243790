<div class="card" *ngIf="editFormGroup">
  <obc-loader [show]="inEditProgress"></obc-loader>
  <div class="card-header">
    <span *ngIf="editFormGroup.get('id').value == 0">Add</span>
    <span *ngIf="editFormGroup.get('id').value != 0">Edit</span>
    <span> Document Kind</span>
    <button type="button" class="close" (click)="onClosePopup()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="card-body">
    <form [formGroup]="editFormGroup" class="row">
      <input type="hidden" class="form-control" formControlName="id">
      <input type="hidden" formControlName="id">
      <div class="form-group col-md-6 col-sm-12">
        <label>Name</label>
        <input type="text" class="form-control" formControlName="name">
      </div>

      <div class="form-group col-md-6 col-sm-12">
        <label>Access Mode</label>
        <select required class="form-control" formControlName="accessMode">
          <option *ngFor="let item of SupplierDocumentAccessMode | enumToArray"
            [ngValue]="SupplierDocumentAccessMode[item]">
            {{SupplierDocumentAccessMode[item] | supplierDocumentAccessMode}}
          </option>
        </select>
      </div>
      <div class="form-group col-md-6 col-sm-12">
        <label>Expiry Date Mode</label>
        <select required class="form-control" formControlName="expireDateMode">
          <option *ngFor="let item of SupplierDocumentExpireDateMode | enumToArray"
            [ngValue]="SupplierDocumentExpireDateMode[item]">
            {{SupplierDocumentExpireDateMode[item] | supplierDocumentExpiryDateMode}}
          </option>
        </select>
      </div>
      <div class="form-group col-md-6 col-sm-12" *ngIf="showSignOff()">
        <label>SignOff Mode</label>
        <select required class="form-control" formControlName="signOffMode">
          <option *ngFor="let item of SignOffMode | enumToArray" [ngValue]="SignOffMode[item]">
            {{SignOffMode[item] | supplierDocumentSignOffMode}}
          </option>
        </select>
      </div>
      <div class="form-group col-md-6 col-sm-12">
        <label>Comment Mode</label>
        <select required class="form-control" formControlName="commentMode">
          <option *ngFor="let item of SupplierDocumentCommentMode | enumToArray"
            [ngValue]="SupplierDocumentCommentMode[item]">
            {{SupplierDocumentCommentMode[item] | supplierDocumentCommentMode}}
          </option>
        </select>
      </div>
      <div class="form-group col-md-6 col-sm-12">
        <label>Amendment Mode</label>
        <select required class="form-control" formControlName="amendmentMode">
          <option *ngFor="let item of SupplierDocumentAmendmentMode | enumToArray"
            [ngValue]="SupplierDocumentAmendmentMode[item]">
            {{SupplierDocumentAmendmentMode[item] | supplierDocumentAmendmentMode}}
          </option>
        </select>
      </div>
      <div class="form-group col-md-6 col-sm-12">
        <label>Is Active</label>
        <div>
          <obc-switch formControlName="isActive" [trueLabel]="'Yes'" [falseLabel]="'No'">
          </obc-switch>
        </div>
      </div>
      <div class="form-group col-md-12 col-sm-12" *ngIf="showWorkflowSection">
        <obc-workflow-renderer #workflowRenderer [isStandAloneMode]="false" [companyId]="companyId"
          [workflowSubType]="documentKind.id" [isInEditMode]="false" [workflowType]="WorkflowType.SupplierDocument"
          [(expireDateMode)]="expireyMode"
          [selectedWorkflowTemplateId]="documentKind?.workflowSpecification?.workflowTemplateId"
          [defaultPlaceHolderValues]="documentKind?.workflowSpecification?.workflowInfo?.placeholderList"
          [currentWorkflowAccessMode]="documentKind?.workflowTemplateAccessMode"></obc-workflow-renderer>
      </div>

      <p class="button-group col-md-12">
        <button [disabled]="!formIsValid" href="#" class="card-link btn btn-primary" (click)="onSave()">
          Save Changes
        </button>
      </p>
    </form>
  </div>
</div>
