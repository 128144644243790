import { SiteAttendanceQuestionAnswer } from './site-attendance-question-answer';
import { UserBasicInfoViewModel } from '../user-basic-info-viewmodel';
import { RequiredDocumentAnswer } from '../requiredDocumentAnswer';
import { SiteAttendanceAccompanierModel } from '../site-attendance-accompanier-model';
import { SiteRequiredFieldValue } from '../site-required-field-viewmodel';
import { AttendanceFieldWithValue } from '../attendance-field-model';
import { SignedSupplierDocumentTypeVersionModel } from '../supplier-document/signed-supplier-document-type-version-model';
import { BaseAnnouncementVisit } from '../announcement-visit';
import { SupplierCheckinModel } from "../supplier-checkin-model";

export interface AttendanceRequestModel {
  Mode: RequestAttendanceMode;
  //isAuto?: boolean;
  latitude?: number;
  longtitude?: number;
  answers: SiteAttendanceQuestionAnswer[];
}

export interface CheckInAttendanceRequestModel extends AttendanceRequestModel {
  siteId: number;
  accompanierCount?: number;
  accompaniers: SiteAttendanceAccompanierModel[];
  requiredFieldValues: SiteRequiredFieldValue[];
  extraFieldValues: AttendanceFieldWithValue[];
  signedSupplierDocumentTypeVersions: SignedSupplierDocumentTypeVersionModel[];
  visitedAnnouncements: BaseAnnouncementVisit[];
}

export interface AnonymousCheckOutAttendanceRequestModel extends AttendanceRequestModel {
  supplierCheckOutData: SupplierCheckinModel;
}

export interface CheckOutAttendanceRequestModel extends AttendanceRequestModel {
  siteId: number;
  siteAttendanceId: number;
  documents: RequiredDocumentAnswer[];
  supplierCheckOutData: SupplierCheckinModel;
}

export interface ManualCheckInAttendanceRequestViewModel extends CheckInAttendanceRequestModel {
  siteAttendanceInfo: UserBasicInfoViewModel;
  documents: RequiredDocumentAnswer[];
  inductions: any[];
  allowComminiucateWithUser?: boolean;
  visitorTypeId?: number;
  supplierCheckinData: SupplierCheckinModel;
  requestSource: RequestSource;
}

export interface AnonymousCheckInAttendanceRequestModel extends CheckInAttendanceRequestModel {
  siteAttendanceInfo: UserBasicInfoViewModel;
  documents: RequiredDocumentAnswer[];
  allowComminiucateWithUser?: boolean;
  session: string;
  inductions: any[];
  visitorTypeId?: number;
  supplierCheckinData: SupplierCheckinModel;
}

export interface SiteAttendanceViewModel {
  userId: number;
  siteName: string;
  siteId: number;
  checkInDate: Date;
  siteAttendanceId: number;
}

export interface AnonymousSiteAttendanceViewModel extends SiteAttendanceViewModel {
  logoUrl: string;
  anonymousCheckInKey: any;
}


export enum RequestAttendanceMode {
  Self = 0,
  Manual = 1,
  Auto = 2,
  UndoAutoCheckOut = 3,
}

export enum RequestSource {
  Portal = 0,
  Mobile = 1,
}
