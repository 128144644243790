import { ProcorePermission } from './../../../models/procore/procore-permission';
import { CompanyPermissionsProjects } from './../../../models/procore/company-permissions-projects';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Subscription, of } from 'rxjs';
import { CompanySettingService } from 'src/app/services/company-setting.service';
import { BaseProcoreIntegrationConfigModel, ProcoreCheckTokenResponse, ProcoreCompany, ProcoreIntegrationAuthorizationModel, ProcoreIntegrationTypeInfo } from 'src/app/models/procore-integration-models';
import { ModalService } from 'src/app/services/modal.service';
import { finalize } from 'rxjs/operators';
import { SiteViewModel } from 'src/app/models/site-viewmodel';
import { IntDictionary } from 'src/app/models/dictionary';
import { ProcoreIntegrationType } from 'src/app/enums/procore-integration-type.enum';
import { ProcoreExportCheckInAsTimeCardMode, ProcoreExportCheckInAsVisitorLogMode, ProcoreExportCheckInAsManpowerMode } from 'src/app/enums/procore-export-modes.enum';
import { ProcoreConnectionMode } from 'src/app/enums/procore-connection-mode';
import { popup } from 'src/app/helpers/general-functions';
import { ProcoreFolderStructureMode } from 'src/app/enums/procore-folder-structure-mode.enum';
import { UserService } from 'src/app/services/user.service';
import { ProcoreProjectModel } from 'src/app/models/procore/procore-project-model';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ProcoreProjectInfo } from 'src/app/models/procore/procore-project';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-procore-integration',
  templateUrl: './procore-integration.component.html',
  styleUrls: ['./procore-integration.component.scss']
})
export class ProcoreIntegrationComponent implements OnInit {
  editClientInfo: boolean = false;
  editModeOfEnabledZone: boolean = false;
  errorMessage: string;
  infoMessage: string;
  procoreCheckTokenResponse: ProcoreCheckTokenResponse;
  apiUserEmail: string = null;
  ProcoreIntegrationTypes = ProcoreIntegrationType;
  procoreIntegrationTypeInfos: IntDictionary<ProcoreIntegrationTypeInfo> = null;
  templateSites: SiteViewModel[];
  ProcoreExportSiteAttendanceAsTimeCardMode = ProcoreExportCheckInAsTimeCardMode;
  ProcoreExportSiteAttendanceAsVisitorLogMode = ProcoreExportCheckInAsVisitorLogMode;
  ProcoreExportSiteAttendanceAsManpowerMode = ProcoreExportCheckInAsManpowerMode;
  ProcoreConnectionMode = ProcoreConnectionMode;
  ProcoreFolderStructureMode = ProcoreFolderStructureMode;
  companyList: ProcoreCompany[];
  dmsaCompanyList: ProcoreCompany[];
  companyPermissionsProjects: CompanyPermissionsProjects;
  projectPermissions: ProcorePermission[];
  bsModalHandle: any;
  disableEdit: boolean = true;
  isInEditingMode: boolean = false;
  currentProcoreEnabledState = false;
  //isToggle: boolean = false;

  get isGodUser(): boolean {
    return this.userService.isGodUser();
  }
  get isProcoreEnable(): boolean {
    return this.procoreEnableFormControl.value == true;
  }
  get isMarketPlaceOrDmsa(): boolean {
    return [ProcoreConnectionMode.MarketplaceApp, ProcoreConnectionMode.DMSA].includes(this.secretFormGroup.controls.procoreConnectionMode.value);
  }

  get isDmsaMode(): boolean {
    return [ProcoreConnectionMode.DMSA].includes(this.secretFormGroup.controls.procoreConnectionMode.value);
  }

  get hasProcoreCompanyId(): boolean {
    return +(this.configFormGroup.controls.procoreCompanyId.value) > 0
  }
  get showConfig() {
    return this.isProcoreEnable && this.configFormGroup.controls.procoreCompanyId.value && this.editModeOfEnabledZone;
  }
  showCheckPermissionButton() {
    return this.isGodUser && this.currentProcoreEnabledState == true && this.configFormGroup.controls.procoreCompanyId.value;
  }
  procoreEnableFormControl = new FormControl(null);
  secretFormGroup = new FormGroup({
    "clientId": new FormControl('', [Validators.required]),
    "clientSecret": new FormControl('', [Validators.required]),
    "procoreConnectionMode": new FormControl(ProcoreConnectionMode.DMSA, []),
  });
  godUserFormGroup = new FormGroup({
    "procoreCompanyId": new FormControl('', []),
  });

  configFormGroup = new FormGroup({
    "procoreWebHookEnabled": new FormControl(false, []),
    "procoreImportSitesEnabled": new FormControl(false, []),
    "procoreExportSiteAttendanceAsTimeCardMode": new FormControl(false, []),
    "procoreExportSiteAttendanceAsVisitorLogMode": new FormControl(false, []),
    "procoreExportSiteAttendanceAsManpowerMode": new FormControl(false, []),
    "procoreImportUsersEnabled": new FormControl(false, []),
    "procoreImportSiteUserAsFrequent": new FormControl(false, []),
    "procoreUploadSiteSupplierDocumentsEnabled": new FormControl(false, []),
    "procoreUploadSiteInductionsEnabled": new FormControl(false, []),
    "procoreUploadPermitFormsEnabled": new FormControl(false, []),
    "procoreUploadSiteAssetsFormEnabled": new FormControl(false, []),
    "procoreUploadSiteBriefingsEnabled": new FormControl(false, []),
    "procoreTemplateSiteId": new FormControl(null),
    "procoreImportCompanyDirectoryEnabled": new FormControl(null, []),
    "procoreImportProjectDirectoryEnabled": new FormControl(null, []),
    "procoreImportCompanyRolesEnabled": new FormControl(null, []),
    "procoreImportProjectUserRolesEnabled": new FormControl(null, []),
    "procoreCompanyId": new FormControl('', [Validators.required]),
    "enableProcoreSyncIncompleteForTimecard": new FormControl(false, []),
    "enableProcoreSyncIncompleteForVisitorLog": new FormControl(false, []),
    "enableProcoreSyncIncompleteForManpower": new FormControl(false, []),
    "procoreFolderStructureMode": new FormControl(ProcoreFolderStructureMode.Legacy, []),
    "isCreateProcoreFolderAsPrivate": new FormControl(false, []),

    "enableProcoreSyncIncompleteDescriptionForTimecard": new FormControl(true, []),
    "enableProcoreSyncIncompleteDescriptionForVisitorLog": new FormControl(true, []),
    "enableProcoreSyncIncompleteDescriptionForManpower": new FormControl(true, []),
  });
  inProgress: boolean = false;

  constructor(private companySettingService: CompanySettingService,
    private modalService: ModalService,
    private ref: ChangeDetectorRef,
    private userService: UserService,
    private bsModalService: BsModalService,
    private toastrService: ToastrService) { }
  onUpdateProcoreConfigSubs: Subscription = null;

  ngOnInit() {
    this.getTokenAuthorizationInfo();
    this.configFormGroup.controls.procoreImportSitesEnabled.valueChanges.subscribe(res => {
      if (res)
        this.configFormGroup.controls['procoreTemplateSiteId'].setValidators([Validators.required]);
      else
        this.configFormGroup.controls['procoreTemplateSiteId'].clearValidators();
      this.configFormGroup.controls['procoreTemplateSiteId'].updateValueAndValidity();
    });
  }

  getTokenAuthorizationInfo() {
    this.inProgress = true;
    this.companySettingService.getTokenInfo()
      .pipe(finalize(() => {
        this.inProgress = false;
        this.ref.detectChanges();
        setTimeout(() => {
          this.ref.detectChanges();
        }, 1000);
      }))
      .subscribe(res => {
        this.setFormValues(res);
        this.ref.detectChanges();
      });
  }

  onUpdateProcoreConfig() {
    this.inProgress = true;


    this.companySettingService.updateProcoreConfig({
      procoreEnabled: this.isProcoreEnable,
      procoreImportSitesEnabled: this.configFormGroup.controls.procoreImportSitesEnabled.value,
      procoreWebHookEnabled: this.configFormGroup.controls.procoreWebHookEnabled.value,
      procoreImportUsersEnabled: this.configFormGroup.controls.procoreImportUsersEnabled.value,
      procoreImportSiteUserAsFrequent: this.configFormGroup.controls.procoreImportSiteUserAsFrequent.value,

      procoreExportSiteAttendanceAsTimeCardMode: this.configFormGroup.controls.procoreExportSiteAttendanceAsTimeCardMode.value,
      procoreExportSiteAttendanceAsVisitorLogMode: this.configFormGroup.controls.procoreExportSiteAttendanceAsVisitorLogMode.value,
      procoreExportSiteAttendanceAsManpowerMode: this.configFormGroup.controls.procoreExportSiteAttendanceAsManpowerMode.value,

      procoreUploadSiteSupplierDocumentsEnabled: this.configFormGroup.controls.procoreUploadSiteSupplierDocumentsEnabled.value,
      procoreUploadSiteInductionsEnabled: this.configFormGroup.controls.procoreUploadSiteInductionsEnabled.value,
      procoreUploadPermitFormsEnabled: this.configFormGroup.controls.procoreUploadPermitFormsEnabled.value,
      procoreUploadSiteAssetsFormEnabled: this.configFormGroup.controls.procoreUploadSiteAssetsFormEnabled.value,
      procoreUploadSiteBriefingsEnabled: this.configFormGroup.controls.procoreUploadSiteBriefingsEnabled.value,

      procoreImportCompanyDirectoryEnabled: this.configFormGroup.controls.procoreImportCompanyDirectoryEnabled.value,
      procoreImportProjectDirectoryEnabled: this.configFormGroup.controls.procoreImportProjectDirectoryEnabled.value,

      procoreImportCompanyRolesEnabled: this.configFormGroup.controls.procoreImportCompanyRolesEnabled.value,
      procoreImportProjectUserRolesEnabled: this.configFormGroup.controls.procoreImportProjectUserRolesEnabled.value,

      procoreTemplateSiteId: this.configFormGroup.controls.procoreTemplateSiteId.value,

      procoreCompanyId: this.configFormGroup.controls.procoreCompanyId.value,

      enableProcoreSyncIncompleteForTimecard: this.configFormGroup.controls.enableProcoreSyncIncompleteForTimecard.value,
      enableProcoreSyncIncompleteForVisitorLog: this.configFormGroup.controls.enableProcoreSyncIncompleteForVisitorLog.value,
      enableProcoreSyncIncompleteForManpower: this.configFormGroup.controls.enableProcoreSyncIncompleteForManpower.value,
      procoreFolderStructureMode: this.configFormGroup.controls.procoreFolderStructureMode.value,
      isCreateProcoreFolderAsPrivate: this.configFormGroup.controls.isCreateProcoreFolderAsPrivate.value,

      enableProcoreSyncIncompleteDescriptionForTimecard: this.configFormGroup.controls.enableProcoreSyncIncompleteDescriptionForTimecard.value,
      enableProcoreSyncIncompleteDescriptionForVisitorLog: this.configFormGroup.controls.enableProcoreSyncIncompleteDescriptionForVisitorLog.value,
      enableProcoreSyncIncompleteDescriptionForManpower: this.configFormGroup.controls.enableProcoreSyncIncompleteDescriptionForManpower.value,

    } as BaseProcoreIntegrationConfigModel)
      .pipe(finalize(() => {
        this.inProgress = false;
        this.disableEdit = true;
      }))
      .subscribe(res => {
        this.setFormValues(res);
        this.toastrService.success("Successfully Saved.");
      });
  }

  setFormValues(res: ProcoreCheckTokenResponse) {
    //this.isToggle = false;
    this.errorMessage = '';
    this.procoreCheckTokenResponse = res;
    this.unsubscribeToFormControlChanges();
    if (res == null) {
      this.procoreEnableFormControl.setValue(false);
      this.currentProcoreEnabledState = false;
    } else if (!res.valid) {
      //this.modalService.error(res.message);
      this.errorMessage = res.message;
      this.editClientInfo = true;
      this.editModeOfEnabledZone = false;
      this.procoreEnableFormControl.setValue(res.configuration.procoreEnabled);
      this.currentProcoreEnabledState = res.configuration.procoreEnabled;
    } else {
      this.infoMessage = res.message;

      if (res.procoreSummeryInfo && res.procoreSummeryInfo.me) {
        this.apiUserEmail = res.procoreSummeryInfo.me.login;
        if (!this.companyList || this.companyList.length != (res.procoreSummeryInfo.companies?.length ?? 0))
          this.companyList = res.procoreSummeryInfo.companies;
      }

      //this.dmsaCompanyList = res.dmsaCompanies;

      this.procoreIntegrationTypeInfos = res.procoreIntegrationTypeInfos;
      this.procoreEnableFormControl.setValue(res.configuration.procoreEnabled);
      this.isInEditingMode = !res.configuration.procoreEnabled;
      this.currentProcoreEnabledState = res.configuration.procoreEnabled;
      this.configFormGroup.controls.procoreExportSiteAttendanceAsTimeCardMode.setValue(res.configuration.procoreExportSiteAttendanceAsTimeCardMode);
      this.configFormGroup.controls.procoreExportSiteAttendanceAsVisitorLogMode.setValue(res.configuration.procoreExportSiteAttendanceAsVisitorLogMode);
      this.configFormGroup.controls.procoreExportSiteAttendanceAsManpowerMode.setValue(res.configuration.procoreExportSiteAttendanceAsManpowerMode);
      this.configFormGroup.controls.procoreImportSitesEnabled.setValue(res.configuration.procoreImportSitesEnabled);
      this.configFormGroup.controls.procoreWebHookEnabled.setValue(res.configuration.procoreWebHookEnabled);
      this.configFormGroup.controls.procoreImportUsersEnabled.setValue(res.configuration.procoreImportUsersEnabled);
      this.configFormGroup.controls.procoreImportSiteUserAsFrequent.setValue(res.configuration.procoreImportSiteUserAsFrequent);
      this.configFormGroup.controls.procoreUploadSiteSupplierDocumentsEnabled.setValue(res.configuration.procoreUploadSiteSupplierDocumentsEnabled);
      this.configFormGroup.controls.procoreUploadSiteInductionsEnabled.setValue(res.configuration.procoreUploadSiteInductionsEnabled);
      this.configFormGroup.controls.procoreUploadPermitFormsEnabled.setValue(res.configuration.procoreUploadPermitFormsEnabled);
      this.configFormGroup.controls.procoreUploadSiteAssetsFormEnabled.setValue(res.configuration.procoreUploadSiteAssetsFormEnabled);
      this.configFormGroup.controls.procoreUploadSiteBriefingsEnabled.setValue(res.configuration.procoreUploadSiteBriefingsEnabled);
      this.configFormGroup.controls.procoreImportCompanyDirectoryEnabled.setValue(res.configuration.procoreImportCompanyDirectoryEnabled);
      this.configFormGroup.controls.procoreImportProjectDirectoryEnabled.setValue(res.configuration.procoreImportProjectDirectoryEnabled);
      this.configFormGroup.controls.procoreImportCompanyRolesEnabled.setValue(res.configuration.procoreImportCompanyRolesEnabled);
      this.configFormGroup.controls.procoreImportProjectUserRolesEnabled.setValue(res.configuration.procoreImportProjectUserRolesEnabled);
      this.configFormGroup.controls.enableProcoreSyncIncompleteForTimecard.setValue(res.configuration.enableProcoreSyncIncompleteForTimecard);
      this.configFormGroup.controls.enableProcoreSyncIncompleteDescriptionForTimecard.setValue(res.configuration.enableProcoreSyncIncompleteDescriptionForTimecard);
      this.configFormGroup.controls.enableProcoreSyncIncompleteForVisitorLog.setValue(res.configuration.enableProcoreSyncIncompleteForVisitorLog);
      this.configFormGroup.controls.enableProcoreSyncIncompleteDescriptionForVisitorLog.setValue(res.configuration.enableProcoreSyncIncompleteDescriptionForVisitorLog);
      this.configFormGroup.controls.enableProcoreSyncIncompleteForManpower.setValue(res.configuration.enableProcoreSyncIncompleteForManpower);
      this.configFormGroup.controls.enableProcoreSyncIncompleteDescriptionForManpower.setValue(res.configuration.enableProcoreSyncIncompleteDescriptionForManpower);
      this.configFormGroup.controls.isCreateProcoreFolderAsPrivate.setValue(res.configuration.isCreateProcoreFolderAsPrivate);
      this.configFormGroup.controls.procoreTemplateSiteId.setValue(res.configuration.procoreTemplateSiteId);
      this.configFormGroup.controls.procoreCompanyId.setValue(res.configuration.procoreCompanyId);
      this.godUserFormGroup.controls.procoreCompanyId.setValue(res.configuration.procoreCompanyId ?? '');
      this.configFormGroup.controls.procoreFolderStructureMode.setValue(res.configuration.procoreFolderStructureMode);
      this.secretFormGroup.controls.clientId.setValue(res.configuration.clientId);
      this.secretFormGroup.controls.clientSecret.setValue(null);
      this.secretFormGroup.controls.procoreConnectionMode.setValue(res.configuration.procoreConnectionMode);
      this.editClientInfo = res.configuration.isReadyToConnectToProcore ? false : true;
      this.editModeOfEnabledZone = res.configuration.isReadyToConnectToProcore ? true : false;
      if (res.siteTemplates)
        this.templateSites = res.siteTemplates;
    }
    this.configFormGroup.controls.procoreCompanyId.updateValueAndValidity();
    this.configFormGroup.updateValueAndValidity();

    this.godUserFormGroup.controls.procoreCompanyId.updateValueAndValidity();
    this.godUserFormGroup.updateValueAndValidity();

    this.subscribeToFormControlChanges();

    this.initializeEditingModeVariables();
  }

  initializeEditingModeVariables() {
    if (this.currentProcoreEnabledState == true && !this.configFormGroup.controls.procoreCompanyId.value)
      this.enableEdit(true);
  }

  isProcoreConfigEdited: boolean = false;
  private subscribeToFormControlChanges() {
    this.onUpdateProcoreConfigSubs = this.configFormGroup.valueChanges.subscribe(res => {
      this.isProcoreConfigEdited = true;
    });
  }

  private unsubscribeToFormControlChanges() {
    if (this.onUpdateProcoreConfigSubs)
      this.onUpdateProcoreConfigSubs.unsubscribe();
  }

  onCheckAndSaveAuthorization() {
    this.inProgress = true;
    this.companySettingService.checkAndSaveAuthorization({
      clientId: this.secretFormGroup.controls.clientId.value,
      clientSecret: this.secretFormGroup.controls.clientSecret.value,
      procoreConnectionMode: this.secretFormGroup.controls.procoreConnectionMode.value,
    } as ProcoreIntegrationAuthorizationModel)
      .pipe(finalize(() => {
        this.inProgress = false;
      }))
      .subscribe(res => {
        this.setFormValues(res);
      });
  }

  onAuthOnProcore() {
    this.inProgress = true;
    let url = `${this.procoreCheckTokenResponse.marketplaceAppConfiguration.marketplaceAppAuthorizationUrl}/oauth/authorize?response_type=code&client_id=`;
    if (this.isDmsaMode)
      url += `${this.procoreCheckTokenResponse.marketplaceAppConfiguration.dmsaAppClientId}&redirect_uri=${this.procoreCheckTokenResponse.marketplaceAppConfiguration.dmsaAppRedirectUrl}`;
    else
      url += `${this.procoreCheckTokenResponse.marketplaceAppConfiguration.marketplaceAppClientId}&redirect_uri=${this.procoreCheckTokenResponse.marketplaceAppConfiguration.marketplaceAppRedirectUrl}`;
    window['procoreConfigCompleted'] = () => { this.onProcoreConfigSuccess(); };
    popup(url, 'Procore Authorize');
  }

  onProcoreConfigSuccess() {
    this.inProgress = false;
    this.getTokenAuthorizationInfo();

    this.ref.detectChanges();
  }

  resetProcoreSchedule(type: ProcoreIntegrationType) {
    this.inProgress = true;
    this.companySettingService.resetProcoreSchedule(type)
      .pipe(finalize(() => {
        this.inProgress = false;
      }))
      .subscribe(res => {
        this.procoreIntegrationTypeInfos = res;
      }, err => {
        this.modalService.error(err);
      });
  }

  get showReSyncButtonForExportAttendance() {
    return this.configFormGroup.get('procoreExportSiteAttendanceAsTimeCardMode').value != ProcoreExportCheckInAsTimeCardMode.Disabled ||
      this.configFormGroup.get('procoreExportSiteAttendanceAsVisitorLogMode').value != ProcoreExportCheckInAsVisitorLogMode.Disabled ||
      this.configFormGroup.get('procoreExportSiteAttendanceAsManpowerMode').value != ProcoreExportCheckInAsManpowerMode.Disabled;
  }
  get showReSyncButtonForUploadSiteSupplierDocuments() {
    return this.configFormGroup.get('procoreUploadSiteSupplierDocumentsEnabled').value;
  }

  get showReSyncButtonForUploadSiteInductions() {
    return this.configFormGroup.get('procoreUploadSiteInductionsEnabled').value;
  }

  get showReSyncButtonForUploadPermitForms() {
    return this.configFormGroup.get('procoreUploadPermitFormsEnabled').value;
  }

  get showReSyncButtonForUploadSiteBriefings() {
    return this.configFormGroup.get('procoreUploadSiteBriefingsEnabled').value;
  }

  get showReSyncButtonForUploadSiteAssetForms() {
    return this.configFormGroup.get('procoreUploadSiteAssetsFormEnabled').value;
  }

  get showReSyncButtonForImportCompanyDirectory() { return this.configFormGroup.get('procoreImportCompanyDirectoryEnabled').value; }
  get showReSyncButtonForImportProjectDirectory() { return this.configFormGroup.get('procoreImportProjectDirectoryEnabled').value; }

  get showReSyncButtonForImportCompanyRoles() { return this.configFormGroup.get('procoreImportCompanyRolesEnabled').value; }
  get showReSyncButtonForImportProjectUserRoles() { return this.configFormGroup.get('procoreImportProjectUserRolesEnabled').value; }

  get procoreCompanyInfo() {
    let id = this.procoreCheckTokenResponse.configuration?.procoreCompanyId;
    if (id && this.companyList?.length)
      return `${this.companyList.find(c => c.id == id)?.name} (${id})`;
    return id;
  }

  checkPermissions(template: any) {
    this.inProgress = true;
    this.companySettingService.GetProcorePermissionsProjects().pipe(finalize(() => {
      this.inProgress = false;
    }))
      .subscribe(result => {
        this.bsModalHandle = this.bsModalService.show(template, { class: 'modal-lg' });
        this.companyPermissionsProjects = result;
      });
  }

  getProjectPermission(item: ProcoreProjectInfo) {
    if (item.projectPermissions)
      return;

    var model: ProcoreProjectModel = {
      projectId: item.id
    };
    this.companySettingService.GetProjectPermissions(model).pipe(finalize(() => {
      this.inProgress = false;
    }))
      .subscribe(res => {
        item.projectPermissions = res;
      });
  }

  enableEdit(forceMode: boolean = false) {
    if (this.disableEdit) {
      (forceMode ? of(true) :
        this.modalService.confirm("This is a functioning integration, changing this setting will change it for the entire companies projects.", "Warning", "Continue", "Cancel", "btn-danger", "btn-primary"))
        .subscribe(result => {
          this.disableEdit = !result;
          this.isInEditingMode = !this.disableEdit;
        });
    }
    else {
      if (this.isProcoreConfigEdited)
        this.onUpdateProcoreConfig();
    }
  }

  onProcoreEnabledChange(value) {

    if (value && this.currentProcoreEnabledState != this.isProcoreEnable) {
      this.enableEdit(true);
      this.isProcoreConfigEdited = true;
    }
  }

  onSaveProcoreEnable() {
    if (this.currentProcoreEnabledState != this.isProcoreEnable) {
      this.modalService.confirm("This is a functioning integration, changing this setting will change it for the entire companies projects.", "Warning", "Continue", "Cancel", "btn-danger", "btn-primary")
        .subscribe(result => {
          if (result) {
            this.inProgress = true;
            this.companySettingService.toggleProcoreEnable()
              .pipe(finalize(() => {
                this.inProgress = false;
                this.isInEditingMode = false;
                this.disableEdit = true;
              }))
              .subscribe(res => {
                this.setFormValues(res);
              });
          }
        });
    }
  }

  cancelEdit() {
    this.getTokenAuthorizationInfo();
    this.isInEditingMode = false;
    this.disableEdit = true;
  }
}
