<form name="siteform3" [formGroup]="siteManagementFG" (submit)="updateInsert()" class="form-horizontal form-bordered"
  autocomplete="off">
  <obc-loader [show]="inProgress"></obc-loader>

  <div class="row">
    <div class="col-12 mb-2">
      <div class="d-flex justify-content-between align-items-center">
        <button type="button" class="btn btn-default" [routerLink]="['/sites']">
          <i class="fas fa-arrow-left"></i> Return to List
        </button>
      </div>
    </div>
  </div>

  <div class="mb-2">
    <div class="row form-group">
      <div class="col-md-12">
        <obc-site-selector-withexternal [siteId]="siteId" [siteSupplierId]="siteSupplierId"
          (siteSelect)="onChangeSelectedSite($event)">
        </obc-site-selector-withexternal>
      </div>
    </div>
  </div>
  <div class="mb-2 alert alert-info d-flex align-items-center" *ngIf="showInfo">
    <i class="fas fa-info mr-2"></i>
    <span class="mr-1" *ngIf="siteManagementMode == SiteManagementModeEnum.Site">You can manage the site settings and
      information
      all in this page</span>
    <span class="mr-1" *ngIf="siteManagementMode == SiteManagementModeEnum.Supplier">You can manage your own
      questions, forms,
      inductions, and announcements in this page</span>
    <button type="button" style="position: absolute;right: 10px;top: 10px;" class="close"
      (click)="closeInfoPanel();">×</button>
  </div>

  <button [hidden]="!isAnyAccordionDirty() && !isNewSiteRequest()" [disabled]="!areAllAccordionsValid()"
    [style]="'z-index: 1000; right: 40px; top: 68px;'" class="btn btn-success btn-submit position-fixed"
    type="submit">Submit
    Changes</button>

  <div *ngIf="this.siteManagementFG.get('projectSettings.isTemplate')?.value"
    class="mb-2 alert alert-warning d-flex align-items-center">
    <i class="fas fa-paint-brush mr-2"></i>
    <span>Template Site</span>
  </div>
  <accordion class="site-management">
    <accordion-group formGroupName="projectSettings" *ngIf="siteManagementMode == SiteManagementModeEnum.Site">
      <div accordion-heading>
        <obc-accordion-header [mainTitle]="'Project Settings'" [info]="[{'value': manageSiteModel?.site?.name}]"
          [icon]="'fa fa-sliders-h'" [iconColor]="'#2196F3'"
          [invalidControlsCount]="findInvalidControlsCount('projectSettings')"
          [isAccordionDirty]="checkIfFormGroupIsDirty('projectSettings')"></obc-accordion-header>
      </div>
      <div>


        <div class="form-group row pb-2 mb-1">
          <label class="col-sm-4 control-label text-left pt-2">Name</label>
          <div class="col-sm-8">
            <div class="input-group mb-3">
              <input type="text" name="name" formControlName="name" class="form-control" required />
              <span class="input-group-append">
                <obc-tooltip message="Site name is displayed to public"></obc-tooltip>
              </span>
            </div>
            <div
              *ngIf="siteManagementFG.get('projectSettings.name')?.invalid && (siteManagementFG.get('projectSettings.name')?.dirty || siteManagementFG.get('projectSettings.name')?.touched)"
              class="help-block with-errors">
              Please Enter Site Name
            </div>
          </div>
        </div>
        <div class="form-group row pb-2 mb-1">
          <label class="col-sm-4 control-label text-left pt-2">Description</label>
          <div class="col-sm-8">
            <div class="input-group mb-3">
              <input type="text" name="Description" formControlName="description" class="form-control" />
              <span class="input-group-append">
                <obc-tooltip message="Internal Site Name, Not visible to the public"></obc-tooltip>
              </span>
            </div>
          </div>
        </div>


        <div class="form-group row pb-2 mb-1">
          <label class="col-sm-4 control-label text-left pt-2">Is Public Site</label>
          <div class="col-auto pull-left">
            <obc-switch [value]="siteManagementFG.get('projectSettings.isPublicSite')?.value"
              (valueChanged)="siteManagementFG.get('projectSettings.isPublicSite')?.setValue($event)"></obc-switch>
            <obc-tooltip
              message="All members of the public can check in to a public site, A private site restricts check in access to individuals who have been invited as a regular user">
            </obc-tooltip>
          </div>
        </div>
        <div class="form-group row pb-2 mb-1">
          <label class="col-sm-4 control-label text-left pt-2">Check-In Approval Mode</label>
          <div class="col-sm-8">
            <div class="input-group mb-3">
              <select class="form-control" formControlName="checkInApprovalMode" name="checkInApprovalMode">
                <option value="{{ CheckInApprovalMode.Disable }}">
                  {{ getCheckinApprovalModeFriendlyMode(CheckInApprovalMode.Disable) }}
                </option>
                <option value="{{ CheckInApprovalMode.EveryCheckIn }}">
                  {{ getCheckinApprovalModeFriendlyMode(CheckInApprovalMode.EveryCheckIn) }}
                </option>
                <option value="{{ CheckInApprovalMode.NewSupplierDocument }}">
                  {{ getCheckinApprovalModeFriendlyMode(CheckInApprovalMode.NewSupplierDocument) }}
                </option>
              </select>
              <span class="input-group-append">
                <obc-tooltip message="If site attendance needs approval enable this feature.">
                </obc-tooltip>
              </span>
            </div>
          </div>
        </div>
        <!--
        <div class="form-group row pb-2 mb-1">
          <label class="col-sm-4 control-label text-left pt-2">Contact</label>
          <div class="col-sm-8">
            <div class="input-group mb-3">
              <app-user-card class="fw" [required]="false" [label]="null"
                [userId]="siteManagementFG.get('projectSettings.contactUserId')?.value"></app-user-card>
            </div>
          </div>
        </div>-->

        <div class="form-group row pb-2 mb-1">
          <label class="col-sm-4 control-label text-left pt-2">Site Supervisor(s) Email Address</label>
          <div class="col-sm-8">
            <div class="input-group mb-3">
              <input emailList placeholder="supervisor1@company.com,supervisor2@company.com,..." type="email"
                name="siteSupervisorEmailAddress" class="form-control" formControlName="siteSupervisorEmailAddress" />
            </div>
          </div>
        </div>

        <div class="form-group row pb-2 mb-1">
          <label class="col-sm-4 control-label text-left pt-2">Force Check Out At End Of Day?</label>
          <div class="col-auto pull-left">
            <obc-switch [value]="siteManagementFG.get('projectSettings.isForcedCheckOutTime')?.value"
              (valueChanged)="siteManagementFG.get('projectSettings.isForcedCheckOutTime')?.setValue($event)">
            </obc-switch>
          </div>
        </div>
        <div class="form-group row pb-2 mb-1">
          <label class="col-sm-4 control-label text-left pt-2">Force Check Out After Time Period?</label>
          <div class="col-auto pull-left">
            <div class="d-flex flex-column flex-md-row">
              <obc-switch [value]="isForcedCheckOutAfterMinute?.value"
                (valueChanged)="isForcedCheckOutAfterMinuteMethod($event)">
              </obc-switch>

              <div *ngIf="isForcedCheckOutAfterMinute?.value == true" class="input-group ml-3 mt-2 mt-md-0">
                <!-- <obc-hour-minute-selector
                  [cssClass]="siteManagementFG.get('projectSettings.forceCheckOutAfterMinute')?.value?'':'ng-invalid'"
                  [minute]="siteManagementFG.get('projectSettings.forceCheckOutAfterMinute')?.value"
                  [isMinuteActiveByDefault]="true"
                  (valueChanged)="updateForcedCheckOutAfterMinute($event)">
                </obc-hour-minute-selector> -->
                <input type="number" min="1" class="form-control" name="forceCheckOutAfterMinute"
                  formControlName="forceCheckOutAfterMinute" placeholder="Minutes" />
                <span class="input-group-text">Minutes</span>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group row pb-2 mb-1"
          *ngIf="siteManagementFG.get('projectSettings.isForcedCheckOutTime')?.value">
          <label class="col-sm-4 control-label text-left pt-2">End Time Of Day</label>
          <div class="col-auto pull-left">
            <input type="time" class="form-control" placeholder="" formControlName="forceCheckOutTime"
              name="forceCheckOutTime" />
          </div>
        </div>
        <div class="form-group row pb-2 mb-1">
          <label class="col-sm-4 control-label text-left pt-2">Location Required Mode On Check In/Out</label>
          <div class="col-auto pull-left">
            <div class="input-group mb-3">
              <select class="form-control" name="checkinLocationMode" formControlName="checkinLocationMode"
                (change)="checkinLocationModeFn($event.target.value)">
                <option *ngFor="let index of CheckinLocationMode | enumToArray"
                  value="{{ CheckinLocationMode[index] }}">
                  {{ CheckinLocationMode[index] | checkinLocationMode }}
                </option>
              </select>
              <span class="input-group-append">
                <obc-tooltip
                  message="1BreadCrumb will check the individual's location matches the site location   (Users Location must be enabled)">
                </obc-tooltip>
                <obc-tooltip
                  message="1BreadCrumb will send a push notification to an individuals device when in close proximity to the site. WARNING! Enabling Automatic Check in, will not ask Individuals Questions">
                </obc-tooltip>
              </span>
            </div>
          </div>
        </div>
        <div class="form-group row pb-2 mb-1" *ngIf="checkinLocationMode === CheckinLocationMode.Mandatory
               ||checkinLocationMode === CheckinLocationMode.MandatoryOnSite">
          <label class="col-sm-4 control-label text-left pt-2">Override Check-In Location To Optional Mode In
            Kiosk</label>
          <div class="col-auto pull-left">
            <obc-switch [value]="siteManagementFG.get('projectSettings.checkInLocationOverrideInKioskMode')?.value"
              (valueChanged)="siteManagementFG.get('projectSettings.checkInLocationOverrideInKioskMode')?.setValue($event)">
            </obc-switch>
          </div>
        </div>
        <div class="form-group row pb-2 mb-1">
          <label class="col-sm-4 control-label text-left pt-2">Site Reference</label>
          <div class="col-sm-8">
            <div class="input-group mb-3">
              <input type="text" name="siteReference" class="form-control" formControlName="siteReference" />
              <span class="input-group-append">
                <obc-tooltip message="Site Reference Code"></obc-tooltip>
              </span>
            </div>
          </div>
        </div>
        <div class="form-group row pb-2 mb-1">
          <label class="col-sm-4 control-label text-left pt-2">Site Radius</label>
          <div class="col-sm-auto pull-left">
            <div class="input-group mb-3">
              <input type="number" class="form-control" name="siteRadius" formControlName="siteRadius"
                placeholder="Meter" max="{{ siteManagementFG.get('projectSettings.maxAllowedSiteRadius')?.value }}"
                (change)="validateSiteRadius()" />
              <span class="input-group-text">Metres</span>
            </div>
          </div>
          <div
            *ngIf="siteManagementFG.get('projectSettings.siteRadius')?.invalid && (siteManagementFG.get('projectSettings.siteRadius')?.dirty || siteManagementFG.get('projectSettings.siteRadius')?.touched)"
            class="help-block with-errors">
            Please Enter Site Radius
          </div>
          <div class="col-sm-12">
            <span class="alert alert-warning" *ngIf="showInvalidSiteRadiusMessage">
              <small>
                The maximum allowed value is {{ siteManagementFG.get('projectSettings.maxAllowedSiteRadius')?.value }}.
                Contact administrator for more information.
              </small>
            </span>
          </div>
        </div>
        <div class="form-group row pb-2 mb-1" *ngIf="currentUserIsGod">
          <label class="col-sm-4 control-label text-left pt-2">
            Max Allowed Site Radius
            <small><span class="text-danger">(GOD USER)</span></small>
          </label>
          <div class="col-auto pull-left">
            <div class="input-group mb-3">
              <input type="number" class="form-control" name="maxAllowedSiteRadius" (change)="validateMaxSiteRadius()"
                formControlName="maxAllowedSiteRadius" placeholder="Meter" />
              <span class="input-group-text">Metres</span>
            </div>
          </div>
        </div>
        <div *ngIf="this.siteManagementFG.get('projectSettings.isTemplate')?.value != true"
          class="form-group row pb-2 mb-1">
          <label class="col-sm-4 control-label text-left pt-2">Address:</label>
          <div class="col-sm-8">
            <div class="input-group mb-3">
              <input required name="Address" [formControl]="siteManagementFG.get('projectSettings.address')"
                [typeahead]="suggestions$" [isAnimated]="true" [adaptivePosition]="true" [typeaheadAsync]="true"
                [typeaheadItemTemplate]="customItemTemplate" [typeaheadOptionsInScrollableView]="6"
                [typeaheadOrderBy]="sortConfig" (typeaheadOnSelect)="onSelectAddress($event)" [typeaheadMinLength]="2"
                class="form-control" />
              <div class="input-group-prepend" *ngIf="loader">
                <div class="spinner-border text-primary" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            </div>
            <div
              *ngIf="siteManagementFG.get('projectSettings.address')?.invalid && (siteManagementFG.get('projectSettings.address')?.dirty || siteManagementFG.get('projectSettings.address')?.touched)"
              class="help-block with-errors">
              Please Enter Site Address
            </div>
          </div>
          <ng-template #customItemTemplate let-model="item">
            <span>
              <strong>{{ model.primaryTitle }}</strong> -
              {{ model.secondaryTitle }} -
              <small>{{ model.description }}</small>
            </span>
          </ng-template>
        </div>
        <div *ngIf="this.siteManagementFG.get('projectSettings.isTemplate')?.value != true"
          class="form-group row pb-0 mb-0">
          <div class="p-2 w-100">
            <div class="col-6 pull-left">
              <div class="input-group mb-3">
                <span class="input-group-text">Latitude</span>
                <input type="number" class="form-control" name="latitude" formControlName="latitude"
                  placeholder="Latitude" required />
              </div>
            </div>
            <div class="col-6 pull-left">
              <div class="input-group mb-3">
                <span class="input-group-text">Longitude</span>
                <input type="number" class="form-control" name="longitude" formControlName="longitude"
                  placeholder="Longitude" required />
              </div>
            </div>

            <obc-map [markerTitle]="this.siteManagementFG.get('projectSettings.name')?.value"
              [siteRadius]="this.siteManagementFG.get('projectSettings.siteRadius')?.value"
              [location]="{ lat: this.siteManagementFG.get('projectSettings.latitude')?.value, lng: this.siteManagementFG.get('projectSettings.longitude')?.value }"
              (locationChange)="updateLocation($event)">
            </obc-map>
          </div>
        </div>




      </div>
    </accordion-group>
    <accordion-group formGroupName="contactInformation" *ngIf="siteManagementMode == SiteManagementModeEnum.Site">
      <div accordion-heading>
        <obc-accordion-header [mainTitle]="'Contact Information'" [info]="[{'value': getContactInformationDetail()}]"
          [icon]="'far fa-address-card'" [iconColor]="'#1B8A72FF'"
          [invalidControlsCount]="findInvalidControlsCount('contactInformation')"
          [isAccordionDirty]="checkIfFormGroupIsDirty('contactInformation')"></obc-accordion-header>
      </div>
      <obc-contact-information
        [contactInformationAsJson]="siteManagementFG.get('contactInformation.contactInformationAsJson')"
        (result)="updateContactInformation($event)"></obc-contact-information>
    </accordion-group>
    <accordion-group *ngIf="siteManagementMode == SiteManagementModeEnum.Supplier">
      <div accordion-heading>
        <obc-accordion-header [mainTitle]="'Site Information'"
          [info]="[{'value': manageSiteModel?.supplierSite?.siteName}]" [icon]="'fa fa-info-circle'"
          [iconColor]="'#2196F3'"></obc-accordion-header>
      </div>
      <div>

        <div class="row">
          <div class="col-12">
            <h2>{{ this.manageSiteModel?.supplierSite?.siteName }}</h2>
            <h6>{{ this.manageSiteModel?.supplierSite?.address }}</h6>
          </div>
        </div>

        <div class="form-group row pb-0 mb-0">
          <div class="p-2 w-100">
            <obc-map [markerTitle]="this.manageSiteModel?.supplierSite?.siteName" [readonly]="true"
              [location]="{ lat: this.manageSiteModel?.supplierSite?.latitude, lng: this.manageSiteModel?.supplierSite?.longitude }">
            </obc-map>
          </div>
        </div>

      </div>
    </accordion-group>

    <accordion-group formGroupName="automatedAttendance"
      *ngIf="isActiveAutoCheckInPanel && siteManagementMode == SiteManagementModeEnum.Site">
      <div accordion-heading>
        <obc-accordion-header [mainTitle]="'Automated Attendance'" [icon]="'fa fa-magic'" [iconColor]="'#795548'"
          [invalidControlsCount]="findInvalidControlsCount('automatedAttendance')"
          [isAccordionDirty]="checkIfFormGroupIsDirty('automatedAttendance')"
          [info]="[{'title': 'Automatic Check In: ',
          'value': +siteManagementFG.get('automatedAttendance.autoCheckInType')?.value | automaticCheckinMode : true}]"></obc-accordion-header>
      </div>
      <div>


        <div class="form-group row pb-2 mb-1" *ngIf="isActiveAutoCheckInPanel">
          <label class="col-sm-4 control-label text-left pt-2">Automatic Check In</label>
          <div class="col-auto pull-left">
            <div class="input-group mb-3">
              <select class="form-control" formControlName="autoCheckInType" name="autoCheckInType">
                <option *ngFor="let index of automaticCheckinMode | enumToArray"
                  value="{{ automaticCheckinMode[index] }}">
                  {{ automaticCheckinMode[index] | automaticCheckinMode }}
                </option>
              </select>
              <span class="input-group-append">
                <obc-tooltip
                  message="Automatic check-in makes the process much easier for users but they won't be able to answer check-in questions.">
                </obc-tooltip>
              </span>
            </div>
          </div>
        </div>
        <div class="form-group row pb-2 mb-1"
          *ngIf="isActiveAutoCheckInPanel && siteManagementFG.get('automatedAttendance.autoCheckInType')?.value != automaticCheckinMode.Disable">
          <label class="col-sm-4 control-label text-left pt-2">Automatic Check In Delay</label>
          <div class="col-sm-auto pull-left">
            <div class="input-group mb-3">
              <select required style="min-width: 110px;" class="form-control" name="autoCheckInDelayInMinute"
                formControlName="autoCheckInDelayInMinute">
                <option *ngFor="let value of checkInDelayInMinutes" value="{{ value }}">{{value}}</option>
              </select>
              <span class="input-group-text">Minute(s)</span>
            </div>
          </div>
        </div>
        <div class="form-group row pb-2 mb-1" *ngIf="isActiveAutoCheckInPanel">
          <label class="col-sm-4 control-label text-left pt-2">
            Automatic Check Out
          </label>
          <div class="col-auto pull-left">
            <div class="input-group mb-3">
              <select class="form-control" formControlName="autoCheckOutType" name="autoCheckOutType">
                <option *ngFor="let index of autoCheckOutTypes | enumToArray" [ngValue]="autoCheckOutTypes[index]">
                  {{autoCheckOutTypes[index] | autoCheckOutType }}
                </option>
              </select>
              <span class="input-group-append">
                <obc-tooltip
                  message="By using auto check-out, you can make sure that users won't leave the premises without a check-out.">
                </obc-tooltip>
              </span>
            </div>
          </div>
        </div>
        <div class="form-group row pb-2 mb-1" *ngIf="isActiveAutoCheckInPanel &&
          siteManagementFG.get('automatedAttendance.autoCheckOutType')?.value != autoCheckOutTypes.Disable">
          <label class="col-sm-4 control-label text-left pt-2">Automatic Check Out Threshold</label>
          <div class="col-auto pull-left">
            <div class="input-group mb-3">
              <input type="number" min="0" class="form-control" name="geofencingThresholdInMinutes"
                formControlName="geofencingThresholdInMinutes" placeholder="Minute"
                (change)="validateGeofencingThresholdInMinutes()" />
              <span class="input-group-text">Minutes</span>
              <span class="input-group-append">
                <obc-tooltip
                  message="If users return to the site within this threshold, we won't consider them as being away.">
                </obc-tooltip>
              </span>
            </div>
            <div
              *ngIf="siteManagementFG.get('automatedAttendance.geofencingThresholdInMinutes')?.invalid && (siteManagementFG.get('automatedAttendance.geofencingThresholdInMinutes')?.dirty || siteManagementFG.get('automatedAttendance.geofencingThresholdInMinutes')?.touched)"
              class="help-block with-errors">
              Please Enter Check Out Threshold
            </div>
          </div>
          <div class="col-sm-12">
            <span class="alert alert-warning"
              *ngIf="currentUserIsGod == false && siteManagementFG.get('automatedAttendance.geofencingThresholdInMinutes')?.value > maxAllowedGeofencingThresholdInMinutes">
              <small>
                The maximum allowed value is {{ maxAllowedGeofencingThresholdInMinutes }}.
                Contact administrator for more information.
              </small>
            </span>
          </div>
        </div>
        <div class="form-group row pb-2 mb-1">
          <label class="col-sm-4 control-label text-left pt-2">Delayed CheckIn Answers</label>
          <div class="col-auto pull-left">
            <select class="form-control" formControlName="delayedAnswerModeForCheckIn"
              name="delayedAnswerModeForCheckIn">
              <option *ngFor="let index of DelayedAnswerMode | enumToArray" value="{{ DelayedAnswerMode[index] }}">
                {{ DelayedAnswerMode[index] | delayAnswerMode }}
              </option>
            </select>
            <obc-tooltip message="Ability to answer questions after automatic check-in">
            </obc-tooltip>
          </div>
        </div>
        <div class="form-group row pb-2 mb-1"
          *ngIf="siteManagementFG.get('automatedAttendance.delayedAnswerModeForCheckIn')?.value != DelayedAnswerMode.Disable">
          <label class="col-sm-4 control-label text-left pt-2">Delayed CheckIn Expiry</label>
          <div class="col-auto pull-left">
            <div class="input-group mb-3">
              <obc-duration-selector
                [durationInHour]="siteManagementFG.get('automatedAttendance.delayedAnswerForCheckInThresholdInHour')?.value"
                (durationSelectedChanged)="siteManagementFG.get('automatedAttendance.delayedAnswerForCheckInThresholdInHour')?.setValue($event)">
              </obc-duration-selector>
              <obc-tooltip message="After this time has expired, the user will not be able to answer the questions">
              </obc-tooltip>
            </div>
          </div>
        </div>
        <div class="form-group row pb-2 mb-1">
          <label class="col-sm-4 control-label text-left pt-2">Delayed CheckOut Answers?</label>
          <div class="col-auto pull-left">
            <select class="form-control" formControlName="delayedAnswerModeForCheckOut"
              name="delayedAnswerModeForCheckOut">
              <option *ngFor="let index of DelayedAnswerMode | enumToArray" value="{{ DelayedAnswerMode[index] }}">
                {{ DelayedAnswerMode[index] | delayAnswerMode }}
              </option>
            </select>
            <obc-tooltip message="Ability to answer questions after automatic check-out">
            </obc-tooltip>
          </div>
        </div>
        <div class="form-group row pb-2 mb-1"
          *ngIf="siteManagementFG.get('automatedAttendance.delayedAnswerModeForCheckOut')?.value != DelayedAnswerMode.Disable">
          <label class="col-sm-4 control-label text-left pt-2">Delayed CheckOut Expiry</label>
          <div class="col-auto pull-left">
            <div class="input-group mb-3">
              <obc-duration-selector
                [durationInHour]="siteManagementFG.get('automatedAttendance.delayedAnswerForCheckOutThresholdInHour')?.value"
                (durationSelectedChanged)="siteManagementFG.get('automatedAttendance.delayedAnswerForCheckOutThresholdInHour')?.setValue($event)">
              </obc-duration-selector>
              <obc-tooltip message="After this time has expired, the user will not be able to answer the questions">
              </obc-tooltip>
            </div>
          </div>
        </div>
        <div class="form-group row pb-2 mb-1"
          *ngIf="siteManagementFG.get('automatedAttendance.delayedAnswerModeForCheckIn')?.value != DelayedAnswerMode.Disable || siteManagementFG.get('automatedAttendance.delayedAnswerModeForCheckOut')?.value != DelayedAnswerMode.Disable ">
          <label class="col-sm-4 control-label text-left pt-2">Delayed Answer Notification Frequency</label>
          <div class="col-auto pull-left">
            <div class="input-group mb-3">
              <select class="form-control" formControlName="delayedAnswerNotificationFrequencyInMinute"
                name="delayedAnswerNotificationFrequencyInMinute">
                <option value="">Disabled</option>
                <option value="{{ 30 }}">Every 30 Minutes</option>
                <option value="{{ 60 }}">Every Hour</option>
              </select>
            </div>
          </div>
        </div>
        <div class="form-group row pb-2 mb-1"
          *ngIf="siteManagementFG.get('automatedAttendance.delayedAnswerModeForCheckOut')?.value != DelayedAnswerMode.Disable && siteManagementFG.get('automatedAttendance.delayedAnswerNotificationFrequencyInMinute')?.value > 0">
          <label class="col-sm-4 control-label text-left pt-2">
            Delayed Answer Notification After Checkout Options:
          </label>
          <div class="col-auto pull-left">
            <div class="input-group mb-3">
              <select class="form-control" formControlName="delayedAnswerNotificationAfterCheckOutNotifyCount"
                name="delayedAnswerNotificationAfterCheckOutNotifyCount">
                <option value="{{ 1 }}">Once</option>
                <option value="{{ 2 }}">Twice</option>
                <option value="{{ 3 }}">Three Times</option>
              </select>
            </div>
          </div>
        </div>


      </div>
    </accordion-group>
    <accordion-group *ngIf="!isNewSiteRequest() && siteManagementMode == SiteManagementModeEnum.Site"
      formGroupName="cameraQR">
      <div accordion-heading>
        <obc-accordion-header [mainTitle]="'Camera QR'" [icon]="'fa fa-qrcode'" [iconColor]="'#FF9800'"
          [invalidControlsCount]="findInvalidControlsCount('cameraQR')"
          [isAccordionDirty]="checkIfFormGroupIsDirty('cameraQR')" [info]="[{'title': 'Anonymous Check In: ',
          'value': +siteManagementFG.get('cameraQR.qrCodeCheckinMode')?.value | qRCodeCheckinMode : true}]">
        </obc-accordion-header>
      </div>
      <div>

        <div class="form-group row pb-2 mb-1">
          <label class="col-sm-4 control-label text-left pt-2">{{constants.ANONYMOUS_ATTENDANCE_TITLE}} Check-In By
            QR</label>
          <div class="col-auto pull-left">
            <select class="form-control" name="qrCodeCheckinMode" formControlName="qrCodeCheckinMode"
              (change)="qrCodeCheckinModeFn($event.target.value)">
              <option *ngFor="let index of QrCodeCheckinMode | enumToArray" value="{{ QrCodeCheckinMode[index] }}">
                {{ QrCodeCheckinMode[index] | qRCodeCheckinMode }}
              </option>
            </select>
            <!-- <obc-switch [value]="siteManagementFG.get('cameraQR.qRCodeCheckinMode')?.value"
              (switchChanged)="qRCodeCheckinModeFn($event)"
              (valueChanged)="siteManagementFG.get('cameraQR.qRCodeCheckinMode')?.setValue($event)">
            </obc-switch> -->
          </div>
          <obc-tooltip
            message="If you enable anonymous check-in using QR code, users will be able to check-in without downloading the app just by scanning site's QR code.">
          </obc-tooltip>
        </div>
        <div class="form-group row pb-2 mb-1"
          *ngIf="siteManagementFG.get('cameraQR.qrCodeCheckinMode')?.value != QrCodeCheckinMode.Disable">
          <label class="col-sm-4 control-label text-left pt-2">Auto Check Out {{constants.ANONYMOUS_ATTENDANCE_TITLE}}
            After</label>
          <div class="col-auto pull-left">
            <div class="input-group mb-3">
              <obc-hour-minute-selector
                [cssClass]="siteManagementFG.get('cameraQR.forceCheckOutAnonymousAfterInMinute')?.value?'':'ng-invalid'"
                [minute]="siteManagementFG.get('cameraQR.forceCheckOutAnonymousAfterInMinute')?.value"
                [isMinuteActiveByDefault]="true"
                (valueChanged)="siteManagementFG.get('cameraQR.forceCheckOutAnonymousAfterInMinute')?.setValue($event)">
              </obc-hour-minute-selector>
            </div>
          </div>
        </div>

        <div *ngIf="!manageSiteModel?.site?.isTemplate">
          <div class="d-flex button-group">
            <obc-loader [show]="inProgress"></obc-loader>
            <button (click)="regenerateSecretKey()" type="button" class="mb-1 ml-auto mt-1 mr-1 btn btn-outline-primary"
              *ngIf="manageSiteModel?.site?.secretCode == null || manageSiteModel?.site?.secretCode == ''">
              <i class="fas fa-sync"></i>
              Enable QR Code
            </button>
            <div class="ml-auto" *ngIf="manageSiteModel?.site?.secretCode && manageSiteModel?.site?.secretCode != ''">
              <button (click)="showSecretKey()" type="button" class="mb-1 mt-1 mr-1 btn btn-success ">
                <i class="fas fa-thumbs-up"></i> Show QRCode
              </button>
              <button (click)="showQrCodeWithInstruction()" type="button" class="mb-1 mt-1 mr-1 btn btn-success ">
                <i class="fas fa-thumbs-up"></i> Show QRCode With Site
                Instructions
              </button>
            </div>

          </div>
        </div>
        <div class="border mg-main ml-0 mt-3"
          *ngIf="!manageSiteModel?.site?.isTemplate && siteQRCode?.qrCodeImageBase64">
          <div class="mg-files">
            <div class="thumbnail bg-white no-borders">
              <div class="thumb-preview text-center">
                <a class="thumb-image" #printSection>
                  <img alt="Site QR Code" [src]="siteQRCode?.qrCodeImageBase64" style="max-width: 400px;">
                </a>
              </div>
            </div>
            <button type="button" class="btn btn-info btn-print-qr mt-3" (click)="print(false)">
              <i class="fas fa-print mr-1"></i>
              Print QR Code
            </button>
          </div>
        </div>

      </div>
    </accordion-group>

    <accordion-group formGroupName="offsite"
      *ngIf="isActiveAutoCheckInPanel && siteManagementMode == SiteManagementModeEnum.Site">
      <div accordion-heading>
        <obc-accordion-header [mainTitle]="'Offsite'" [icon]="'fa fa-map-marked'" [iconColor]="'#20997c'"
          [invalidControlsCount]="findInvalidControlsCount('offsite')"
          [isAccordionDirty]="checkIfFormGroupIsDirty('offsite')" [info]="[{'title': 'Pre-Check-In: ',
          'value': +siteManagementFG.get('offsite.enablePreCheckIn')?.value | enabledDisabledSwitch},
          {'title': 'Offsite Inductions: ',
          'value': +siteManagementFG.get('offsite.enableOffsiteInductions')?.value | enabledDisabledSwitch}]">
        </obc-accordion-header>
      </div>
      <div>
        <ng-container *ngIf="enablePreCheckInFeature">


          <div class="card">
            <div class="card-header">
              <strong>Operations</strong>
            </div>
            <div class="card-body">
              <div class="form-group row pb-2 mb-1">
                <label class="col-sm-4 control-label text-left pt-2">Enable Pre Check-In</label>
                <div class="col-sm-8">
                  <div class="input-group mb-3">
                    <obc-switch [value]="siteManagementFG.get('offsite.enablePreCheckIn')?.value"
                      (valueChanged)="siteManagementFG.get('offsite.enablePreCheckIn')?.setValue($event)"
                      [trueLabel]="'Enabled'" [falseLabel]="'Disabled'">
                    </obc-switch>
                  </div>
                </div>
              </div>
              <div class="form-group row pb-2 mb-1">
                <label class="col-sm-4 control-label text-left pt-2">Enable Offsite Inductions</label>
                <div class="col-sm-8">
                  <div class="input-group mb-3">
                    <obc-switch [value]="siteManagementFG.get('offsite.enableOffsiteInductions')?.value"
                      (valueChanged)="siteManagementFG.get('offsite.enableOffsiteInductions')?.setValue($event)"
                      [trueLabel]="'Enabled'" [falseLabel]="'Disabled'">
                    </obc-switch>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card"
            *ngIf="siteManagementFG.get('offsite.enablePreCheckIn')?.value || siteManagementFG.get('offsite.enableOffsiteInductions')?.value">
            <div class="card-header">
              <strong>Access</strong>
            </div>
            <div class="card-body">
              <div>
                <div class="form-group row pb-2 mb-1">
                  <label class="col-sm-4 control-label text-left pt-2">Frequent Users</label>
                  <div class="col-sm-8">
                    <div class="input-group mb-3">
                      <obc-switch [value]="siteManagementFG.get('offsite.enablePreCheckInForFrequentUsers')?.value"
                        (valueChanged)="siteManagementFG.get('offsite.enablePreCheckInForFrequentUsers')?.setValue($event)"
                        [trueLabel]="'Enabled'" [falseLabel]="'Disabled'">
                      </obc-switch>
                    </div>
                  </div>
                </div>
                <div class="form-group row pb-2 mb-1">
                  <label class="col-sm-4 control-label text-left pt-2">Offsite Access Code</label>
                  <div class="col-sm-8">
                    <div class="input-group mb-3">
                      <obc-switch [value]="siteManagementFG.get('offsite.enablePreCheckInByCode')?.value"
                        (valueChanged)="siteManagementFG.get('offsite.enablePreCheckInByCode')?.setValue($event)"
                        [trueLabel]="'Enabled'" [falseLabel]="'Disabled'">
                      </obc-switch>
                    </div>
                  </div>
                </div>
                <ng-container *ngIf="siteManagementFG.get('offsite.enablePreCheckInByCode')?.value">
                  <div class="form-group row pb-2 mb-1">
                    <label class="col-sm-4 control-label text-left pt-2">
                      Offsite Code
                      <!-- <a (click)="getRandomCodeForPreCheckInCode()" class="pointer"><u><small>Get Random
                        Code</small></u></a> -->
                    </label>
                    <div class="col-sm-8">
                      <div class="input-group mb-3">
                        <!-- <input type="number" name="preCheckInCode" class="form-control"
                      minlength="{{minimumPreCheckInCodeLength}}" [(ngModel)]="_site.preCheckInCode" /> -->
                        <span class="mt-2">{{siteManagementFG.get('offsite.preCheckInCode')?.value}}</span>
                      </div>
                    </div>
                  </div>
                  <div class="form-group row pb-2 mb-1">
                    <label class="col-sm-4 control-label text-left pt-2">Offsite Password (Optional)</label>
                    <div class="col-sm-8">
                      <div class="input-group mb-3">
                        <input type="text" name="preCheckInPassword" class="form-control"
                          formControlName="preCheckInPassword" />
                      </div>
                    </div>
                  </div>
                </ng-container>
                <div class="form-group row pb-2 mb-1">
                  <label class="col-sm-4 control-label text-left pt-2">Enable Offsite QR</label>
                  <div class="col-sm-8">
                    <div class="input-group mb-3">
                      <obc-switch [value]="siteManagementFG.get('offsite.enableOffsiteQR')?.value"
                        (valueChanged)="siteManagementFG.get('offsite.enableOffsiteQR')?.setValue($event)"
                        [trueLabel]="'Enabled'" [falseLabel]="'Disabled'">
                      </obc-switch>
                      <button (click)="showOffsiteSecretKey()" type="button"
                        class="mb-1 mt-1 mr-1 btn btn-success pull-right"
                        *ngIf="siteManagementFG.get('offsite.enableOffsiteQR')?.value && this.manageSiteModel?.site?.id">
                        <i class="fas fa-thumbs-up"></i> Show Offsite QRCode
                      </button>
                    </div>
                  </div>
                </div>
                <div class="border mg-main ml-0 mt-3"
                  *ngIf="siteManagementFG.get('offsite.enableOffsiteQR')?.value && !manageSiteModel?.site?.isTemplate && offsiteQRCode?.qrCodeImageBase64">
                  <div class="mg-files">
                    <div class="thumbnail bg-white no-borders">
                      <div class="thumb-preview text-center">
                        <a class="thumb-image" #printOffsiteSection>
                          <img alt="Site QR Code" [src]="offsiteQRCode?.qrCodeImageBase64" style="max-width: 400px;">
                        </a>
                      </div>
                    </div>

                    <button type="button" class="btn btn-info btn-print-qr mt-3" (click)="print(true)">
                      <i class="fas fa-print mr-1"></i>
                      Print QR Code
                    </button>
                    <div class="card m-3">

                      <div class="card-body">
                        <app-clipboard-box [label]="'Offsite Link'" [text]="offsiteQRCode?.qrCodeContent">
                        </app-clipboard-box>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </accordion-group>

    <obc-site-questions-management [dirtyComponents]="dirtyComponents" [siteSupplierId]="siteSupplierId"
      [siteId]="siteId" [siteManagementMode]="siteManagementMode" [manageSiteViewModel]="manageSiteModel"
      (manageSiteViewModelChange)="updateManageSiteModel($event)" *ngIf="!isNewSiteRequest()">
    </obc-site-questions-management>

    <accordion-group *ngIf="!isNewSiteRequest() && siteManagementMode == SiteManagementModeEnum.Site"
      formGroupName="advancedSettings">
      <div accordion-heading>
        <obc-accordion-header [mainTitle]="'Advanced Settings'" [icon]="'fa fa-cogs'" [iconColor]="'#413030'"
          [invalidControlsCount]="findInvalidControlsCount('advancedSettings')"
          [isAccordionDirty]="checkIfFormGroupIsDirty('advancedSettings')"></obc-accordion-header>
      </div>
      <div>

        <div class="form-group row pb-2 mb-1">
          <label class="col-sm-4 control-label text-left pt-2">Region</label>
          <div class="col-sm-8">
            <div class="input-group mb-3 d-flex">
              <div class="flex-fill">
                <obc-region-selector [regionId]="siteManagementFG.get('advancedSettings.regionId')?.value"
                  (regionIdSelect)="onRegionSelect($event)">
                </obc-region-selector>
              </div>
              <span class="input-group-append">
                <obc-tooltip message="Region of the site ( Optional )"></obc-tooltip>
              </span>
            </div>
          </div>
        </div>

        <div class="form-group row pb-2 mb-1" *ngIf="userService.isEnableSeatLocation">
          <label class="col-sm-4 control-label text-left pt-2">Seating Location Mode</label>
          <div class="col-sm-8">
            <div class="input-group mb-3">
              <select class="form-control" formControlName="seatingLocationFillMode">
                <option *ngFor="let sl of ( SeatingModeLabels | dicToArray )" value="{{ +sl.key }}">
                  {{sl.value}}
                </option>
              </select>
              <!-- <span class="input-group-append">
                <obc-tooltip message="Seating mode for hospitality companies"></obc-tooltip>
              </span> -->
            </div>
          </div>
        </div>


        <div class="form-group row pb-2 mb-1">
          <label class="col-sm-4 control-label text-left pt-2">Multiple Check-In </label>
          <div class="col-sm-8">
            <div class="input-group mb-3">
              <select class="form-control" formControlName="multipleCheckinType" name="multipleCheckinType">
                <option *ngFor="let index of multipleCheckinTypes | enumToArray"
                  value="{{ multipleCheckinTypes[index] }}">
                  {{ getMultipleCheckinType(multipleCheckinTypes[index]) }}
                </option>
              </select>
              <span class="input-group-append">
                <obc-tooltip message="by this feature your users allow to check-in in multiple site at the same time.">
                </obc-tooltip>
              </span>
            </div>
          </div>
        </div>

        <div class="form-group row" style="padding: 15px;">
          <obc-image-cropper-form-control [removable]="manageSiteModel?.site?.id > 0"
            [formControl]="siteLogoFormControl"
            [currentImageSrcUrl]="siteManagementFG.get('advancedSettings.logoUrl')?.value"
            (removeImage)="onRemoveLogo()" header="Select Site Logo">
          </obc-image-cropper-form-control>
        </div>


        <div class="form-group row pb-2 mb-1">
          <label class="col-sm-4 control-label text-left pt-2">Notify Near Users</label>
          <div class="col-auto pull-left">
            <obc-switch [value]="siteManagementFG.get('advancedSettings.enableNotifyNearUser')?.value"
              (valueChanged)="siteManagementFG.get('advancedSettings.enableNotifyNearUser')?.setValue($event)">
            </obc-switch>
            <obc-tooltip message="All users near this site get notification on mobile device"></obc-tooltip>
          </div>
        </div>

        <div class="form-group row pb-2 mb-1">
          <label class="col-sm-4 control-label text-left pt-2">Site Timezone</label>
          <div class="col-sm-8">
            <div class="input-group mb-3">
              <select class="form-control" formControlName="timezoneId">
                <option value="{{ null }}" *ngIf="currentCompany">
                  Company TimeZone ({{ currentCompany.timezone.name }}
                  {{ currentCompany.timezone.offset }})
                </option>
                <option *ngFor="let tz of (timezones | filterByProperty:false:'isOtherTimezone')" value="{{ tz.id }}">
                  {{tz.name}} ({{tz.offset}})
                </option>
                <optgroup label="OTHER TIMEZONES">
                  <option *ngFor="let tz of (timezones | filterByProperty:true:'isOtherTimezone')" value="{{ tz.id }}">
                    {{tz.name}} ({{tz.offset}})
                  </option>
                </optgroup>
              </select>
              <span class="input-group-append">
                <obc-tooltip message="Site TimeZone by default company timezone is selected"></obc-tooltip>
              </span>
            </div>
          </div>
        </div>

        <div class="form-group row pb-2 mb-1">
          <label class="col-sm-4 control-label text-left pt-2">Accompanying Persons Info</label>
          <div class="col-sm-8">
            <div class="input-group mb-3">
              <select class="form-control" name="accompanierMode" formControlName="accompanierMode">
                <option *ngFor="let index of accompanierMode | enumToArray" value="{{ accompanierMode[index] }}">
                  {{ accompanierMode[index] | accompanierMode }}
                </option>
              </select>
              <span class="input-group-append">
                <obc-tooltip message="You can collect the information of multiple people as one check-in record.">
                </obc-tooltip>
              </span>
            </div>
          </div>
        </div>

        <div class="form-group row pb-2 mb-1">
          <label class="col-sm-4 control-label text-left pt-2">Max People On Site Mode </label>
          <div class="col-sm-8">
            <div class="input-group mb-3">
              <select class="form-control" formControlName="maxPeopleOnSiteMode"
                (change)="maxPeopleOnSiteModeFn($event.target)" name="maxPeopleOnSiteMode">
                <option *ngFor="let index of maxPeopleOnSiteMode | enumToArray"
                  value="{{ maxPeopleOnSiteMode[index] }}">
                  {{ getMaxPeopleOnSiteMode(maxPeopleOnSiteMode[index]) }}
                </option>
              </select>
            </div>
          </div>
        </div>
        <div class="form-group row pb-2 mb-1"
          *ngIf="siteManagementFG.get('advancedSettings.maxPeopleOnSiteMode').value != maxPeopleOnSiteMode.Disable">
          <label class="col-sm-4 control-label text-left pt-2">Max People On Site Limit </label>
          <div class="col-sm-auto pull-left">
            <div class="input-group mb-3">
              <input required type="number" class="form-control" min="0" name="maxPeopleOnSite"
                formControlName="maxPeopleOnSite" placeholder="People Count" />
              <span class="input-group-text">{{siteManagementFG.get('advancedSettings.maxPeopleOnSite').value == 1 ?
                'Person' : 'Persons'}}</span>
            </div>
            <div
              *ngIf="siteManagementFG.get('advancedSettings.maxPeopleOnSite')?.invalid && (siteManagementFG.get('advancedSettings.maxPeopleOnSite')?.dirty || siteManagementFG.get('advancedSettings.maxPeopleOnSite')?.touched)"
              class="help-block with-errors">
              Please Enter Max People Number
            </div>
          </div>
        </div>


        <div class="form-group row pb-2 mb-1">
          <label class="col-sm-4 control-label text-left pt-2">Opt-in Question Mode</label>
          <div class="col-sm-8">
            <div class="input-group mb-3">
              <select class="form-control" (change)="optInQuestionModeFn($event.target)"
                formControlName="optInQuestionMode" name="optInQuestionMode">
                <option *ngFor="let index of optInQuestionMode | enumToArray" value="{{ optInQuestionMode[index] }}">
                  {{ optInQuestionMode[index] | optInQuestionMode}}
                </option>
              </select>
              <span class="input-group-append">
                <obc-tooltip message="by this feature you manage Opt-in question mode.">
                </obc-tooltip>
              </span>
            </div>
          </div>
        </div>
        <div
          *ngIf="siteManagementFG.get('advancedSettings.optInQuestionMode')?.value == optInQuestionMode.CustomWithMessage"
          class="form-group row pb-2 mb-1">
          <label class="col-sm-4 control-label text-left pt-2">Opt-in Question Message</label>
          <div class="col-sm-8">
            <div class="input-group mb-3">
              <input
                [required]="siteManagementFG.get('advancedSettings.optInQuestionMode')?.value == optInQuestionMode.CustomWithMessage"
                placeholder="Custom Message..." formControlName="optInQuestionMessage" name="optInQuestionMessage"
                class="form-control" />
            </div>
            <div
              *ngIf="siteManagementFG.get('advancedSettings.optInQuestionMessage')?.invalid && (siteManagementFG.get('advancedSettings.optInQuestionMessage')?.dirty || siteManagementFG.get('advancedSettings.optInQuestionMessage')?.touched)"
              class="help-block with-errors">
              Please Enter Opt In Question Message
            </div>
          </div>
        </div>
        <div class="form-group row pb-2 mb-1">
          <label class="col-sm-4 control-label text-left pt-2">Integration Configuration</label>
          <div class="col-sm-8">
            <div class="input-group mb-3">
              <textarea rows="3" placeholder="Custom integration notes" formControlName="integrationConfigAsJson"
                name="integrationConfigAsJson" class="form-control">
              </textarea>
            </div>
          </div>
        </div>
      </div>
    </accordion-group>


    <accordion-group
      *ngIf="!isNewSiteRequest() && siteManagementMode == SiteManagementModeEnum.Site && enableAccessControlFeature"
      formGroupName="accessControl">
      <div accordion-heading>
        <obc-accordion-header [mainTitle]="'Access Control'" [icon]="'fa fa-door-open'" [iconColor]="'#ff0000'"
          [info]="getAccessControlHeaderInfo()">
        </obc-accordion-header>
      </div>

      <div class="form-group row pb-2 mb-1">
        <label class="col-sm-2 control-label text-left pt-2">Access Control Provider</label>
        <div class="col-sm-8">
          <div class="input-group mb-3 d-flex">
            <div class="flex-fill">
              <label class="mr-3">
                <input type="radio" class="mr-1" [value]="AccessControlUnitProvider.Disable"
                  formControlName="accessControlUnitProvider" />
                <strong>Disable</strong>
              </label>
              <label class="mr-3">
                <input type="radio" class="mr-1" [value]="AccessControlUnitProvider.OneBreadCrumb"
                  formControlName="accessControlUnitProvider" />
                <strong>1Breadcrumb</strong>
              </label>
              <label class="mr-3">
                <input type="radio" class="mr-1" [value]="AccessControlUnitProvider.BioStar"
                  formControlName="accessControlUnitProvider" />
                <strong>BioStar2</strong>
              </label>
            </div>
          </div>
        </div>
      </div>

      <div
        *ngIf="siteManagementFG.get('accessControl.accessControlUnitProvider')?.value != AccessControlUnitProvider.Disable">


        <div class="card mb-2"
          *ngIf="siteManagementFG.get('accessControl.accessControlUnitProvider')?.value == AccessControlUnitProvider.BioStar">
          <div class="card-header">BioStar2 Credential</div>
          <div class="card-body">
            <div class="row mb-3">
              <div class="form-group col-md-12 d-flex flex-column justify-content-between">
                <label>Custom Credential</label>
                <obc-switch [trueLabel]="' Active '" [falseLabel]="' Inactive '"
                  [value]="siteManagementFG.get('accessControl.isBioStarEnabled')?.value"
                  (valueChanged)="changeBioStarActiveState($event)"></obc-switch>
              </div>
            </div>

            <ng-container *ngIf="siteManagementFG.get('accessControl.isBioStarEnabled')?.value == true">
              <div class="row">
                <div class="form-group col-md-3 col-sm-12">
                  <label>Server Url</label>
                  <input type="url" class="form-control" formControlName="bioStarApiServerUrl" placeholder="">
                </div>
                <div class=" form-group col-md-3 col-sm-12">
                  <label>Username</label>
                  <input type="text" class="form-control" formControlName="bioStarApiUsername">
                </div>
                <div class=" form-group col-md-3 col-sm-12">
                  <label>Password</label>
                  <input type="password" class="form-control" formControlName="bioStarApiPassword"
                    autocomplete="new-password">
                </div>
              </div>
            </ng-container>
          </div>
        </div>

        <div class="card mb-2">
          <div class="card-header">Entry Conditions</div>
          <div class="card-body">
            <div class="form-group row pb-2 mb-1">
              <label class="col-sm-4 control-label text-left pt-2">
                Inductions
              </label>
              <div class="col-sm-8">
                <div class="input-group mb-3 d-flex">
                  <div class="flex-fill">
                    <select class="form-control" name="accessControlInductionCheckingMode"
                      formControlName="accessControlInductionCheckingMode">
                      <option *ngFor="let index of AccessControlInductionMode | enumToArray"
                        value="{{ AccessControlInductionMode[index] }}">
                        {{ AccessControlInductionMode[index] | accessControlInductionMode }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group row pb-2 mb-1">
              <label class="col-sm-4 control-label text-left pt-2">
                Site Briefings
              </label>
              <div class="col-sm-8">
                <div class="input-group mb-3 d-flex">
                  <div class="flex-fill">
                    <select class="form-control" name="accessControlSiteBriefingCheckingMode"
                      formControlName="accessControlSiteBriefingCheckingMode">
                      <option *ngFor="let index of AccessControlSiteBriefingMode | enumToArray"
                        value="{{ AccessControlSiteBriefingMode[index] }}">
                        {{ AccessControlSiteBriefingMode[index] | accessControlSiteBriefingMode }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group row pb-2 mb-1">
              <label class="col-sm-4 control-label text-left pt-2">
                Supplier Documents
              </label>
              <div class="col-sm-8">
                <div class="input-group mb-3 d-flex">
                  <div class="flex-fill">
                    <select class="form-control" name="accessControlSupplierDocumentCheckingMode"
                      formControlName="accessControlSupplierDocumentCheckingMode">
                      <option *ngFor="let index of AccessControlSupplierDocumentMode | enumToArray"
                        value="{{ AccessControlSupplierDocumentMode[index] }}">
                        {{ AccessControlSupplierDocumentMode[index] | accessControlSupplierDocumentMode }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group row pb-2 mb-1"
              *ngIf="siteManagementFG.get('accessControl.accessControlUnitProvider')?.value == AccessControlUnitProvider.BioStar">
              <div class="col-sm-4 control-label text-left pt-2">
                <label class="control-label">Add User to BioStar Access Group</label>
                <obc-switch [trueLabel]="' Active '" [falseLabel]="' Inactive '" class="d-block"
                  [value]="siteManagementFG.get('accessControl.enableBioStarAccessGroup')?.value"
                  (valueChanged)="changeBioStarEnableAccessGroup($event)"></obc-switch>
              </div>
              <div class="col-sm-8"
                *ngIf="siteManagementFG.get('accessControl.enableBioStarAccessGroup')?.value == true">
                <div class="input-group mb-3 d-flex">
                  <div class="flex-fill">
                    <input type="text" class="form-control" formControlName="bioStarAccessGroup"
                      placeholder="Access Group">
                  </div>
                </div>
                <div class="input-group mb-3 d-flex">
                  <div class="flex-fill">
                    <input type="text" class="form-control" formControlName="bioStarUserGroup"
                      placeholder="User Group Id">
                  </div>
                </div>
                <div
                  *ngIf="siteManagementFG.get('accessControl.bioStarAccessGroup')?.invalid && (siteManagementFG.get('accessControl.bioStarAccessGroup')?.dirty || siteManagementFG.get('accessControl.bioStarAccessGroup')?.touched)"
                  class="help-block with-errors">
                  Please Enter Access Group
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group row pb-2 mb-1"
          *ngIf="siteManagementFG.get('accessControl.accessControlUnitProvider')?.value == AccessControlUnitProvider.BioStar">
          <label class="col-sm-4 control-label text-left pt-2">
            Entry Operation
          </label>
          <div class="col-sm-8">
            <div class="input-group mb-3 d-flex">
              <div class="flex-fill">
                <label>
                  <input type="checkbox" formControlName="enableAccessControlUnitReport"> Report
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        *ngIf="siteManagementFG.get('accessControl.accessControlUnitProvider')?.value == AccessControlUnitProvider.OneBreadCrumb">

        <div class="form-group row pb-2 mb-1">
          <label class="col-sm-4 control-label text-left pt-2">Automatic Check-In Using Access Control Unit</label>
          <div class="col-sm-8">
            <div class="input-group mb-3 d-flex">
              <div class="flex-fill">
                <obc-switch formControlName="accessControlAutomaticCheckInMode" [trueLabel]="'Enabled'"
                  [falseLabel]="'Disabled'" [trueValue]="accessControlAutomaticAttendanceModeEnum.Enabled"
                  [falseValue]="accessControlAutomaticAttendanceModeEnum.Disabled"></obc-switch>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group row pb-2 mb-1">
          <label class="col-sm-4 control-label text-left pt-2">Automatic Check-Out Using Access Control Unit</label>
          <div class="col-sm-8">
            <div class="input-group mb-3 d-flex">
              <div class="flex-fill">
                <obc-switch formControlName="accessControlAutomaticCheckOutMode" [trueLabel]="'Enabled'"
                  [falseLabel]="'Disabled'" [trueValue]="accessControlAutomaticAttendanceModeEnum.Enabled"
                  [falseValue]="accessControlAutomaticAttendanceModeEnum.Disabled"></obc-switch>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card" *ngIf="!isAccessControlDisabled">
        <div class="card-header">Access Control Units</div>
        <div class="card-body">
          <div class="row">
            <div class="col-md-{{is1BcProviederSelected?12:8}}">
              <div *ngIf="accessControlUnits.length == 0" class="alert alert-warning">
                There are no {{is1BcProviederSelected?'access control':'device'}} units.
              </div>
              <table class="table table-bordered" *ngIf="accessControlUnits.length">
                <thead>
                  <tr>
                    <th>Display Name</th>
                    <th>{{is1BcProviederSelected?'Control Unit':'Device'}} Identifier</th>
                    <th *ngIf="is1BcProviederSelected">Evacuation Identifier</th>
                    <th *ngIf="is1BcProviederSelected">Entry Barcode Scanner Identifier</th>
                    <th *ngIf="is1BcProviederSelected">Entry Gate Identifier</th>
                    <th *ngIf="is1BcProviederSelected">Reject Repeated Entry Scans</th>
                    <th *ngIf="is1BcProviederSelected">Exit Barcode Scanner Identifier</th>
                    <th *ngIf="is1BcProviederSelected">Exit Gate Identifier</th>
                    <th *ngIf="is1BcProviederSelected">Reject Repeated Exit Scans</th>
                    <th *ngIf="is1BcProviederSelected">Active Hours From</th>
                    <th *ngIf="is1BcProviederSelected">Active Hours To</th>
                    <th *ngIf="isBioProviederSelected">Direction</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of accessControlUnits.controls; let i = index">
                    <td>{{item.controls.displayName.value}}</td>
                    <td>{{item.controls.controlUnitIdentifier.value}}</td>
                    <td *ngIf="is1BcProviederSelected">{{item.controls.evacuationIdentifier.value}}</td>
                    <td *ngIf="is1BcProviederSelected">{{item.controls.entryBarcodeScannerIdentifier.value}}</td>
                    <td *ngIf="is1BcProviederSelected">{{item.controls.entryGateIdentifier.value}}</td>
                    <td *ngIf="is1BcProviederSelected">{{item.controls.limitEntryGateRepeatedScansInSeconds.value}}</td>
                    <td *ngIf="is1BcProviederSelected">{{item.controls.exitBarcodeScannerIdentifier.value}}</td>
                    <td *ngIf="is1BcProviederSelected">{{item.controls.exitGateIdentifier.value}}</td>
                    <td *ngIf="is1BcProviederSelected">{{item.controls.limitExitGateRepeatedScansInSeconds.value}}</td>
                    <td *ngIf="is1BcProviederSelected">{{item.controls.activeHoursFrom?.value | time}}</td>
                    <td *ngIf="is1BcProviederSelected">{{item.controls.activeHoursTo?.value | time}}</td>
                    <td *ngIf="isBioProviederSelected">{{item.controls.direction?.value |
                      accessControlUnitDirection}}</td>
                    <td>
                      <button type="button" class="btn btn-danger" (click)="onRemoveAccessControlUnit(i)">
                        <i class="fas fa-trash-alt"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="col-md-{{is1BcProviederSelected?12:4}}">
              <div *ngIf="is1BcProviederSelected" class="form-group row pb-2 mb-1 access-control-unit-header"
                formGroupName="newAccessControlUnit">
                <div class="container">
                  <div class="row justify-content-between">
                    <div class="col-9">
                      <div class="row justify-content-between">
                        <div class="col">
                          <label>Display Name</label>
                          <div>
                            <input type="text" class="form-control" formControlName="displayName">
                          </div>
                        </div>
                        <div class="col">
                          <label>Control Unit Identifier</label>
                          <div>
                            <input type="text" class="form-control" formControlName="controlUnitIdentifier">
                          </div>
                        </div>
                        <div class="col">
                          <label>Evacuation Identifier</label>
                          <div>
                            <input type="text" class="form-control" formControlName="evacuationIdentifier">
                          </div>
                        </div>
                      </div>
                      <div class="row justify-content-between">
                        <div class="col">
                          <label>Entry Barcode Scanner Identifier</label>
                          <div>
                            <input type="text" class="form-control" formControlName="entryBarcodeScannerIdentifier">
                          </div>
                        </div>
                        <div class="col">
                          <label>Entry Gate Identifier</label>
                          <div>
                            <input type="text" class="form-control" formControlName="entryGateIdentifier">
                          </div>
                        </div>
                        <div class="col">
                          <label>Reject Repeated Entry Scans</label>
                          <div class="d-flex align-items-center">
                            <input type="number" min="0" max="1000" class="form-control"
                              formControlName="limitEntryGateRepeatedScansInSeconds">
                            <span class="ml-1">Seconds</span>
                          </div>
                        </div>
                      </div>
                      <div class="row justify-content-between">
                        <div class="col">
                          <label>Exit Barcode Scanner Identifier</label>
                          <div>
                            <input type="text" class="form-control" formControlName="exitBarcodeScannerIdentifier">
                          </div>
                        </div>
                        <div class="col">
                          <label>Exit Gate Identifier</label>
                          <div>
                            <input type="text" class="form-control" formControlName="exitGateIdentifier">
                          </div>
                        </div>
                        <div class="col">
                          <label>Reject Repeated Exit Scans</label>
                          <div class="d-flex align-items-center">
                            <input type="number" min="0" max="1000" class="form-control"
                              formControlName="limitExitGateRepeatedScansInSeconds">
                            <span class="ml-1">Seconds</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-3">
                      <div class="row">
                        <div class="col">
                          <label>Active Hours From</label>
                          <div>
                            <timepicker [contentEditable]="false" formControlName="activeHoursFrom"
                              [showMeridian]="false">
                            </timepicker>
                          </div>
                        </div>
                        <div class="col">
                          <label>Active Hours To</label>
                          <div>
                            <timepicker [contentEditable]="false" formControlName="activeHoursTo"
                              [showMeridian]="false">
                            </timepicker>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12 align-slef-end">
                          <label></label>
                          <div>
                            <button type="button" class="btn btn-primary btn-block"
                              (click)="onNewControlUnit()">Add</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="isBioProviederSelected" class="form-group row pb-2 mb-1 access-control-unit-header"
                formGroupName="newAccessControlUnit">
                <div class="container">
                  <div class="row justify-content-between">
                    <div class="col-12">
                      <strong>
                        New Device
                      </strong>
                    </div>
                    <div class="col-12">
                      <label>Display Name</label>
                      <div>
                        <input type="text" class="form-control" formControlName="displayName">
                      </div>
                    </div>
                    <div class="col-9">
                      <label>Device Identifier</label>
                      <div>
                        <input type="text" class="form-control" formControlName="controlUnitIdentifier">
                      </div>
                    </div>
                    <div class="col-9">
                      <label>Direction</label>
                      <div>
                        <select class="form-control" name="direction" formControlName="direction">
                          <option *ngFor="let index of AccessControlUnitDirection  | enumToArray"
                            value="{{ AccessControlUnitDirection [index] }}">
                            {{ AccessControlUnitDirection[index] | accessControlUnitDirection }}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-3">
                      <button type="button" class="btn btn-sm btn-info mt-4" *ngIf="isBiostarEnabled"
                        (click)="showSelectBioStarDeviceModal(biostarDeviceSelector)">
                        Select From List
                      </button>
                    </div>
                    <div class="col-12">
                      <label></label>
                      <div>
                        <button type="button" class="btn btn-primary btn-block"
                          (click)="onNewControlUnit()">Add</button>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </accordion-group>


    <accordion-group *ngIf="!isNewSiteRequest() && siteManagementMode == SiteManagementModeEnum.Site"
      class="danger-zone">
      <div accordion-heading>
        <obc-accordion-header [mainTitle]="'Danger Zone'" [icon]="'fa fa-bolt'" [iconColor]="'#ffffff'">
        </obc-accordion-header>
      </div>
      <div>

        <div *ngIf="manageSiteModel?.site?.secretCode == null || manageSiteModel?.site?.secretCode == ''"
          class="form-group row pb-2 mb-1">
          <div class="col-12 d-flex justify-content-end">
            <button (click)="regenerateSecretKey()" type="button"
              class="mb-1 ml-auto mt-1 mr-1 btn btn-outline-primary">
              <i class="fas fa-sync"></i>
              Enable QR Code
            </button>
          </div>
        </div>

        <div class="form-group row mt-4 pb-2 mb-1"
          *ngIf="!manageSiteModel?.site?.isTemplate && manageSiteModel?.site?.secretCode">
          <label class="col-12 col-md-6 control-label text-left pt-2">Reissue or disable QR code </label>
          <div class="col-12 col-md-6 d-flex justify-content-end">
            <button (click)="regenerateSecretKey()" type="button" class="mb-1 mt-1 mr-1 btn btn-outline-primary">
              <i class="fas fa-sync"></i>
              Reissue New QR Code
            </button>
            <button (click)="clearSecretKey()" type="button" class="mb-1 mt-1 mr-1 btn btn-outline-warning">
              <i class="fas fa-trash"></i> Disable QR Code
            </button>
          </div>
        </div>
        <div class="form-group row pb-2 mb-1" *ngIf="!manageSiteModel?.site?.isTemplate">
          <label class="col-12 col-sm-6 control-label text-left pt-2">Deactivate site </label>
          <div class="col-12 col-sm-6  d-flex justify-content-end">
            <button type="button" class="btn" (click)="changeActiveState()"
              [ngClass]="manageSiteModel?.site?.isActive ? 'btn-info' : 'btn-warning'">
              {{manageSiteModel?.site?.isActive ? 'Deactivate Site' : 'Activate Site'}}
            </button>
          </div>
        </div>
        <div class="form-group row pb-2 mb-1">
          <label class="col-12 col-sm-6 control-label text-left pt-2">Delete
            {{manageSiteModel?.site?.isTemplate?'template':'site'}} and all dependencies permanently </label>
          <div class="col-12 col-sm-6  d-flex justify-content-end">
            <button type="button" class="btn btn-danger" (click)="deleteSite()">
              <i class="fas fa-trash mr-1"></i>
              Delete {{manageSiteModel?.site?.isTemplate?'Template':'Site'}}
            </button>
          </div>
        </div>

      </div>
    </accordion-group>
  </accordion>

</form>

<div class="pt-5 mt-5"></div>
<ng-template #biostarDeviceSelector>
  <div class="card">
    <div class="card-header">
      Select Device From List
    </div>
    <div class="card-body">
      <table class="table table-bordered">
        <thead>
          <tr>
            <th>
              Device Name
            </th>
            <th>
              Device Id
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody *ngIf="bioDevicesFetchInProgress">
          <tr>
            <td colspan="5">
              <obc-loader [show]="bioDevicesFetchInProgress"></obc-loader>
            </td>
          </tr>
        </tbody>
        <tbody *ngIf="!bioDevicesFetchInProgress">
          <tr *ngFor="let device of bioStartDeviceList">
            <td>
              {{device.name}}
            </td>
            <td>
              {{device.id}}
            </td>
            <td>
              <button type="button" class="btn btn-sm btn-primary"
                (click)="onSelectBiostarDevice(device)">Select</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</ng-template>