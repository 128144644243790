import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormControl } from '@angular/forms';
import { AttendanceFieldModel, AttendanceFieldWithValue, AttendanceFieldViewModel } from 'src/app/models/attendance-field-model';

@Component({
  selector: 'app-attendance-field-renderer',
  templateUrl: './attendance-field-renderer.component.html',
  styleUrls: ['./attendance-field-renderer.component.scss']
})
export class AttendanceFieldRendererComponent implements OnInit {
  formArray: FormArray;
  @Output() save = new EventEmitter();
  @Input() isHost = false;
  @Input() isEditable = false;
  @Input() hideLabel = false;
  @Input() renderMode: ('column' | 'list') = 'column';
  @Input() set fields(models: AttendanceFieldWithValue[]) {
    if (models != null && models.length > 0)
      if (this.formArray != null && this.formArray.length == models.length)
        for (let index = 0; index < models.length; index++) {
          const element = models[index];
          this.formArray.controls[index].setValue(element);
        }
      else
        this.formArray = new FormArray(models.map(x => new FormControl(x)));
    else
      if (this.formArray)
        this.formArray.controls.splice(0, this.formArray.controls.length)
  };
  constructor() { }

  ngOnInit() {
  }

  getFieldFormControl(model: AttendanceFieldWithValue) {
    return new FormControl(model.value)
  }

  onSave($event: AttendanceFieldViewModel) {
    this.save.emit($event);
  }

  get valid(): boolean {
    if (!this.formArray || this.formArray.length == 0) return true;
    return this.formArray.valid;
  }

  get values(): AttendanceFieldViewModel[] {
    if (!this.formArray) return [];
    return this.formArray.controls.map(x => {
      return {
        fieldKey: x.value.fieldKey,
        value: x.value.value
      } as AttendanceFieldViewModel;
    });
  }
}
