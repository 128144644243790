<div class="{{isStandAloneMode?'card':''}}">
  <div class="card-header" *ngIf="isStandAloneMode">
    <span>
      Add/Update workflow
    </span>
    <div class="card-actions">
      <a (click)="closeModal()" class="close card-action card-action-dismiss" data-card-dismiss=""></a>
    </div>
  </div>
  <div class="card-body">
    <obc-loader [show]="inProgress"></obc-loader>
    <div class="row">
      <div class="form-group col-md-6" *ngIf="isStandAloneMode">
        <label>Workflow Type</label>
        <select [disabled]="isEditMode"
          [class]="'custom-select form-control '+ (dataModel.workflowType == null || dataModel.workflowType == undefined ? 'ng-invalid':'ng-valid' )"
          [(ngModel)]="dataModel.workflowType" (change)="onWorkflowTypeSubTypeChange()">
          <option *ngFor="let type of WorkflowType | enumToArray" [ngValue]="WorkflowType[type]">
            {{ WorkflowType[type] | WorkflowTypeFilter}}
          </option>
        </select>
      </div>
      <div class="form-group col-md-6"
        *ngIf="isStandAloneMode && dataModel.workflowType == WorkflowType.SupplierDocument">
        <label>Document Kind</label>
        <select [disabled]="isEditMode"
          [class]="'custom-select form-control ' +(dataModel.workflowSubType  == null || dataModel.workflowSubType  == undefined ? 'ng-invalid':'ng-valid')"
          [(ngModel)]="dataModel.workflowSubType" (change)="onWorkflowTypeSubTypeChange()">
          <option *ngFor="let type of documentKinds" [ngValue]="type.id">
            {{ type.name }}
          </option>
        </select>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-12"
        *ngIf="!isLockedWorkflowSection && ((!isStandAloneMode && filteredWorkflowTemplateList?.length>1) || dataModel?.workflowSubType)">
        <label>Workflow Template</label>
        <select [disabled]="isEditMode"
          [class]="'custom-select form-control '+ (dataModel.workflowTemplateId == null || dataModel.workflowTemplateId == undefined ? 'ng-invalid':'ng-valid')"
          [(ngModel)]="dataModel.workflowTemplateId" (change)="manipulatePlaceholders()">
          <option [ngValue]="undefined" selected disabled class="text-muted">Choose Workflow Template
          </option>
          <option *ngFor="let item of filteredWorkflowTemplateList" [ngValue]="item.id">
            {{ item.title }}
          </option>
        </select>
      </div>
    </div>

    <div class="row mt-3" *ngIf="dataModel.workflowInfo?.placeholderList">
      <div class="form-group col-md-12">
        <div class="card">
          <div class="card-header d-flex justify-content-between">
            <span>
              Customise Workflow
            </span>
            <div class="card-actions d-flex align-items-center" *ngIf="showBasicAdvancedSwitch">
              <obc-switch style="margin-top: -10px;" [trueLabel]="'Advanced'" [falseLabel]="'Basic'" [small]="true"
                [value]="isActiveAdvancedMode" (switchChanged)="onAdvancedModeChanged($event)">
              </obc-switch>
            </div>
          </div>
          <div class="card-body">
            <div class="row" *ngIf="dataModel.workflowInfo?.placeholderList && isStandAloneMode">
              <div class="form-group col-md-12">
                <label>Workflow Title</label>
                <input placeholder="Workflow Title" [disabled]="isEditMode"
                  [class]="'form-control ' +(!dataModel.workflowTitle ? 'ng-invalid':'ng-valid')"
                  [(ngModel)]="dataModel.workflowTitle">
              </div>
            </div>
            <ng-container *ngIf="startVariables?.length > 0">
              <div class="card mt-3">
                <div class="card-header">
                  Variables
                </div>
                <div class="card-body">
                  <div class="row">
                    <ng-container *ngFor="let variable of startVariables; let i= index;">
                      <div class="form-group col">
                        <label>{{variable.title}}</label>
                        <ng-container [ngSwitch]="variable.key">
                          <div class="input-group">
                            <ng-container *ngSwitchCase="'ExpireDate'">
                              <select [disabled]="isEditMode"
                                [class]="'form-control ' + (!variable.value || variable.value === '' ? 'ng-invalid':'ng-valid')"
                                [(ngModel)]="variable.value">
                                <option value="1 years from now">1 years</option>
                                <option value="2 years from now">2 years</option>
                                <option value="3 years from now">3 years</option>
                                <option value="4 years from now">4 years</option>
                                <option value="5 years from now">5 years</option>
                                <option value="null">No Default Expiry</option>
                              </select>
                            </ng-container>
                            <ng-container *ngSwitchDefault>
                              <input [(ngModel)]="variable.value" type="text" [disabled]="isEditMode"
                                [class]="'form-control ' +(!variable.value || variable.value === '' ? 'ng-invalid':'ng-valid')">
                            </ng-container>
                          </div>
                        </ng-container>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>
            </ng-container>
            <ng-container
              *ngFor="let group of dataModel.workflowInfo?.placeholderList | groupBy:'groupName' | orderBy:'order';">
              <div class="card mt-3">
                <div class="card-header">
                  <span>{{group.key == null ? "Workflow Step" : group.key}}</span>
                </div>
                <div class="card-body">
                  <div class="row justify-content-start">
                    <ng-container *ngFor="let placeholder of group.value | orderBy:'order'; let i= index">
                      <ng-container [ngSwitch]="placeholder.type">
                        <ng-container *ngSwitchCase="FilterType.ActionList">
                          <div class="col-12">
                            <div class="row">
                              <ng-container *ngFor="let val of placeholder.parsedValue">
                                <div class="form-group col" *ngIf="!placeholder.disabled" style="flex-wrap: nowrap;">
                                  <label>
                                    {{placeholder.title}}
                                    Title</label>
                                  <div class="input-group mb-1">
                                    <input type="text"
                                      [class]="'form-control '+ (!val.Text || val.Text === ''? 'ng-invalid':'ng-valid') "
                                      [(ngModel)]="val.Text" [placeholder]="val.Placeholder"
                                      [disabled]="placeholder.disabled || isEditMode">
                                  </div>
                                </div>
                                <div class="form-group col" *ngIf="val.Key ==='ResetApproval' || !placeholder.disabled">
                                  <label>
                                    Roles only access to {{placeholder.title}} </label>
                                  <ng-select style="padding-bottom: 0 !important;" [dropdownPosition]="'top'"
                                    class="region-selector" bindValue="name" bindLabel="name"
                                    [typeaheadOptionsLimit]="7" [typeaheadMinLength]="0" [items]="roles"
                                    [placeholder]="'Select Roles'" [multiple]="true" [typeahead]="term$ | async"
                                    [loading]="inProgress" [clearable]="true" [(ngModel)]="val.RoleList"
                                    [disabled]="isEditMode">
                                    <ng-template ng-option-tmp let-item="item">
                                      {{item.name}}
                                    </ng-template>
                                  </ng-select>
                                  <div
                                    *ngIf="val.Key==='ResetApproval' && (!val.RoleList && val.RoleList == '' || val.RoleList.length==0)"
                                    class="help-block with-errors text-danger">
                                    Please select role(s)
                                  </div>
                                </div>
                              </ng-container>
                            </div>
                          </div>
                        </ng-container>
                        <ng-container *ngSwitchDefault>
                          <div class="form-group col " *ngIf="!placeholder.disabled">
                            <label>{{placeholder.title}}</label>
                            <ng-container *ngIf="placeholder.type == FilterType.Text">
                              <div class="input-group">
                                <input [(ngModel)]="placeholder.value" type="text"
                                  [class]="'form-control '+ (!placeholder.value || placeholder.value === ''? 'ng-invalid':'ng-valid') "
                                  [disabled]="placeholder.disabled || isEditMode">
                              </div>
                            </ng-container>
                            <ng-container *ngIf="placeholder.type == FilterType.RoleArray">
                              <ng-select style="padding-bottom: 0 !important;" [dropdownPosition]="'top'"
                                class="region-selector " bindValue="name" bindLabel="name" [typeaheadOptionsLimit]="7"
                                [typeaheadMinLength]="0" [items]="roles" [placeholder]="'Select Roles'"
                                [multiple]="true" [typeahead]="term$ | async" [loading]="inProgress" [clearable]="true"
                                [(ngModel)]="placeholder.value" [disabled]="isEditMode">
                                <ng-template ng-option-tmp let-item="item">
                                  {{item.name}}
                                </ng-template>
                              </ng-select>
                              <div
                                *ngIf="!isNotification(placeholder.key) && (!placeholder.value || placeholder.value.toString() === '')"
                                class="help-block with-errors text-danger">
                                Please select role(s)
                              </div>
                            </ng-container>
                            <ng-container *ngIf="placeholder.type == FilterType.FormType">
                              <select
                                [class]="'custom-select form-control ' + (!placeholder.value || placeholder.value === ''? 'ng-invalid':'ng-valid')"
                                [(ngModel)]="placeholder.value" [disabled]="placeholder.disabled || isEditMode">
                                <option *ngFor="let type of FormType | enumToArray" [ngValue]="FormType[type]">
                                  {{ FormType[type] | formType }}
                                </option>
                              </select>
                            </ng-container>
                            <ng-container *ngIf="placeholder.type == FilterType.MessageType">
                              <select
                                [class]="'custom-select form-control ' + (!placeholder.value || placeholder.value === ''? 'ng-invalid':'ng-valid')"
                                [(ngModel)]="placeholder.value" [disabled]="placeholder.disabled || isEditMode">
                                <option *ngFor="let exp of MessageType | enumToArray" [ngValue]="MessageType[exp]">
                                  {{ MessageType[exp] | messageTypeFilter }}
                                </option>
                              </select>
                            </ng-container>
                            <ng-container *ngIf="placeholder.type == FilterType.Switch">
                              <br>
                              <obc-switch [trueLabel]="' Active '" [falseLabel]="' Inactive '"
                                [value]="placeholder.value" [(ngModel)]="placeholder.value"
                                [disabled]="placeholder.disabled || isEditMode">
                              </obc-switch>
                            </ng-container>
                          </div>
                        </ng-container>
                      </ng-container>
                    </ng-container>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
        <div class="pt-2 text-right" *ngIf="isStandAloneMode">
          <button class="btn btn-primary" (click)="saveWorkflowTemplate()" *ngIf="!isEditMode">
            Add
          </button>
        </div>
      </div>
    </div>
  </div>
</div>